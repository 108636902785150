import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withSession } from '@mile-hi-labs/react-session';
import { withToast } from 'contexts/toast-context';

import ProviderFields from 'components/provider/provider-fields';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/basics/modals';
import { Form, FormGroup, FormControl, FormText } from 'components/basics/forms';
import { Button } from 'components/basics/buttons';
import { Container, Row, Col } from 'components/basics/grids';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, logger, randomString } from 'utils/helpers';


const ProviderForm = (props) => {
  const { provider = {}, title, type, session, store, toast, nextAction } = props;
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Methods
  const saveProvider = async (facility) => {
    try {
      setTaskRunning(true);
      await provider.save();
      toast.showSuccess('Provider saved!');
      nextAction()
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


  // Render
  if (type == 'modal') {
    return (
      <Form onSubmit={e => e.preventDefault()}>
        <ModalHeader title={title}/>
        <ModalBody size='md'>
          <ProviderFields model={provider}/>
        </ModalBody>
        <ModalFooter>
          <Button
            type='button'
            variant='secondary'
            taskRunning={taskRunning}
            onClick={() => saveProvider(provider)}>
            Save<FarIcon icon='check' className='ml-2'/>
          </Button>
        </ModalFooter>
      </Form>
    )
  }
  return (
    <Form onSubmit={e => e.preventDefault()}>
      <SectionHeader title={title}/>
      <SectionBody size='md'>
        <ProviderFields model={provider}/>
      </SectionBody>
      <SectionFooter>
        <Button
          type='button'
          variant='secondary'
          taskRunning={taskRunning}
          onClick={() => saveProvider(provider)}>
          Save<FarIcon icon='check' className='ml-2'/>
        </Button>
      </SectionFooter>
    </Form>
  )
}

export default withSession(withStore(withToast(ProviderForm)));

