import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';

import ProviderActions from 'components/provider/provider-actions';
import BookingsSection from 'components/booking/bookings-section';
import ContactForm from 'components/contact/contact-form';
import ContactsSection from 'components/contact/contacts-section';
import NoteSection from 'components/note/note-section';
import { LocationDetailCard } from 'components/location/location-card';
import { ProviderDetailCard } from 'components/provider/provider-card';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { ButtonText } from 'components/basics/buttons';
import { Modal } from 'components/basics/modals';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminProvidersDetailRoute = (props) => {
  const { providerID, history, location, store, toast } = props;
  const [ provider, setProvider ] = useState({});
  const [ contact, setContact ] = useState({});
  const [ modal, toggleModal ] = useState(false);
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, []);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord('provider', providerID, { include: 'location,note' });
      setProvider(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const refreshData = () => {
    toggleModal(!modal);
    fetchData();
  }

  const createContact = () => {
    let model = store.createRecord('contact', { provider: provider });
    openModal('contact', model);
  }

  const openModal = (type, model = {}) => {
    setContact(model);
    toggleModal(!modal);
  }


  // Render
  return (
    <AdminRoute title={`DMC - Provider #${providerID}`}>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <SectionHeader title={`Provider #${providerID}`} className='flex-between no-b'>
              <ProviderActions provider={provider} />
            </SectionHeader>
          </SectionBlock>

          <Row>
            <Col xs={12} md={4}>
              <ProviderDetailCard provider={provider} loading={loading}/>
              <NoteSection
                model={provider.note}
                notableId={provider.id}
                notableType='Provider'
                loading={loading}
              />
            </Col>
            <Col xs={12} md={8}>
              <LocationDetailCard location={provider.location} loading={loading} />
              <ContactsSection
                type='list'
                providerID={providerID}
                loading={loading}
                action={() => createContact()}
              />

              <BookingsSection
                type='list'
                providerID={providerID}
                loading={loading}
              />
            </Col>
          </Row>

        </Container>
      </AdminMain>
      <Modal show={modal} toggleModal={() => toggleModal(!modal)}>
        <ContactForm
          type='modal'
          title={contact.id ? 'Edit Contact' : 'New Contact'}
          contact={contact}
          toggleModal={toggleModal}
          nextAction={() => refreshData()}
        />
      </Modal>
    </AdminRoute>
  )
}

export default withStore(withToast(AdminProvidersDetailRoute));
