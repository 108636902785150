import React, { useEffect } from 'react';
import Filestack from 'filestack-react';
import { ButtonText } from 'components/basics/buttons';
import { FadIcon } from 'components/vendors/fa-wrapper';
import { isEmpty } from 'utils/helpers';

const FILESTACK_KEY = process.env.REACT_APP_FILESTACK_KEY;

const FilestackPhotoWrapper = (props) => {
	const { type, value, width = 1, height = 1, onChange } = props;
	const pickerOptions = {
		accept: ['image/*'],
    maxFiles: 1,
    minFiles: 1,
    imageMin: [480, 480],
    imageMax: [1080, 1080],
    imageDim: [1080, 1080],
    uploadInBackground: false,
    transformations: {
    	crop: {
    		force: true,
    		aspectRatio: width/height
    	},
    	rotate: true
    }
	}


  // Hooks
  useEffect(() => {
    console.log(value);
  }, [value])


  // Methods
	const photoUploaded = (v) => {
		if (isEmpty(v.filesUploaded)) { return }
		let photo = v.filesUploaded[0];
		onChange(photo.url);
	}


  // Render
	return (
		<Filestack
			apikey={FILESTACK_KEY}
			actionOptions={pickerOptions}
			onSuccess={v => photoUploaded(v)}
			customRender={({ onPick }) => (
      	<div className={'photo-block ' + type} onClick={onPick}>
        	{value ? <img className='photo-img' src={value} /> : (
        		<div className='photo-block-inner'>
          		<FadIcon icon='images' size='2x'/>
          		<small className='mt-3'>Upload Photo</small>
        		</div>
      		)}
      	</div>
			)}
		/>
	)
}


export default FilestackPhotoWrapper;

// Docs:
// https://github.com/filestack/filestack-react
