import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from 'app';

import FadIcons from 'utils/font-awesome/fad-icons';
import FarIcons from 'utils/font-awesome/far-icons';

import 'styles/app.scss';

ReactDOM.render(
  <BrowserRouter>
  	<HelmetProvider>
  		<App id='application' />
		</HelmetProvider>
	</BrowserRouter>,
  document.getElementById('root')
);
