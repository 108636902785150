import React from 'react';
import Axios from 'utils/axios';
import { JsonApiSerializer } from '@mile-hi-labs/react-data';
import { JsonApiError } from '@mile-hi-labs/react-data';
import { timeout } from 'utils/helpers';

const axios = new Axios().instance;

class AdminApi {

	static async login(data) {
		try {
			await timeout(300);
			let formattedData = JsonApiSerializer.serialize(data);
	  	let response = await axios.post(`/admins/auth/login`, formattedData);
	  	return JsonApiSerializer.normalize(response.data.data, response.data.included, response.data.meta);
	  } catch(e) {
	  	throw JsonApiError.format(e.response.data);
		}
	}

	static async register(data) {
		try {
      await timeout(300);
			let formattedData = JsonApiSerializer.serialize(data);
	  	let response = await axios.post(`/admins`, formattedData);
	  	return JsonApiSerializer.normalize(response.data.data, response.data.included, response.data.meta);
	  } catch(e) {
	  	throw JsonApiError.format(e.response.data);
		}
	}

	static async forgotPassword(data) {
		try {
			await timeout(300);
	  	let response = await axios.get(`/admins/auth/forgot-password`, { params: data });
	  	return JsonApiSerializer.normalize(response.data.data, response.data.included, response.data.meta);
		} catch(e) {
			throw JsonApiError.format(e.response.data);
		}
	}

	static async resetPassword(data, token) {
		try {
			await timeout(300);
			axios.defaults.headers.common['Authorization'] = token;
			let formattedData = JsonApiSerializer.serialize(data);
	  	let response = await axios.put(`/admins/auth/reset-password`, formattedData);
	  	return JsonApiSerializer.normalize(response.data.data, response.data.included, response.data.meta);
	  } catch(e) {
			throw JsonApiError.format(e.response.data);
		}
	}

}

export default AdminApi;
