import React, { Fragment, useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';
import ProviderList from 'components/provider/provider-list';
import { ButtonText } from 'components/basics/buttons';
import { SectionEmpty } from 'components/basics/empties';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { Pagination } from 'components/basics/pagination';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, timeout, logger } from 'utils/helpers';

const ProvidersSection = (props) => {
	const { title = 'Providers', facilityID, store, toast, onClick } = props;
	const [ providers, setProviders ] = useState([]);
  const [ page, setPage ] = useState(0);
  const [ pageSize, setPageSize ] = useState(5);
	const [ loading, setLoading ] = useState(false);


	// Hooks
	useEffect(() => {
		fetchData();
	}, [page, pageSize]);


	// Methods
	const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query('provider', {
        page: page,
        pageSize: pageSize,
        sortProp: 'id',
        sortValue: 'desc',
        facility_id: facilityID,
      });
      setProviders(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
	}

	return (
    <SectionBlock>
      <SectionHeader title={title} className='flex-between'>
        {onClick && (
          <ButtonText size='xs' variant='gray' onClick={onClick}>
            <FarIcon icon='ellipsis-h' size='2x'/>
          </ButtonText>
        )}
      </SectionHeader>
      <SectionBody>
      	<ProviderList
      		type='list'
      		providers={providers}
      		loading={loading}
      	/>
      </SectionBody>
      <SectionFooter>
      	<Pagination meta={providers.meta} onClick={page => setPage(page)}/>
      </SectionFooter>
    </SectionBlock>
	)
}

export default withStore(withToast(ProvidersSection));
