import { BaseAdapter } from '@mile-hi-labs/react-data';
import Pluralize from 'pluralize';

class AppAdapter extends BaseAdapter {
	static adminID = null;

	static buildURL(modelName, id) {
		let url = super.buildURL(modelName, id);

		if (this.adminID && id) {
			return this.baseURL() + `/admins/${this.adminID}/${Pluralize(modelName)}/${String(id)}`;
		}
		if (this.adminID) {
			return this.baseURL() + `/admins/${this.adminID}/${Pluralize(modelName)}`;
		}
		return url;
	}

}

export default AppAdapter;
