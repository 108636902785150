import React, { Fragment, useEffect, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'components/basics/dropdowns';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, logger } from 'utils/helpers';
import { capitalize } from 'utils/transforms';

const BookingFilter = (props) => {  
  const { title, filter, options = [], loading, store, toast, setFilter } = props;
  const [ drop, toggleDrop ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Render
  return (
    <Dropdown open={drop} toggle={() => toggleDrop(!drop)}>
      <DropdownToggle size='sm' variant='text-gray' taskRunning={loading} onClick={() => toggleDrop(!drop)}>
        {capitalize(title)}<FarIcon icon='chevron-down' className='ml-2'/>
      </DropdownToggle>
      <DropdownMenu open={drop} toggle={() => setDropOpen(!drop)}>
        {options.map(option => (
          <DropdownItem
            key={option}
            title={filter == option ? <span><FarIcon icon='check' className='mr-3'/>{capitalize(option)}</span> : capitalize(option)}
            onClick={() => setFilter(option)}
          />
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}

export default BookingFilter;

