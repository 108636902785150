import React, { Fragment } from 'react';
import FilestackPhotoWrapper from 'components/vendors/filestack-photo-wrapper';
import ProviderSearchInput from 'components/provider/provider-search-input';
import { PhoneMaskedInput } from 'components/vendors/masked-input-wrapper';
import { SubSectionBlock, SubSectionHeader, SubSectionBody } from 'components/basics/sub-sections';
import { Form, FormGroup, FormControl, FormTextArea } from 'components/basics/forms';
import { Container, Row, Col } from 'components/basics/grids';

const ContactFields = (props) => {
	const { model } = props;

	return (
    <Fragment>
      <SubSectionBlock className='bb'>
        <SubSectionHeader title='Details' />
        <SubSectionBody>
          <FormGroup>
            <FilestackPhotoWrapper
              type='user'
              value={model.photo}
              onChange={v => model.set('photo', v)}
            />
          </FormGroup>
          <FormGroup label='Name'>
            <FormControl
              type='text'
              placeholder='Robert Redford'
              value={model.name}
              onChange={e => model.set('name', e.target.value)}
            />
          </FormGroup>

          <FormGroup label='Job Title'>
            <FormControl
              type='text'
              placeholder='Manager'
              value={model.jobTitle}
              onChange={e => model.set('jobTitle', e.target.value)}
            />
          </FormGroup>

          <Row>
            <Col xs='12' sm='6'>
              <FormGroup label='Email Address'>
                <FormControl
                  type='email'
                  placeholder='redford@hollywood.com'
                  value={model.email}
                  onChange={e => model.set('email', e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col xs='12' sm='6'>
              <FormGroup label='Cell Number'>
                <PhoneMaskedInput
                  placeholder='(303) 444-2226'
                  value={model.cellPhone}
                  onChange={e => model.set('cellPhone', e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs='12' sm='6'>
              <FormGroup label='Work Number'>
                <PhoneMaskedInput
                  placeholder='(303) 444-2226'
                  value={model.workPhone}
                  onChange={e => model.set('workPhone', e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col xs='12' sm='6'>
              <FormGroup label='Work Extension'>
                <FormControl
                  placeholder='#412'
                  value={model.workPhoneExt}
                  onChange={e => model.set('workPhoneExt', e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup label='Description'>
            <FormTextArea
              placeholder="What would you like to share about this contact?"
              value={model.description}
              onChange={e => model.set('description', e.target.value)}
            />
          </FormGroup>

        </SubSectionBody>
      </SubSectionBlock>

      <SubSectionBlock title='Relationships'>

        <SubSectionBody>
          <FormGroup label='Select Provider'>
            <ProviderSearchInput
              provider={model.provider}
              onRemove={v => model.set('provider', {} )}
              onSelect={v => model.setRelation('provider', v)}
            />
          </FormGroup>
        </SubSectionBody>

      </SubSectionBlock>

    </Fragment>
  )
}

export default ContactFields;
