import React, { Fragment } from 'react';
import Accounting from 'accounting';
import Moment from 'moment';
import DetailCard from 'components/cards/detail-card';
import ListCard from 'components/cards/list-card';
import SearchCard from 'components/cards/search-card';

const InquiryListCard = (props) => {
	const { inquiry = {}, loading } = props;

	return (
		<ListCard
			icon='inbox'
			status={inquiry.status}
			title={inquiry.user && inquiry.user.name}
			subtitle={inquiry.location && inquiry.location.cityState}
			line1={inquiry.displayDates}
			loading={loading}
			link={`/inquiries/${inquiry.id}`}
		/>
	)
}

const InquirySearchCard = (props) => {
	const { inquiry = {}, type, selectedID, loading, onClick } = props;
	const selected = inquiry.id == selectedID;

	if (type == 'input') {
		return (
			<SearchCard
				icon='inbox'
				status={inquiry.status}
				title={inquiry.user && inquiry.user.name}
				line1={`${Moment(inquiry.move_in).format('MMM Do, YYYY')} - ${Moment(inquiry.move_out).format('MMM Do, YYYY')}`}
				selected={selected}
				onClick={() => onClick(inquiry)}
			/>
		)
	}
	return (
		<SearchCard
			icon='inbox'
			status={inquiry.status}
			title={inquiry.user && inquiry.user.name}
			line1={`${Moment(inquiry.move_in).format('MMM Do, YYYY')} - ${Moment(inquiry.move_out).format('MMM Do, YYYY')}`}
			link={`/inquiries/${inquiry.id}`}
		/>
	)
}

export { InquiryListCard, InquirySearchCard }
