import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withSession } from '@mile-hi-labs/react-session';
import { withToast } from 'contexts/toast-context';
import AdminApi from 'apis/admin-api';

import { Form, FormGroup, FormControl, FormText } from 'components/basics/forms';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { ButtonBlock } from 'components/basics/buttons';
import { Container, Row, Col } from 'components/basics/grids';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, timeout, logger } from 'utils/helpers';

const ForgotPasswordForm = (props) => {
  const { session, store, toast, nextAction } = props;
  const [ email, setEmail ] = useState('');
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Hooks
  useEffect(() => {
    return () => setTaskRunning(false);
  }, []);


  // Methods
  async function forgotPassword() {
    try {
      setTaskRunning(true);
      await AdminApi.forgotPassword({ email: email });
      toast.showSuccess('Forgot password sent!');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


  // Render
  return (
    <Form onSubmit={e => e.preventDefault()}>
      <SectionBlock title='Forgot Password'>
        <SectionBody>

          <FormGroup label='Email Address'>
            <FormControl
              type='email'
              placeholder='redford@hollywood.com'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </FormGroup>
        </SectionBody>

        <SectionFooter>
          <ButtonBlock
            type='submit'
            variant='secondary'
            taskRunning={taskRunning}
            onClick={() => forgotPassword()}>
            Send Email <FarIcon icon='chevron-right' className='ml-2'/>
          </ButtonBlock>
        </SectionFooter>

      </SectionBlock>
    </Form>
  )
}

export default withStore(withSession(withToast(ForgotPasswordForm)));

