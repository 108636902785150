import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withSession } from '@mile-hi-labs/react-session';

import ContactForm from 'components/contact/contact-form';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionBody } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminContactsNewRoute = (props) => {
  const { history, location, session, store } = props;
  const [ contact, setContact ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    createContact();
  }, []);


  // Methods
  const createContact = () => {
    let model = store.createRecord('contact', {});
    setContact(model);
  }


  // Render
  return (
    <AdminRoute title='DMC - New Contact'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <ContactForm
              title='New Contact'
              contact={contact}
              nextAction={() => history.push('/contacts')}
            />
          </SectionBlock>

        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withStore(AdminContactsNewRoute);
