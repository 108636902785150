import React, { useEffect, useState } from 'react';
import { withSession } from '@mile-hi-labs/react-session';
import { withStore } from '@mile-hi-labs/react-data';
import Moment from 'moment';

import AlertForm from 'components/alert/alert-form';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionBody } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminAlertsNewRoute = (props) => {
  const { history, location, session, store } = props;
  const [ alert, setAlert ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    createAlert();
  }, []);


  // Methods
  const createAlert = () => {
    let model = store.createRecord('alert', { selectAll: false } );
    setAlert(model);
  }


  // Render
  return (
    <AdminRoute title='DMC - New Alert'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <AlertForm
              title='New Alert'
              alert={alert}
              nextAction={() => history.push('/alerts')}
            />
          </SectionBlock>

        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withSession(withStore(AdminAlertsNewRoute));
