import React from 'react';
import Axios from 'utils/axios';
import { JsonApiSerializer } from '@mile-hi-labs/react-data';
import { JsonApiError } from '@mile-hi-labs/react-data';
import { timeout } from 'utils/helpers';

const axios = new Axios().instance;

class UserApi {

	static async resetPassword(data, token) {
		try {
			await timeout(300);
			axios.defaults.headers.common['Authorization'] = token;
			let formattedData = JsonApiSerializer.serialize(data);
	  	let response = await axios.put(`/users/auth/reset-password`, formattedData);
	  	return JsonApiSerializer.normalize(response.data.data, response.data.included, response.data.meta);
	  } catch(e) {
			throw JsonApiError.format(e.response.data);
		}
	}

}

export default UserApi;
