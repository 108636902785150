import React, { Fragment, useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';
import ContactList from 'components/contact/contact-list';
import { ButtonText } from 'components/basics/buttons';
import { SectionEmpty } from 'components/basics/empties';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { Pagination } from 'components/basics/pagination';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, timeout, logger } from 'utils/helpers';

const ContactsSection = (props) => {
	const { title = 'Contacts', providerID, store, toast, onClick } = props;
	const [ contacts, setContacts ] = useState([]);
  const [ page, setPage ] = useState(0);
  const [ pageSize, setPageSize ] = useState(5);
	const [ loading, setLoading ] = useState(false);


	// Hooks
	useEffect(() => {
		fetchData();
	}, [page, pageSize]);


	// Methods
	const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query('contact', {
        page: page,
        pageSize: pageSize,
        provider_id: providerID,
        sortProp: 'id',
        sortValue: 'desc',
      });
      setContacts(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
	}

	return (
    <SectionBlock>
      <SectionHeader title={title} className='flex-between'>
        {onClick && (
          <ButtonText size='xs' variant='gray' onClick={onClick}>
            <FarIcon icon='plus' size='2x'/>
          </ButtonText>
        )}
      </SectionHeader>
      <SectionBody>
      	<ContactList
      		type='list'
      		contacts={contacts}
      		loading={loading}
      	/>
      </SectionBody>
      <SectionFooter>
      	<Pagination meta={contacts.meta} onClick={page => setPage(page)}/>
      </SectionFooter>
    </SectionBlock>
	)
}

export default withStore(withToast(ContactsSection));
