import React, { Fragment, useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';

import AnalyticsPreview from 'components/analytics/analytics-preview';
import { SectionEmpty } from 'components/basics/empties';
import { SectionBlock, SectionBody, SectionFooter } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AnalyticsSection = (props) => {
	const { title, store, toast } = props;
	const [ analytics, setAnalytics ] = useState({});
	const [ loading, setLoading ] = useState(false);


	// Hooks
	useEffect(() => {
		fetchData();
	}, []);

	
	// Methods
	const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord('analytic', null);
      setAnalytics(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
	}

	return (
    <SectionBlock title={title}>
      <SectionBody>
      	<AnalyticsPreview
      		type='preview'
      		analytics={analytics}
      		loading={loading}
      	/>
      </SectionBody>
    </SectionBlock>
	)
}

AnalyticsSection.defaultProps = { title: 'Analytics' }

export default withStore(withToast(AnalyticsSection));