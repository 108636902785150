import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withSession } from '@mile-hi-labs/react-session';
import { withToast } from 'contexts/toast-context';

import FacilityFields from 'components/facility/facility-fields';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/basics/modals';
import { Form, FormGroup, FormControl, FormText } from 'components/basics/forms';
import { Button } from 'components/basics/buttons';
import { Container, Row, Col } from 'components/basics/grids';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, logger } from 'utils/helpers';


const FacilityForm = (props) => {
  const { facility = {}, type, title, loading, session, store, toast, nextAction } = props;
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Methods
  const saveFacility = async (facility) => {
    try {
      setTaskRunning(true);
      await facility.save();
      toast.showSuccess('Facility saved!');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  // Render
  if (type == 'modal') {
    return (
      <Form onSubmit={e => e.preventDefault()}>
        <ModalHeader title={title}/>
        <ModalBody size='md'>
          <FacilityFields model={facility}/>
        </ModalBody>
        <ModalFooter>
          <Button
            type='button'
            variant='secondary'
            taskRunning={taskRunning}
            onClick={() => saveFacility(facility)}>
            Save<FarIcon icon='check' className='ml-2'/>
          </Button>
        </ModalFooter>
      </Form>
    )
  }
  return (
    <Form onSubmit={e => e.preventDefault()}>
      <SectionHeader title={title}/>
      <SectionBody size='md'>
        <FacilityFields model={facility}/>
      </SectionBody>
      <SectionFooter>
        <Button
          type='button'
          variant='secondary'
          taskRunning={taskRunning}
          onClick={() => saveFacility(facility)}>
          Save<FarIcon icon='check' className='ml-2'/>
        </Button>
      </SectionFooter>
    </Form>
  )
}

export default withSession(withStore(withToast(FacilityForm)));

