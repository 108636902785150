import React, { Fragment, useState } from 'react';
import ServiceTagSelect from 'components/service-tag/service-tag-select';
import SwitchWrapper from 'components/vendors/switch-wrapper';
import BookingSearchInput from 'components/booking/booking-search-input';
import UserSearchInput from 'components/user/user-search-input';
import LocationFields from 'components/location/location-fields';
import { SubSectionBlock, SubSectionHeader, SubSectionBody } from 'components/basics/sub-sections';
import { Form, FormGroup, FormControl, FormSelect, FormTextArea } from 'components/basics/forms';
import { Container, Row, Col } from 'components/basics/grids';

const ServiceFields = (props) => {
	const { model } = props;
  const [ other, toggleOther ] = useState(false);

	return (
    <Fragment>
      <SubSectionBlock title='Details' className='bb'>
        <SubSectionBody>
          <FormGroup label='Status'>
            <FormSelect
              placeholder='Select status'
              value={model.status}
              options={model.statusOptions && model.statusOptions}
              onChange={e => model.set('status', e.target.value)}
            />
          </FormGroup>
          <FormGroup label="What's the issue?">
            <ServiceTagSelect
              value={model.title}
              other={other}
              toggleOther={toggleOther}
              onChange={v => model.set('title', v)}
            />
          </FormGroup>
          {other && (
            <FormGroup label='Please describe'>
              <FormControl
                type='text'
                placeholder="What's the issue..."
                value={model.title}
                onChange={e => model.set('title', e.target.value)}
              />
            </FormGroup>
          )}

          <FormGroup label='Description'>
            <FormTextArea
              type='text'
              placeholder="Anything else you'd like to share..."
              value={model.description}
              onChange={e => model.set('description', e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <SwitchWrapper
              label='Do we have permission to enter the premises?'
              value={model.permission}
              onChange={v => model.set('permission', v)}
            />
          </FormGroup>

        </SubSectionBody>
      </SubSectionBlock>

      <SubSectionBlock title='Relationships'>
        <SubSectionBody>
          <FormGroup label='Select Booking'>
            <BookingSearchInput
              booking={model.booking}
              onRemove={v => model.set('booking', {})}
              onSelect={v => model.setRelation('booking', v)}
            />
          </FormGroup>

          <FormGroup label='Select User'>
            <UserSearchInput
              user={model.user}
              onRemove={v => model.set('user', {})}
              onSelect={v => model.setRelation('user', v)}
            />
          </FormGroup>
        </SubSectionBody>
      </SubSectionBlock>

    </Fragment>
  )
}

export default ServiceFields;
