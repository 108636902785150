import React, { useEffect, useState } from 'react';
import { withSession } from '@mile-hi-labs/react-session';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';
import ServiceFilter from 'components/service/service-filter';
import ServiceTable from 'components/service/service-table';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { Pagination } from 'components/basics/pagination';
import { Button } from 'components/basics/buttons';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminServicesRoute = (props) => {
  const { history, location, session, store, toast } = props;
  const [ services, setServices ] = useState([]);
  const [ status, setStatus ] = useState('');
  const [ page, setPage ] = useState(0);
  const [ pageSize, setPageSize ] = useState(10);
  const [ sortProp, setSortProp ] = useState('');
  const [ sortValue, setSortValue ] = useState('');
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, [status, page, pageSize, sortProp, sortValue]);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query('service', {
        status: status,
        page: page,
        pageSize: pageSize,
        sortProp: sortProp,
        sortValue: sortValue,
        include: 'booking,user'
      });
      setServices(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const sortData = (prop, value) => {
    setSortProp(prop);
    setSortValue(value);
  }


  // Render
  return (
    <AdminRoute title='DMC - Service Requests'>
      <AdminBreadcrumbs />
        <AdminMain>
          <Container>

            <SectionBlock>
              <SectionHeader title='Service Requests' className='flex-between'>
                <Button size='sm' variant='secondary' onClick={() => history.push('/services/new')}>
                  <FarIcon icon='plus'/>
                </Button>
              </SectionHeader>

              <SectionHeader className='flex-start'>
                <ServiceFilter
                  title={status ? status : 'Status'}
                  filter={status}
                  options={['all', 'draft', 'requested', 'wip', 'canceled', 'completed']}
                  setFilter={value => setStatus(value == 'all' ? '' : value)}
                />
              </SectionHeader>

              <SectionBody>
                <ServiceTable
                  services={services}
                  loading={loading}
                  sortProp={sortProp}
                  sortValue={sortValue}
                  sortData={(prop, value) => sortData(prop, value)}
                  refreshData={() => fetchData()}
                />
              </SectionBody>
              <SectionFooter>
                <Pagination meta={services.meta} onClick={page => setPage(page)} />
              </SectionFooter>
            </SectionBlock>

          </Container>

        </AdminMain>
    </AdminRoute>
  )
}

export default withSession(withStore(withToast(AdminServicesRoute)));
