import React, { Fragment } from 'react';
import DetailCard from 'components/cards/detail-card';
import ListCard from 'components/cards/list-card';
import { SectionEmpty } from 'components/basics/empties';
import { ListSkeleton, GridSkeleton } from 'components/vendors/loading-skeleton-wrapper';

const AlertDetailCard = (props) => {
	const { alert = {}, loading } = props;

	return (
		<DetailCard
			icon='broadcast-tower'
			photo={alert.photo}
			title={alert.title}
			line1={alert.displayMessage}
			loading={loading}
			timestamp={alert.displayUpdatedAt}
		/>
	)
}

const AlertListCard = (props) => {
	const { alert = {}, loading } = props;

	return (
		<ListCard
			icon='broadcast-tower'
			photo={alert.photo}
			title={alert.title}
			line1={alert.displayMessage}
			line2={'Updated ' + alert.displayUpdatedAt}
			loading={loading}
			link={`/alerts/${alert.id}`}
		/>
	)
}

export { AlertDetailCard, AlertListCard }
