import React, { Fragment, useState, useEffect } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';
import AlgoliaService from 'services/algolia-service';
import UserList from 'components/user/user-list';
import { Tag } from 'components/basics/tags';
import { Pagination } from 'components/basics/pagination';
import { UserSearchCard } from 'components/user/user-card';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/basics/modals';
import { FormGroup, FormControl, FormControlDiv, FormControlPrepend, FormControlPreAndAppend } from 'components/basics/forms';
import { isEmpty, logger, timeout, randomString } from 'utils/helpers';

const UserSearchInput = (props) => {
	const { user = {}, store, toast, onRemove, onSelect } = props;
	const [ query, setQuery ] = useState('');
	const [ hits, setHits ] = useState('');
  const [ meta, setMeta ] = useState({});
  const [ modal, toggleModal ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);
	const algolia = new AlgoliaService('users');


	// Hooks
	useEffect(() => {
		modal && searchData(query);
	}, [modal, query]);


  // Methods
  const fetchData = async (recordID) => {
    try {
      setTaskRunning(true);
      let model = await store.findRecord('user', recordID);
      onSelect(model);
      setQuery('');
      toggleModal(!modal);
    } catch (e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  const searchData = async (query = '', page) => {
    try {
      setLoading(true);
  		let model = await algolia.queryIndex(query, page);
      setMeta({ page: model.page, pageSize: model.hitsPerPage, totalRecords: model.nbHits });
  		setHits(model.hits);
    } catch (e) {
      logger(e);
    } finally {
    	setLoading(false);
    }
  }

  const openModal = async () => {
    if (user.id) { return; }
    toggleModal(!modal);
    await timeout(50);
    document.querySelector('#select_user').focus();
  }

  const UserTag = () => {
    return <Tag title={user.name} onClick={() => onRemove(user)} />
  }

	// Render
	return (
    <Fragment>
      <Modal show={modal} toggleModal={() => toggleModal(!modal)}>
        <ModalHeader title='Select User'/>
        <ModalBody className='bb'>
          <FormControlPrepend icon='search' loading={loading}>
            <FormControl
              type='search'
              value={query}
              autoFocus={true}
              placeholder='Search users...'
              className='search'
              onChange={e => setQuery(e.target.value)}
            />
          </FormControlPrepend>
        </ModalBody>
        <ModalBody size='lg' className='search'>
          <UserList
            type='input'
            users={hits}
            selectedID={user.id}
            loading={loading}
            onClick={d => fetchData(d.id)}
          />
        </ModalBody>
        <ModalFooter className='flex-start'>
          <Pagination meta={meta} onClick={page => searchData(query, page)}/>
        </ModalFooter>
      </Modal>
      <FormControlPreAndAppend prependIcon='search' appendIcon='plus' onClick={() => logger('add user')}>
        <FormControlDiv value={user.id ? <UserTag /> : null} placeholder='Search Users...' onClick={() => openModal()} />
      </FormControlPreAndAppend>
    </Fragment>
	)
}

export default withStore(withToast(UserSearchInput));
