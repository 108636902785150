import { library } from '@fortawesome/fontawesome-svg-core';

import { 
	faAddressCard,
	faBars,
	faBells,
	faBolt,
	faBuilding,
	faBroadcastTower,
	faCalendar,
	faChartPieAlt,
	faCheck,
	faCheckCircle,
	faChevronUp,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faCircle,
	faCog,
	faConciergeBell,
	faDollarSign,
	faEllipsisH,
	faHeartRate,
	faInbox,
	faInfoCircle,
	faMapMarker,
	faPlus,
	faQuestionSquare,
	faSearch,
	faSlidersV,
	faSignOut,
	faSort,
	faSortUp,
	faSortDown,
	faSync,
	faTachometerAltFast,
	faTimes,
	faTimesCircle,
	faTrash,
	faUniversity,
	faUser,
	faUserTie,
	faUserCircle,
	faWandMagic
} from '@fortawesome/pro-regular-svg-icons';

library.add(
	faAddressCard,
	faBars,
	faBells,
	faBolt,
	faBuilding,
	faBroadcastTower,
	faCalendar,
	faChartPieAlt,
	faCheck,
	faCheckCircle,
	faChevronUp,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faCircle,
	faCog,
	faConciergeBell,
	faDollarSign,
	faEllipsisH,
	faHeartRate,
	faInbox,
	faInfoCircle,
	faMapMarker,
	faPlus,
	faQuestionSquare,
	faSearch,
	faSlidersV,
	faSignOut,
	faSort,
	faSortUp,
	faSortDown,
	faSync,
	faTachometerAltFast,
	faTimes,
	faTimesCircle,
	faTrash,
	faUniversity,
	faUser,
	faUserTie,
	faUserCircle,
	faWandMagic
)

export default library;