import AppModel from 'models/app';
import { truncate } from 'utils/transforms';

class Service extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);

		// Attrs
		this.status = props.status;
		this.title = props.title;
		this.description = props.description;
		this.permission = props.permission;


		// Relationships
		this.activities = this.hasMany('activity', props.activities);
		this.booking = this.belongsTo('booking', props.booking);
		this.note = this.belongsTo('note', props.note);
		this.user = this.belongsTo('user', props.user);
		this.messages = this.hasMany('message', props.messages);
		this.serviceTags = this.hasMany('serviceTag', props.serviceTags);
	}


	// Computed
	get statusOptions() {
		return ['draft', 'requested', 'wip', 'scheduled', 'follow-up', 'canceled', 'completed']
	}

	get displayDescription() {
		return this.description ? this.description : '--';
	}

	get displayPermission() {
		return this.permission.toString();
	}

	get previewDescription() {
		return this.description ? truncate(this.description, 80) : '--';
	}

	get url() {
		return `/services/${this.id}`
	}
}

export default Service;
