import React, { Fragment } from 'react';
import MessageCard from 'components/cards/message-card';

const MessageRecipientCard = (props) => {
	const { message = {}, loading, onClick } = props;

	return (
		<MessageCard
			avatar={message.isAdmin ? message.admin : message.user}
			status={message.status}
			message={message.message}
			timestamp={message.displayCreatedAt}
			onClick={() => onClick(message)}
			className='recipient'
		/>
	)
}

const MessageSenderCard = (props) => {
	const { message = {}, loading, onClick } = props;

	return (
		<MessageCard
			avatar={message.isAdmin ? message.admin : message.user}
			status={message.status}
			message={message.message}
			timestamp={message.displayCreatedAt}
			onClick={() => onClick(message)}
			className='sender'
		/>
	)
}

export { MessageRecipientCard, MessageSenderCard }
