import React, { useEffect, useState } from 'react';
import { withSession } from '@mile-hi-labs/react-session';

import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionBody } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminAnalyticsRoute = (props) => {
  const { history, session } = props;
  const [ loading, setLoading ] = useState(false);



  // Hooks
  useEffect(() => {
  }, []);


  // Tasks

  // Render
  return (
    <AdminRoute title='DMC - Analytics'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock title='Analytics'>
            <SectionBody>
            </SectionBody>
          </SectionBlock>

        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withSession(AdminAnalyticsRoute);

