import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withSession } from '@mile-hi-labs/react-session';
import { withToast } from 'contexts/toast-context';

import { Avatar } from 'components/basics/avatars';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/basics/modals';
import { Form, FormGroup, FormLabel, FormTextArea } from 'components/basics/forms';
import { Button, ButtonText } from 'components/basics/buttons';
import { Container, Row, Col } from 'components/basics/grids';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, logger } from 'utils/helpers';


const MessageForm = (props) => {
  const { message = {}, title, type, session, store, toast, toggleModal, nextAction } = props;
  const [ taskRunning, setTaskRunning ] = useState(false);
  const [ deleteRunning, setDeleteRunning ] = useState(false);


  // Methods
  const saveMessage = async (message) => {
    try {
      setTaskRunning(true);
      await message.save();
      toast.showSuccess('Message sent!');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  const destroyMessage = async (message) => {
    try {
      setDeleteRunning(true);
      await message.destroy();
      toast.showSuccess('Message destroyed!');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setDeleteRunning(false);
    }
  }


  // Render
  return (
    <Form onSubmit={e => e.preventDefault()}>
      <ModalHeader title={title}/>
      <ModalBody size='md'>
        <FormGroup>
          <FormLabel>
            <Avatar photo={session.user.photo} title={session.user.name}/>
          </FormLabel>
          <FormTextArea
            value={message.message}
            placeholder='What would you like to share?'
            onChange={e => message.set('message', e.target.value)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter className='flex-between'>
        {message.id && (
          <ButtonText
            variant='danger'
            taskRunning={deleteRunning}
            onClick={() => destroyMessage(message)}>
            <FarIcon icon='times' className='mr-2'/>Delete
          </ButtonText>
        )}
        <Button
          type='submit'
          variant='secondary'
          taskRunning={taskRunning}
          onClick={() => saveMessage(message)}>
          Save<FarIcon icon='check' className='ml-2'/>
        </Button>
      </ModalFooter>
    </Form>
  )
}

export default withSession(withStore(withToast(MessageForm)));

