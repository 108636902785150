import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';

import InquiryActions from 'components/inquiry/inquiry-actions';
import InquiryDetailSection from 'components/inquiry/inquiry-detail-section';
import NoteSection from 'components/note/note-section';
import { BookingDetailCard } from 'components/booking/booking-card';
import { LocationDestinationCard } from 'components/location/location-card';
import { UserDetailCard } from 'components/user/user-card';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminInquiriesDetailRoute = (props) => {
  const { inquiryID, history, location, store, toast } = props;
  const [ inquiry, setInquiry ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, []);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord('inquiry', inquiryID, { include: 'user,location,note' });
      setInquiry(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const refreshData = () => {
    fetchData();
  }


  // Render
  return (
    <AdminRoute title={`DMC - Inquiry #${inquiryID}`}>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <SectionHeader title={`Inquiry #${inquiryID}`} className='flex-between no-b'>
              <div className='flex-end'>
                <InquiryActions inquiry={inquiry} />
              </div>
            </SectionHeader>
          </SectionBlock>

          <Row>
            <Col xs={12} md={4}>
              <UserDetailCard user={inquiry.user} loading={loading} />
              <NoteSection
                model={inquiry.note}
                notableId={inquiry.id}
                notableType='Inquiry'
                loading={loading}
              />
            </Col>
            <Col xs={12} md={8}>
              <SectionBlock>
                <SectionHeader title='Destination'>
                </SectionHeader>
                <SectionBody size='xs'>
                  <LocationDestinationCard location={inquiry.location} loading={loading} />
                </SectionBody>
              </SectionBlock>

              <InquiryDetailSection inquiry={inquiry} loading={loading} />

            </Col>
          </Row>
        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withStore(withToast(AdminInquiriesDetailRoute));

