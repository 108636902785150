import AppModel from 'models/app';
import Accounting from 'accounting';

class Analytic extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);

		// Attrs
		this.totalAdmins = props.totalAdmins;
		this.totalBookings = props.totalBookings;
		this.totalContacts = props.totalContacts;
		this.totalFacilities = props.totalFacilities;
		this.totalMessages = props.totalMessages;
		this.totalProviders = props.totalProviders;
		this.totalServices = props.totalServices;
		this.totalUsers = props.totalUsers;
	}

	// Computed
	get displayTotalAdmins() {
		return Accounting.formatNumber(this.totalAdmins);
	}

	get displayTotalBookings() {
		return Accounting.formatNumber(this.totalBookings);
	}

	get displayTotalContacts() {
		return Accounting.formatNumber(this.totalContacts);
	}

	get displayTotalFacilities() {
		return Accounting.formatNumber(this.totalFacilities);
	}

	get displayTotalMessages() {
		return Accounting.formatNumber(this.totalMessages);
	}

	get displayTotalProviders() {
		return Accounting.formatNumber(this.totalProviders);
	}

	get displayTotalServices() {
		return Accounting.formatNumber(this.totalServices);
	}

	get displayTotalUsers() {
		return Accounting.formatNumber(this.totalUsers);
	}

}

export default Analytic;
