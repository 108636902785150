import React from 'react';
import { withRouter } from 'react-router-dom';
import { withMobileNav } from 'contexts/mobile-nav-context';
import { withSession } from '@mile-hi-labs/react-session';

import { Navbar, NavbarBrand, NavbarToggle, Nav } from 'components/basics/navs';
import AdminDropdown from 'components/admin/admin-dropdown';
import { ButtonText } from 'components/basics/buttons';
import { DmcLogo } from 'utils/assets/branding';

const MktNav = (props) => {
	const { history, mobileNav, session } = props;

	// Render
	return (
		<Navbar className='mkt'>
			<NavbarBrand to='/' img={<img src={DmcLogo}/>} className='mr-15' />
		</Navbar>
	)
}

export default withMobileNav(withRouter(withSession(MktNav)));
