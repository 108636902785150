import React, { Fragment } from 'react';
import AdminFields from 'components/admin/admin-fields';
import { SubSectionBlock, SubSectionHeader, SubSectionBody } from 'components/basics/sub-sections';
import { PhoneMaskedInput } from 'components/vendors/masked-input-wrapper';
import { Form, FormGroup, FormSelect } from 'components/basics/forms';
import { Container, Row, Col } from 'components/basics/grids';

const TeammateFields = (props) => {
	const { model } = props;

	return (
    <Fragment>
      <SubSectionBlock title='Details'>
        <AdminFields model={model} />
        <FormGroup label='Role'>
          <FormSelect
            placeholder='Select role'
            value={model.role}
            options={model.roleOptions}
            onChange={e => model.set('role', e.target.value)}
          />
        </FormGroup>
      </SubSectionBlock>

    </Fragment>
  )
}

export default TeammateFields;
