import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';

import { MktRoute } from 'components/basics/routes';
import { MktHeader } from 'components/basics/headers';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const MktNotFoundRoute = (props) => {  
  const { store, toast } = props;
  const [ users, setUsers ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  

  // Hooks  
  useEffect(() => {
  }, []);


  // Tasks


  // Render
  return (
    <MktRoute title='DMC - Not Found'>
      <MktHeader title='Not Found' subtitle='Please login to get started.'/>
      <Container>
      </Container>
    </MktRoute>
  )
}

export default withStore(withToast(MktNotFoundRoute));

