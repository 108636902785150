import React, { Fragment } from 'react';
import { Card, CardHeader, CardImg, CardBody, CardFooter } from 'components/basics/cards';
import { Image } from 'components/basics/images';
import { FarIcon, FadIcon } from 'components/vendors/fa-wrapper';
import { MapSkeleton } from 'components/vendors/loading-skeleton-wrapper';

const MapCard = (props) => {
	const { map, title, line1, line2, line3, timestamp, footer, loading, children } = props;

	if (loading) { return <MapSkeleton count={1}/>}
	return (
		<Card className='detail'>
			{map && map}
			<CardBody className='detail'>
				{title && <h5>{title}</h5>}
				{line1 && <h6>{line1}</h6>}
				{line2 && <h6>{line2}</h6>}
				{line3 && <p>{line3}</p>}
				{children && children}
			</CardBody>
			{timestamp && (
				<CardFooter className='detail'>
					Last updated: {timestamp}
				</CardFooter>
			)}
		</Card>
	)
}

export default MapCard;
