import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';
import SupportDetailSection from 'components/support/support-detail-section';
import { UserDetailCard } from 'components/user/user-card';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { EmailLink } from 'components/basics/links';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminSupportDetailRoute = (props) => {
  const { supportID, history, location, store, toast } = props;
  const [ support, setSupport ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, []);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord('support', supportID, { include: 'user' });
      setSupport(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const refreshData = () => {
    toggleModal(!modal);
    fetchData();
  }


  // Render
  return (
    <AdminRoute title={`DMC - Support #${supportID}`}>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <SectionHeader title={`Support #${supportID}`} className='flex-between no-b'>
              <EmailLink to={support.email} className='btn btn-text-secondary'/>
            </SectionHeader>
          </SectionBlock>

          <Row>
            <Col xs={12} md={4}>
              <UserDetailCard user={support.user} loading={loading}/>
            </Col>
            <Col xs={12} md={8}>
              <SupportDetailSection support={support} loading={loading} />
            </Col>
          </Row>

        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withStore(withToast(AdminSupportDetailRoute));
