import React, { Fragment, useEffect, useState } from 'react';
import { StreetAddressAutocomplete } from 'components/vendors/google-autocomplete-wrapper';
import { SubSectionBlock, SubSectionHeader, SubSectionBody } from 'components/basics/sub-sections';
import { Form, FormGroup, FormControl, FormText } from 'components/basics/forms';
import { Container, Row, Col } from 'components/basics/grids';
import { logger, isEmpty } from 'utils/helpers';

const LocationFields = (props) => {
	const { model = {}, location = {}, className } = props;

	return (
    <Fragment>
      <SubSectionBlock className={className}>
        <SubSectionHeader title='Location' />
        <SubSectionBody>
          <FormGroup label='Address I'>
            <StreetAddressAutocomplete
              placeholder='Search for a place or location...'
              location={location}
              onChange={v => model.setRelation('location', v)}
            />
          </FormGroup>
          <FormGroup label='Address II (optional)'>
            <FormControl
              type='text'
              placeholder='Unit #412'
              value={location.street2}
              onChange={e => location.set('street2', e.target.value)}
            />
          </FormGroup>

          <Row>
            <Col sm='12' md='6'>
              <FormGroup label='City'>
                <FormControl
                  type='email'
                  placeholder='Montville'
                  value={location.city}
                  onChange={e => location.set('city', e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col sm='12' md='3'>
              <FormGroup label='State'>
                <FormControl
                  type='text'
                  placeholder='New Jersey'
                  value={location.state}
                  onChange={e => location.set('state', e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col sm='12' md='3'>
              <FormGroup label='Postal Code'>
                <FormControl
                  type='text'
                  placeholder='80209'
                  value={location.postalCode}
                  onChange={e => location.set('postalCode', e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>

        </SubSectionBody>
      </SubSectionBlock>
    </Fragment>
  )
}

export default LocationFields;
