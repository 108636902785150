import React, { useEffect, useState } from 'react';
import { withSession } from '@mile-hi-labs/react-session';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';

import InquiryForm from 'components/inquiry/inquiry-form';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionBody } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminInquiriesEditRoute = (props) => {
  const { inquiryID, history, location, session, store, toast } = props;
  const [ inquiry, setInquiry ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, []);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord('inquiry', inquiryID, { include: 'user,location' })
      setInquiry(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }



  // Render
  return (
    <AdminRoute title='DMC - Edit Inquiry'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <InquiryForm
              title='Edit Inquiry'
              inquiry={inquiry}
              nextAction={() => history.push(`/inquiries/${inquiryID}`)}
            />
          </SectionBlock>

        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withSession(withStore(withToast(AdminInquiriesEditRoute)));
