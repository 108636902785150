import React, { Fragment } from 'react';
import MapCard from 'components/cards/map-card';
import GoogleMapWrapper from 'components/vendors/google-map-wrapper';

const LocationDetailCard = (props) => {
	const { location = {}, loading } = props;

	return (
		<MapCard
			map={<GoogleMapWrapper size='xs' model={location} className='card'/>}
			title={location.id ? location.street : 'Location Not Available'}
			line1={location.street2}
			line2={location.cityStatePostal}
			timestamp={location.displayUpdatedAt}
			loading={loading}
		/>
	)
}

const LocationDestinationCard = (props) => {
	const { location = {}, loading } = props;

	return (
		<MapCard
			map={<GoogleMapWrapper size='xs' model={location} className='card'/>}
			title={location.id ? location.cityState : 'Location Not Available'}
			timestamp={location.displayUpdatedAt}
			loading={loading}
		/>
	)
}

export { LocationDetailCard, LocationDestinationCard }