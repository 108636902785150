import AppModel from 'models/app';

class Message extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);

		// Attrs
		this.subject = props.subject;
		this.message = props.message;
		this.messageableId = props.messageableId;
		this.messageableType = props.messageableType;


		// Relationships
		this.admin = this.belongsTo('admin', props.admin);
		this.service = this.belongsTo('service', props.service);
		this.user = this.belongsTo('user', props.user);
	}

	get isAdmin() {
		return this.messageableType == 'Admin';
	}

}

export default Message;
