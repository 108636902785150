import React, { Fragment } from 'react';
import SupportActions from 'components/support/support-actions';
import { AvatarImage } from 'components/basics/avatars';
import { SectionEmpty } from 'components/basics/empties';
import { Table, TableHeader, TableHeaderRow, TableHead, TableHeadSort, TableBody, TableRow, TableLink, TableData } from 'components/basics/tables';
import { TableSkeleton } from 'components/vendors/loading-skeleton-wrapper';
import { capitalize } from 'utils/transforms';

const SupportTable = (props) => {
	const { supports = [], sortProp, sortValue, sortData, loading, refreshData } = props;

	if (loading) {
		return <TableSkeleton count={6} />
	}
	else if (supports.length > 0) {
		return (
			<Table>
				<TableHeader>
					<TableHeaderRow>
						<TableHeadSort title='Id' value='id' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHead title='Photo'/>
						<TableHead title='Customer'/>
						<TableHeadSort title='Reason' value='reason' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHead title='Description'/>
						<TableHeadSort title='Updated' value='updated_at' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHead title='Actions'/>
					</TableHeaderRow>
				</TableHeader>
				<TableBody>
					{supports.map(support => (
						<TableRow key={support.id}>
							<TableLink to={support.url} value={'#' + support.id}/>
							<TableLink to={support.url} value={<AvatarImage size='sm' photo={support.user && support.user.photo}/>}/>
							<TableLink to={support.url}><b>{support.name}</b></TableLink>
							<TableLink to={support.url} value={support.reason}/>
							<TableLink to={support.url} value={support.previewMessage}/>
							<TableLink to={support.url} value={support.displayUpdatedAt}/>
							<TableData>
								<SupportActions support={support} table={true} refreshData={refreshData} />
							</TableData>
						</TableRow>
					))}
				</TableBody>
			</Table>
		)
	}
	return (
		<SectionEmpty
			icon='question-square'
			title='Support Inquiries'
			msg="Sorry, looks like we don't have any support inquiries yet."
		/>
	)
}

export default SupportTable;
