import React, { Fragment } from 'react';
import DetailCard from 'components/cards/detail-card';
import ListCard from 'components/cards/list-card';
import PreviewCard from 'components/cards/preview-card';
import SearchCard from 'components/cards/search-card';

const FacilityDetailCard = (props) => {
	const { facility = {}, loading } = props;

	return (
		<DetailCard
			icon='building'
			title={facility.name}
			line1={facility.web}
			line2={facility.displayPhone}
			timestamp={facility.displayUpdatedAt}
			loading={loading}>
			<p>{facility.displayDescription}</p>
		</DetailCard>
	)
}

const FacilityListCard = (props) => {
	const { facility = {}, loading } = props;


	return (
		<ListCard
			icon='building'
			photo={facility.photo}
			title={facility.name}
			subtitle={facility.location && facility.location.cityState}
			line1={facility.displayPhone}
			loading={loading}
			link={`/facilities/${facility.id}`}
		/>
	)
}

const FacilityPreviewCard = (props) => {
	const { facility = {}, loading } = props;

	return (
		<PreviewCard
			icon='building'
			photo={facility.photo}
			title={facility.name}
			subtitle={facility.location && facility.location.cityState}
			line1={facility.displayPhone}
			loading={loading}
			onClick={() => onClick(facility.id)}
		/>
	)
}

const FacilitySearchCard = (props) => {
	const { facility = {}, type, selectedID, loading, onClick } = props;
	const selected = facility.id == selectedID;

	if (type == 'input') {
		return (
			<SearchCard
				icon='building'
				title={facility.name}
				line1={facility.description}
				line2={facility.location && facility.location.city}
				selected={selected}
				loading={loading}
				onClick={() => onClick(facility)}
			/>
		)
	}
	return (
		<SearchCard
			icon='building'
			title={facility.name}
			line1={facility.description}
			line2={facility.location && facility.location.city}
			loading={loading}
			link={`/facilities/${facility.id}`}
		/>
	)
}

export { FacilityDetailCard, FacilityListCard, FacilityPreviewCard, FacilitySearchCard }
