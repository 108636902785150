import AppSerializer from 'serializers/app';

class ContactSerializer extends AppSerializer {
	static relationships = {
		...super.relationships,
		provider: { serialize: 'id' },
	}


}

export default ContactSerializer;
