import AppSerializer from 'serializers/app';
import Moment from 'moment';

class BookingSerializer extends AppSerializer {
	static relationships = {
		...super.relationships,
		facility: { serialize: 'id' },
		provider: { serialize: 'id' },
		user: { serialize: 'id' },
	}

	static serializeAttr(data, key) {
		let formattedAttr = super.serializeAttr(data, key);

		if (key == 'billingRate') {
			return parseFloat(data[key]);
		}
		if (key == 'netRate') {
			return parseFloat(data[key]);
		}
		if (key.includes('move')) {
			return Moment(data[key]).toISOString();
		}
		return formattedAttr;
	}


}

export default BookingSerializer;
