import AppModel from 'models/app';
import { stringToPhone } from 'utils/transforms';

class Facility extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);


		// Attrs
		this.photo = props.photo;
		this.name = props.name;
		this.phone = props.phone;
		this.web = props.web;
		this.description = props.description;


		// Relationships
		this.location = this.belongsTo('location', props.location);
		this.note = this.belongsTo('note', props.note);
		this.providers = this.hasMany('provider', props.providers);
	}

	// Computed
	get providerIds() {
		return this.providers.map(provider => provider.id);
	}

	get displayPhone() {
		return this.phone && stringToPhone(this.phone);
	}

	get displayDescription() {
		return this.description ? this.description : '--';
	}

	get url() {
		return `/facilities/${this.id}`
	}

}

export default Facility;
