import React, { Fragment } from 'react';
import { UserListCard, UserSearchCard } from 'components/user/user-card';
import { SectionEmpty } from 'components/basics/empties';
import { Row, Col } from 'components/basics/grids';
import { ListSkeleton, SearchSkeleton } from 'components/vendors/loading-skeleton-wrapper';

const UserList = (props) => {
	const { users = [], type, selectedID, selectedIDs, loading, onClick } = props;

	if (loading) {
		return (
			<Fragment>
				{(type == 'input' || type == 'search') ? (
					<SearchSkeleton count={3} />
				) : (
					<ListSkeleton count={3} />
				)}
			</Fragment>
		)
	}
	else if (users.length > 0) {
		return (
			<Row>
				{(type == 'input' || type == 'search') && users.map(user => (
					<Col key={user.id} xs={12}>
						<UserSearchCard
							type={type}
							user={user}
							selectedIDs={selectedIDs}
							selectedID={selectedID}
							onClick={onClick}
						/>
					</Col>
				))}
				{type == 'list' && users.map(user => (
					<Col key={user.id} xs={12}>
						<UserListCard
						 	type={type}
							user={user}
						/>
					</Col>
				))}
			</Row>
		)
	}
	return (
		<SectionEmpty
			icon='user'
			title='Users'
			msg="Sorry, looks like there aren't any users yet."
		/>
	)
}

export default UserList;
