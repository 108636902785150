
import AppModel from 'models/app';
import { truncate, stringToPhone } from 'utils/transforms';

class Support extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);

		// Attrs
		this.name = props.name;
		this.email = props.email;
		this.phone = props.phone;
		this.reason = props.reason;
		this.message = props.message;


		// Relationships
		this.user = this.belongsTo('user', props.user);
	}

	// Computed
	get displayPhone() {
		return this.phone && stringToPhone(this.phone);
	}

	get previewMessage() {
		return this.message ? truncate(this.message, 80) : '--';
	}

	get displayMessage() {
		return this.message ? this.message : '--';
	}

	get url() {
		return `/support/${this.id}`
	}
}

export default Support;
