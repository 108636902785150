import React, { Fragment, useEffect, useState } from 'react';
import { withSession } from '@mile-hi-labs/react-session';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';

import { BtnLink } from 'components/basics/links';
import { ButtonText } from 'components/basics/buttons';
import { MktHeader } from 'components/basics/headers';
import { AuthMain } from 'components/basics/mains';
import { MktRoute } from 'components/basics/routes';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, timeout, logger } from 'utils/helpers';

const MktIndex = (props) => {
  const { session, store, toast } = props;
  const [ users, setUsers ] = useState([]);
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
  }, []);


  // Tasks


  // Render
  return (
    <MktRoute title='DMC - Welcome'>
      <AuthMain>
        <Container className='sm'>
          <SectionBlock>
            <SectionHeader
              title='Welcome to the DMC Admin'
              subtitle={session.authenticated() ? "You're currently logged in." : "Please login to continue"}
            />
            <SectionBody className='xs'>
              <div className='mt-15 mb-15 flex-center'>
                {session.authenticated() ? (
                  <BtnLink to='/dashboard' title='Visit Dashboard' icon='chevron-right' className='btn-block btn-lg btn-primary'/>
                ) : (
                  <Fragment>
                    <BtnLink to='/login' title='Login' icon='chevron-right' className='btn-block btn-lg btn-secondary mr-15'/>
                    <BtnLink to='/register' title='Register' icon='chevron-right' className='btn-block btn-lg btn-primary'/>
                  </Fragment>
                )}
              </div>
            </SectionBody>
          </SectionBlock>
        </Container>
      </AuthMain>
    </MktRoute>
  )
}

export default withSession(withStore(withToast(MktIndex)));

