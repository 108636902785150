import AlgoliaSearch from 'algoliasearch/lite';
import { isEmpty, logger } from 'utils/helpers';

const ENV = process.env.REACT_APP_NODE_ENV;
const ALGOLIA_ID = '1G0OCXWM9T';
const ALGOLIA_KEY = process.env.REACT_APP_ALGOLIA_KEY;

class AlgoliaService {
	constructor(index) {
		this.index = index;
		this.indices = ['admins', 'contacts', 'facilities', 'providers', 'users']; // bookings, services
	}


	// Props
	get client() {
		return AlgoliaSearch(ALGOLIA_ID, ALGOLIA_KEY);
	}


	// Methods
	indexName(index) {
		return index + '_' + ENV;
	}

	buildIndex(query) {
		return this.client.initIndex(this.indexName(this.index));
	}

	buildIndices(query) {
		let indices = this.indices;
		let formattedIndices = indices.map(index => this.indexName(index));
		return formattedIndices.map(index => {
			return {
				indexName: index,
				query: query,
				params: {
					hitsPerPage: 5
				}
			}
		})
	}

	async queryIndex(query = '', page = 0) {
		let client = this.buildIndex();
		let result = await client.search(query, {
			hitsPerPage: 10,
			page: page
		});
		logger('Results: ', result);
		return result;
	}

	async queryIndices(query) {
		let formattedQueries = this.buildIndices(query);
		let result = await this.client.multipleQueries(formattedQueries);
		logger('Results: ', result);
		return result.results;
	}


}

export default AlgoliaService;

// Docs
// https://www.algolia.com/doc/api-client/methods/search
