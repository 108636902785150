import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withSession } from '@mile-hi-labs/react-session';
import { withToast } from 'contexts/toast-context';

import NotificationList from 'components/notification/notification-list';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { Pagination } from 'components/basics/pagination';
import { Button } from 'components/basics/buttons';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminNotificationsRoute = (props) => {
  const { history, location, session, store, toast } = props;
  const [ notifications, setNotifications ] = useState([]);
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, []);


  // Methods
  const fetchData = async (page = 0, pageSize = 10) => {
    try {
      setLoading(true);
      let model = await store.query('notification', {
        page: page,
        pageSize: pageSize,
      });
      setNotifications(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const refreshData = () => {
    toggleModal(!modalOpen);
    fetchData();
  }



  // Render
  return (
    <AdminRoute title='DMC - Notifications'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock title='Notifications'>
            <SectionBody>
              <NotificationList
                type='preview'
                notifications={notifications}
                loading={loading}
                onClick={value => logger(value)}
              />
            </SectionBody>

            <SectionFooter>
              <Pagination meta={notifications.meta} onClick={page => fetchData(page)}/>
            </SectionFooter>
          </SectionBlock>

        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withSession(withStore(withToast(AdminNotificationsRoute)));
