import React, { Fragment } from 'react';
import DetailCard from 'components/cards/detail-card';
import ListCard from 'components/cards/list-card';
import PreviewCard from 'components/cards/preview-card';
import SearchCard from 'components/cards/search-card';
import { isEmpty } from 'utils/helpers';

const ProviderDetailCard = (props) => {
	const { provider = {}, loading } = props;

	return (
		<DetailCard
			icon='building'
			photo={provider.photo}
			title={provider.name}
			line1={<a href={`mailto:${provider.email}`}>{provider.email}</a>}
			line2={provider.displayPhone}
			timestamp={provider.displayUpdatedAt}
			loading={loading}>
			<p>{provider.displayDescription}</p>
		</DetailCard>
	)
}

const ProviderListCard = (props) => {
	const { provider = {}, loading } = props;

	return (
		<ListCard
			icon='building'
			photo={provider.photo}
			title={provider.name}
			subtitle={provider.location && provider.location.cityState}
			line1={provider.displayPhone}
			loading={loading}
			link={`/providers/${provider.id}`}
		/>
	)
}

const ProviderPreviewCard = (props) => {
	const { provider = {}, loading, onClick } = props;

	return (
		<PreviewCard
			icon='building'
			photo={provider.photo}
			title={provider.name}
			subtitle={provider.location && provider.location.cityState}
			line1={provider.displayPhone}
			loading={loading}
			onClick={() => onClick(provider.id)}
		/>
	)
}

const ProviderSearchCard = (props) => {
	const { provider = {}, type, selectedIDs, selectedID, loading, onClick } = props;
	const selected = !isEmpty(selectedIDs) ? selectedIDs.includes(provider.id) : provider.id == selectedID;

	if (type == 'input') {
		return (
			<SearchCard
				icon='building'
				title={provider.name}
				line1={provider.email}
				line2={provider.location && provider.location.city}
				selected={selected}
				onClick={() => onClick(provider)}
			/>
		)
	}
	return (
		<SearchCard
			icon='building'
			title={provider.name}
			line1={provider.email}
			line2={provider.location && provider.location.city}
			link={`/providers/${provider.id}`}
		/>
	)
}

export { ProviderDetailCard, ProviderListCard, ProviderPreviewCard, ProviderSearchCard }
