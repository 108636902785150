import React, { useEffect, useState } from 'react';
import { withSession } from '@mile-hi-labs/react-session';

import ListCard from 'components/cards/list-card';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionBody } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminSettingsCustomizationsRoute = (props) => {
  const { history, location, session } = props;
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
  }, []);


  // Tasks


  // Render
  return (
    <AdminRoute title='DMC - Customizations'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock title='Customizations'>
            <SectionBody>
              <ListCard
                icon='tags'
                title='Service tags'
                line1="Manage the tagging system associated with service requests."
                link='/settings/tags'
              />

{/*              <ListCard
                icon='shield'
                title='Military Branch / Rankings'
                line1="Manage the military branches and rankings associated with users."
                link='/settings/customizations/militaries'
              />*/}

            </SectionBody>
          </SectionBlock>

        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withSession(AdminSettingsCustomizationsRoute);
