import AppSerializer from 'serializers/app';

class LocationSerializer extends AppSerializer {
	static relationships = {
		...super.relationships,
		booking: { serialize: false },
		location: { serialize: false },
		provider: { serialize: false },
		user: { serialize: false },
	}
}

export default LocationSerializer;
