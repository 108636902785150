import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withSession } from '@mile-hi-labs/react-session';
import { withToast } from 'contexts/toast-context';

import ServiceForm from 'components/service/service-form';
import TeammateList from 'components/teammate/teammate-list';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { Pagination } from 'components/basics/pagination';
import { Button } from 'components/basics/buttons';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminSettingsTeammatesIndexRoute = (props) => {
  const { history, location, session, store, toast } = props;
  const [ teammates, setTeammates ] = useState([]);
  const [ page, setPage ] = useState(0);
  const [ pageSize, setPageSize ] = useState(10);
  const [ modalOpen, toggleModal ] = useState(false);
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, []);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query('admin', {
        page: page,
        pageSize: pageSize,
        sortProp: 'id',
        sortValue: 'desc',
      });
      model = model.filter(teammate => teammate.id != session.user.id);
      setTeammates(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const refreshData = () => {
    toggleModal(!modalOpen);
    fetchData();
  }


  // Render
  return (
    <AdminRoute title='DMC - Teammates'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <SectionHeader title='Teammates' className='flex-between'>
              <Button size='sm' onClick={() => history.push('/settings/teammates/new')}>
                <FarIcon icon='plus'/>
              </Button>
            </SectionHeader>
            <SectionBody>
              <TeammateList
                type='list'
                teammates={teammates}
                admin={session.user}
                loading={loading}
              />
            </SectionBody>
            <SectionFooter>
              <Pagination meta={teammates.meta} onClick={page => setPage(page)}/>
            </SectionFooter>
          </SectionBlock>

        </Container>

      </AdminMain>
    </AdminRoute>
  )
}

export default withSession(withStore(withToast(AdminSettingsTeammatesIndexRoute)));
