import AppModel from 'models/app';

class ServiceTag extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);

		// Attrs
		this.title = props.title;
		this.description = props.description;


		// Relationships
		this.services = this.hasMany('service', props.services);
	}


	// Computed
	get displayDescription() {
		return this.description ? this.description : '--';
	}
}

export default ServiceTag;
