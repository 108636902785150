import React, { useState } from 'react';
export const ThemeContext = React.createContext();

const ThemeProvider = (props) => {
  const { children } = props;
  const [ dark, setDark ] = useState(false);


  // Method
  const toggleDark = () => setDark(!dark);


  // Render
  return (
    <ThemeContext.Provider value={{dark, toggleDark}}>
      {children}
    </ThemeContext.Provider>
  )
}

const withTheme = function(WrappedComponent) {
  return (props) => (
    <ThemeContext.Consumer>
      {context => <WrappedComponent theme={context} {...props}/>}
    </ThemeContext.Consumer>
  )
}

export { ThemeProvider, withTheme }
