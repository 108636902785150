import React, { Fragment } from 'react';
import { ContactListCard, ContactPreviewCard, ContactSearchCard } from 'components/contact/contact-card';
import { SectionEmpty } from 'components/basics/empties';
import { Row, Col } from 'components/basics/grids';
import { ListSkeleton, SearchSkeleton } from 'components/vendors/loading-skeleton-wrapper';

const ContactList = (props) => {
	const { contacts = [], type, selectedID, loading, onClick } = props;

	if (loading) {
		return (
			<Fragment>
				{(type == 'input' || type == 'search') ? (
					<SearchSkeleton count={3} />
				) : (
					<ListSkeleton count={3} />
				)}
			</Fragment>
		)
	} 
	else if (contacts.length > 0) {
		return (
			<Row>
				{(type == 'input' || type == 'search') && contacts.map(contact => (
					<Col key={contact.id} xs={12}>
						<ContactSearchCard
							type={type}
							contact={contact}
							selectedID={selectedID}
							onClick={onClick}
						/>
					</Col>
				))}
				{type == 'list' && contacts.map(contact => (
					<Col key={contact.id} xs={12}>
						<ContactListCard
						 	type={type}
							contact={contact}
							onClick={onClick}
						/>
					</Col>
				))}
				{type == 'preview' && contacts.map(contact => (
					<Col key={contact.id} xs={12}>
						<ContactPreviewCard
						 	type={type}
							contact={contact}
							onClick={onClick}
						/>
					</Col>
				))}
			</Row>
		)
	}
	return (
		<SectionEmpty
			icon='user'
			title='Contacts'
			msg="Sorry, looks like we don't have any contacts yet."
		/>
	)
}

export default ContactList;