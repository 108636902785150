import AppModel from 'models/app';
import { stringToPhone } from 'utils/transforms';

class Provider extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);

		// Attrs
		this.photo = props.photo;
		this.name = props.name;
		this.email = props.email;
		this.phone = props.phone;
		this.web = props.web;
		this.description = props.description;


		// Relationships
		this.location = this.belongsTo('location', props.location);
		this.note = this.belongsTo('note', props.note);
		this.bookings = this.hasMany('booking', props.bookings);
		this.contacts = this.hasMany('contact', props.contacts);
	}


	// Computed
	get displayPhone() {
		return this.phone && stringToPhone(this.phone);
	}

	get displayDescription() {
		return this.description ? this.description : '--';
	}

	get url() {
		return `/providers/${this.id}`
	}
}

export default Provider;
