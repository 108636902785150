import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';

import FacilityForm from 'components/facility/facility-form';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionBody } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminFacilitiesEditRoute = (props) => {
  const { facilityID, history, location, store, toast } = props;
  const [ facility, setFacility ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, []);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord('facility', facilityID, { include: 'location,providers' });
      setFacility(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }


  // Render
  return (
    <AdminRoute title='DMC - Edit Facility'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <FacilityForm
              title='Edit Facility'
              facility={facility}
              loading={loading}
              nextAction={() => history.push(`/facilities/${facilityID}`)}
            />
          </SectionBlock>

        </Container>

      </AdminMain>
    </AdminRoute>
  )
}

export default withStore(withToast(AdminFacilitiesEditRoute));
