import React, { Fragment, useState, useEffect } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';

import AlgoliaService from 'services/algolia-service';
import ProviderList from 'components/provider/provider-list';
import { Tag } from 'components/basics/tags';
import { Pagination } from 'components/basics/pagination';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/basics/modals';
import { FormGroup, FormControl, FormControlDiv, FormControlPrepend, FormControlPreAndAppend } from 'components/basics/forms';
import { isEmpty, logger, timeout, randomString } from 'utils/helpers';

const ProviderSearchInput = (props) => {
	const { provider = {}, store, toast, onRemove, onSelect } = props;
	const [ query, setQuery ] = useState('');
	const [ hits, setHits ] = useState('');
  const [ meta, setMeta ] = useState({});
	const [ modal, toggleModal ] = useState(false);
	const [ loading, setLoading ] = useState(false);
	const [ taskRunning, setTaskRunning ] = useState(false);
	const algolia = new AlgoliaService('providers');


  // Hooks
	useEffect(() => {
		modal && searchData(query);
	}, [modal, query]);


  // Methods
  const fetchData = async (recordID) => {
    try {
      setTaskRunning(true);
      let model = await store.findRecord('provider', recordID, { include: 'location' });
      onSelect(model);
      setQuery('');
      toggleModal(!modal);
    } catch (e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  const searchData = async (value = '', page) => {
    try {
    	setLoading(true);
      await timeout(300);
  		let model = await algolia.queryIndex(value, page);
      setMeta({ page: model.page, pageSize: model.hitsPerPage, totalRecords: model.nbHits });
  		setHits(model.hits);
    } catch (e) {
      logger(e);
    } finally {
    	setLoading(false);
    }
  }

  const openModal = async () => {
    if (provider.id) { return; }
  	toggleModal(!modal);
  	await timeout(50);
  	document.querySelector('#select_provider').focus();
  }

  const ProviderTag = () => {
    return <Tag title={provider.name} onClick={() => onRemove(provider)} />
  }

	// Render
	return (
		<Fragment>
      <Modal show={modal} toggleModal={() => toggleModal(!modal)}>
       	<ModalHeader title='Select Provider'/>
       	<ModalBody className='bb'>
	       	<FormControlPrepend icon='search' loading={loading}>
		  			<FormControl
		  				type='search'
				      value={query}
				      autoFocus={true}
				      placeholder='Search providers...'
				      className='search'
				      onChange={e => setQuery(e.target.value)}
			      />
		      </FormControlPrepend>
	      </ModalBody>
	      <ModalBody size='lg' className='search'>
	    		<ProviderList
	    			type='input'
	    			providers={hits}
	    			selectedID={provider.id}
	    			loading={loading}
	    			onClick={d => fetchData(d.id)}
    			/>
	      </ModalBody>
        <ModalFooter className='flex-start'>
          <Pagination meta={meta} onClick={page => searchData(query, page)}/>
        </ModalFooter>
      </Modal>
  		<FormControlPreAndAppend prependIcon='search' appendIcon='plus' onClick={() => logger('add provider')}>
        <FormControlDiv value={provider.id ? <ProviderTag /> : null} placeholder='Search Providers...' onClick={() => openModal()}/>
      </FormControlPreAndAppend>
    </Fragment>
	)
}

export default withStore(withToast(ProviderSearchInput));
