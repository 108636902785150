import React, { Fragment } from 'react';
import PreviewCard from 'components/cards/preview-card';

const ServiceTagPreviewCard = (props) => {
	const { tag = {}, loading, onClick } = props;
	return (
		<PreviewCard
			icon='tags'
			title={tag.title}
			line1={tag.displayDescription}
			loading={loading}
			onClick={() => onClick(tag)}
		/>
	)
}

export { ServiceTagPreviewCard }