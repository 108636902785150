import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withSession } from '@mile-hi-labs/react-session';
import BurgerMenu from 'react-burger-menu';
import { MobileNavHeader, MobileNavHero, MobileNavBody, MobileNavFooter } from 'components/basics/mobile-nav';
import { Nav, NavBtn, NavLink } from 'components/basics/navs';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { ButtonText } from 'components/basics/buttons';
import { Link } from 'components/basics/links';
import { isEmpty, logger } from 'utils/helpers';

export const MobileNavContext = React.createContext();

const MobileNavWrapper = (props) => {
  const { history, location, session, children } = props;
  const [ menu, setMenu ] = useState(false);
  const [ route, setRoute ] = useState('');
  const Menu = BurgerMenu['slide'];


  // Hooks
  useEffect(() => {
    updateRoute();
  }, [location])


  // Methods
  const updateRoute = () => {
    if (route != location.pathname) {
      setRoute(location.pathname);
      setMenu(false);
    }
  }

  const toggleMenu = () => setMenu(!menu);


  // Render
  return (
    <MobileNavContext.Provider value={{menu, toggleMenu}}>
      <Menu
        isOpen={menu}
        outerContainerId='application'
        pageWrapId='route'
        width='80%'
        side='left'
        className='mobile-nav-wrapper'
        onStateChange={v => setMenu(v.isOpen)}>

       <MobileNavHero>
          {session.authenticated() ? (
            <Fragment>
              <img className='mobile-nav-user circle mb-15' src={session.user.photo}/>
              <h3 className='title'>{session.user.name}</h3>
              <h6 className='subtitle'>{session.user.email}</h6>
              <Link to='/settings/profile/detail' className='white-color mt-15 underline'>View Profile</Link>
            </Fragment>
          ) : (
            <Fragment>
              <h3 className='title'>Hey There!</h3>
              <h6 className='subtitle'>Where would you like to head?</h6>
            </Fragment>
          )}
        </MobileNavHero>

        <MobileNavBody>
          {session.authenticated() ? (
            <Fragment>
              <NavLink to='/dashboard' className='lg'>Dashboard</NavLink>
              <NavLink to='/alerts' className='lg'>Alerts</NavLink>
              <NavLink to='/inquiries' className='lg'>Inquiries</NavLink>
              <NavLink to='/bookings' className='lg'>Bookings</NavLink>
              <NavLink to='/contacts' className='lg'>Contacts</NavLink>
              <NavLink to='/facilities' className='lg'>Facilities</NavLink>
              <NavLink to='/providers' className='lg'>Providers</NavLink>
              <NavLink to='/services' className='lg'>Services</NavLink>
              <NavLink to='/users' className='lg'>Users</NavLink>
              <NavLink to='/support' className='lg'>Support</NavLink>
              <NavLink to='/settings' className='lg'>Settings</NavLink>
              <NavBtn className='lg' onClick={() => session.logout()}>Log out</NavBtn>
            </Fragment>
          ) : (
            <Fragment>
              <NavLink to='/login' className='lg'>Login</NavLink>
              <NavLink to='/register' className='lg'>Register</NavLink>
            </Fragment>
          )}
          <hr />
          <NavLink to='/' className='sub-font'>Web</NavLink>
          <NavLink to='/our-services' className='sub-font'>Facebook</NavLink>
          <NavLink to='/trust-safety' className='sub-font'>Twitter</NavLink>
          <NavLink to='for-stylists' className='sub-font'>App Store</NavLink>
        </MobileNavBody>
      </Menu>
      {children}
    </MobileNavContext.Provider>
  )
};

const withMobileNav = function(WrappedComponent) {
  return (props) => (
    <MobileNavContext.Consumer>
      {context => <WrappedComponent mobileNav={context} {...props}/>}
    </MobileNavContext.Consumer>
  )
}

const MobileNavProvider = withSession(withRouter(MobileNavWrapper));

export { MobileNavProvider, withMobileNav }

// Docs
// https://github.com/negomi/react-burger-menu
