import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';
import AlertActions from 'components/alert/alert-actions';
import NotificationsSection from 'components/notification/notifications-section';
import { AlertDetailCard } from 'components/alert/alert-card';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminAlertsDetailRoute = (props) => {
  const { alertID, history, store, toast } = props;
  const [ alert, setAlert ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, []);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord('alert', alertID);
      setAlert(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }


  // Render
  return (
    <AdminRoute title={`DMC - Alert #${alertID}`}>
      <AdminBreadcrumbs/>
      <AdminMain>
        <Container>

          <SectionBlock>
            <SectionHeader title={`Alert #${alertID}`} className='flex-between no-b'>
              <AlertActions alert={alert} />
            </SectionHeader>
          </SectionBlock>

          <Row>
            <Col xs={12} md={4}>
              <AlertDetailCard alert={alert} loading={loading}/>
            </Col>
            <Col xs={12} md={8}>
              <NotificationsSection alertID={alert.id} />
            </Col>
          </Row>
        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withStore(withToast(AdminAlertsDetailRoute));

