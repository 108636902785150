import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withSession } from '@mile-hi-labs/react-session';
import { withToast } from 'contexts/toast-context';

import ServiceTagForm from 'components/service-tag/service-tag-form';
import ServiceTagList from 'components/service-tag/service-tag-list';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/basics/modals';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { Pagination } from 'components/basics/pagination';
import { Button } from 'components/basics/buttons';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminSettingsTagsIndexRoute = (props) => {
  const { history, location, session, store, toast } = props;
  const [ tags, setTags ] = useState([]);
  const [ tag, setTag ] = useState({});
  const [ page, setPage ] = useState(0);
  const [ pageSize, setPageSize ] = useState(10);
  const [ modal, toggleModal ] = useState(false);
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, []);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query('service-tag', {
        page: page,
        pageSize: pageSize,
        sortProp: 'id',
        sortValue: 'desc',
      });
      setTags(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const openModal = (model = {}) => {
    model.id ? setTag(model) : setTag(store.createRecord('service-tag', {}));
    toggleModal(!modal);
  }

  const refreshData = () => {
    toggleModal(!modal);
    fetchData();
  }


  // Render
  return (
    <AdminRoute title='DMC - Service Tags'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <SectionHeader title='Service Tags' className='flex-between'>
              <Button size='sm' variant='secondary' onClick={() => openModal()}>
                <FarIcon icon='plus'/>
              </Button>
            </SectionHeader>

            <SectionBody>
              <ServiceTagList
                type='preview'
                tags={tags}
                loading={loading}
                onClick={tag => openModal(tag)}
              />

            </SectionBody>
            <SectionFooter>
              <Pagination meta={tags.meta} onClick={page => setPage(page)}/>
            </SectionFooter>
          </SectionBlock>

        </Container>
      </AdminMain>
      <Modal show={modal} toggleModal={() => toggleModal(!modal)}>
        <ServiceTagForm
          type='modal'
          title={tag.id ? 'Edit Tag' : 'Add Tag'}
          tag={tag}
          toggleModal={toggleModal}
          nextAction={() => refreshData()}
        />
      </Modal>
    </AdminRoute>
  )
}


export default withSession(withStore(withToast(AdminSettingsTagsIndexRoute)));
