import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withSession } from '@mile-hi-labs/react-session';
import { withToast } from 'contexts/toast-context';
import AlertTable from 'components/alert/alert-table';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/basics/modals';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { Pagination } from 'components/basics/pagination';
import { Button } from 'components/basics/buttons';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminAlertsRoute = (props) => {
  const { history, location, session, store, toast } = props;
  const [ alerts, setAlerts ] = useState([]);
  const [ page, setPage ] = useState(0);
  const [ pageSize, setPageSize ] = useState(10);
  const [ sortProp, setSortProp ] = useState('');
  const [ sortValue, setSortValue ] = useState('');
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, [page, pageSize, sortProp, sortValue]);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query('alert', {
        page: page,
        pageSize: pageSize,
        status: status,
        sortProp: sortProp,
        sortValue: sortValue,
        pageSize: 10,
      });
      setAlerts(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const sortData = (prop, value) => {
    setSortProp(prop);
    setSortValue(value);
  }


  // Render
  return (
    <AdminRoute title='DMC - Alerts'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <SectionHeader title='Alerts' className='flex-between'>
              <Button size='sm' variant='secondary' onClick={() => history.push('/alerts/new')}>
                <FarIcon icon='plus'/>
              </Button>
            </SectionHeader>
            <SectionBody>
              <AlertTable
                alerts={alerts}
                loading={loading}
                sortProp={sortProp}
                sortValue={sortValue}
                sortData={(prop, value) => sortData(prop, value)}
                refreshData={() => fetchData()}
              />
            </SectionBody>
            <SectionFooter>
              <Pagination meta={alerts.meta} onClick={page => setPage(page)}/>
            </SectionFooter>
          </SectionBlock>

        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withSession(withStore(withToast(AdminAlertsRoute)));
