import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStore } from '@mile-hi-labs/react-data';
import { withSession } from '@mile-hi-labs/react-session';
import { withToast } from 'contexts/toast-context';
import QueryString from 'query-string';
import UserApi from 'apis/user-api';

import { Form, FormGroup, FormControl, FormText } from 'components/basics/forms';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { SectionEmpty } from 'components/basics/empties';
import { ButtonBlock } from 'components/basics/buttons';
import { Container, Row, Col } from 'components/basics/grids';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, timeout, logger } from 'utils/helpers';

const UserResetPasswordForm = (props) => {
  const { history, location, session, store, toast, nextAction } = props;
  const [ email, setEmail ] = useState('');
  const [ token, setToken ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ confirmPassword, setConfirmPassword ] = useState('');
  const [ taskRunning, setTaskRunning ] = useState(false);
  const [ view, setView] = useState('action');


  // Hooks
  useEffect(() => {
    parseUrl();
    return () => setTaskRunning(false);
  }, [])


  // Methods
  const resetPassword = async () => {
    try {
      setTaskRunning(true);
      if (confirmPassword != password) return toast.showInfo('Please enter matching passwords');
      await UserApi.resetPassword({ email: email, token: token, password: password });
      toast.showSuccess('Your password has been reset!');
      setView('confirmed');
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  const parseUrl = () => {
    let params = QueryString.parse(location.search);
    setEmail(params.email);
    setToken(params.token);
  }

  const onSubmit = e => {
    e.preventDefault()
    resetPassword();
  }


  // Render
  return (
    <Form onSubmit={onSubmit}>
      <SectionBlock title='Reset Password'>

        {view == 'confirmed' ? (
          <Fragment>
            <SectionBody>
              <SectionEmpty
                icon='check-circle'
                title='Nice work!'
                msg='Your password has been reset. You can now close this window.'
              />
            </SectionBody>

            <SectionFooter>
              <ButtonBlock
                type='button'
                variant='secondary'
                onClick={() => window.close()}>
                Finish<FarIcon icon='chevron-right' className='ml-2'/>
              </ButtonBlock>
            </SectionFooter>
          </Fragment>
        ) : (
          <Fragment>
            <SectionBody>
              <FormGroup label='New Password'>
                <FormControl
                  type='password'
                  placeholder='••••••••'
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </FormGroup>

              <FormGroup label='Confirm Password'>
                <FormControl
                  type='password'
                  placeholder='••••••••'
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                />
              </FormGroup>
            </SectionBody>

            <SectionFooter>
              <ButtonBlock
                type='submit'
                variant='secondary'
                taskRunning={taskRunning}>
                Reset Password<FarIcon icon='chevron-right' className='ml-2'/>
              </ButtonBlock>
            </SectionFooter>
          </Fragment>
        )}

      </SectionBlock>
    </Form>
  )
}

export default withRouter(withStore(withSession(withToast(UserResetPasswordForm))));

