import React, { useEffect } from 'react';
import InputMask from 'react-input-mask';
import { logger } from 'utils/helpers';

const DobMaskedInput = (props) => {
  const { value = '', placeholder, readOnly, className = '', onChange } = props;

  return (
    <InputMask 
      type='text'
      mask='99/99/9999' 
      maskChar={null} 
      defaultValue={value} 
      readOnly={readOnly} 
      className='form-control' 
      placeholder={placeholder} 
      onChange={onChange} 
    />
  )
}

const PhoneMaskedInput = (props) => {
  const { value = '', placeholder, readOnly, className = '', onChange } = props;

  return (
    <InputMask 
      type='phone'
      mask='(999) 999-9999'
      maskChar={null}
      value={value} 
      readOnly={readOnly} 
      className='form-control' 
      placeholder={placeholder} 
      onChange={onChange} 
    />
  )
}

const SsnMaskedInput = (props) => {
  const { value = '', placeholder, readOnly, className = '', onChange } = props;

  return (
    <InputMask 
      mask='999-99-9999' 
      maskChar={null} 
      value={value} 
      readOnly={readOnly} 
      className='form-control' 
      placeholder={placeholder} 
      onChange={onChange} 
    />
  )  
}

export { DobMaskedInput, PhoneMaskedInput, SsnMaskedInput }

// Docs
// https://github.com/sanniassin/react-input-mask