import React, { Fragment, useEffect, useState } from 'react';
import { withSession } from '@mile-hi-labs/react-session';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';

import MessageForm from 'components/message/message-form';
import MessageList from 'components/message/message-list';
import NoteSection from 'components/note/note-section';
import { ButtonText } from 'components/basics/buttons';
import { Modal } from 'components/basics/modals';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { Pagination } from 'components/basics/pagination';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, timeout, logger } from 'utils/helpers';

const MessagesSection = (props) => {
	const { title = 'Messages', service, session, store, toast } = props;
	const [ message, setMessage ] = useState({});
	const [ messages, setMessages ] = useState([]);
  const [ page, setPage ] = useState(0);
  const [ pageSize, setPageSize ] = useState(5);
	const [ modal, toggleModal ] = useState(false);
  const [ loading, setLoading ] = useState(false);

	// Hooks
	useEffect(() => {
		service.id && fetchData();
	}, [service, page, pageSize])


	// Methods
	const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query('message', {
        page: page,
        pageSize: pageSize,
        sortProp: 'id',
        sortValue: 'desc',
      	service_id: service.id,
      	include: 'admin,user'
      });
      setMessages(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
	}

  const createMessage = () => {
    let model = store.createRecord('message', {
      service: service,
      subject: '',
      messageableId: session.user.id,
      messageableType: 'Admin'
    });
    setMessage(model);
    toggleModal(!modal);
  }

  const editMessage = (model) => {
    setMessage(model);
    toggleModal(!modal);
  }

  const refreshData = () => {
		toggleModal(!modal);
		fetchData();
  }


	// Render
	return (
    <Fragment>
      <SectionBlock>
        <SectionHeader title={title} className='flex-between'>
          <ButtonText size='xs' variant='gray' onClick={() => createMessage()}>
            <FarIcon icon='plus' size='2x'/>
          </ButtonText>
        </SectionHeader>
        <SectionBody>
          <MessageList
            type='preview'
            admin={true}
            messages={messages}
            loading={loading}
            onClick={msg => editMessage(msg)}
          />
        </SectionBody>
        <SectionFooter>
          <Pagination meta={messages.meta} onClick={page => setPage(page)}/>
        </SectionFooter>
      </SectionBlock>
      <Modal show={modal} toggleModal={() => toggleModal(!modal)}>
        <MessageForm
          title={message.id ? 'Edit Message' : 'New Message'}
          message={message}
          toggleModal={toggleModal}
          nextAction={() => refreshData()}
        />
      </Modal>
    </Fragment>
	)
}

export default withSession(withStore(withToast(MessagesSection)));
