import React, { Fragment } from 'react';
import DetailCard from 'components/cards/detail-card';
import ListCard from 'components/cards/list-card';

const ServiceDetailCard = (props) => {
	const { service = {}, loading } = props;

	return (
		<DetailCard
			icon='concierge-bell'
			status={service.status}
			title={service.title}
			subtitle={service.user && service.user.name}
			line1={service.previewDescription}
			loading={loading}
			timestamp={service.displayUpdatedAt}>
		</DetailCard>
	)
}

const ServiceListCard = (props) => {
	const { service = {}, loading } = props;

	return (
		<ListCard
			icon='concierge-bell'
			status={service.status}
			title={service.title}
			subtitle={service.user && service.user.name}
			line1={service.previewDescription}
			loading={loading}
			link={`/services/${service.id}`}
		/>
	)
}

const ServicePreviewCard = (props) => {
	const { service = {}, loading } = props;

	return (
		<ListCard
			icon='concierge-bell'
			status={service.status}
			title={service.title}
			subtitle={service.user && service.user.name}
			line1={service.previewDescription}
			loading={loading}
			link={`/services/${service.id}`}
		/>
	)
}

export { ServiceDetailCard, ServiceListCard, ServicePreviewCard }
