import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FarIcon = (props) => {
	const { icon = '', size = '1x', transform, className = '' } = props;

  return <FontAwesomeIcon icon={['far', icon]} size={size} transform={transform} className={className} />
}

const FadIcon = (props) => {
	const { icon = '', size = '1x', transform, className = '' } = props;

  return <FontAwesomeIcon icon={['fad', icon]} size={size} transform={transform} className={className} />
}

export { FarIcon, FadIcon }
