import React, { Fragment } from 'react';

import { InquiryListCard, InquirySearchCard } from 'components/inquiry/inquiry-card';
import { SectionEmpty } from 'components/basics/empties';
import { Row, Col } from 'components/basics/grids';
import { ListSkeleton, SearchSkeleton } from 'components/vendors/loading-skeleton-wrapper';

const InquiryList = (props) => {
	const { inquiries = [], type, selectedID, loading, onClick } = props;

	if (loading) {
		return (
			<Fragment>
				{(type == 'input' || type == 'search') ? (
					<SearchSkeleton count={3} />
				) : (
					<ListSkeleton count={3} />
				)}
			</Fragment>
		)
	} 
	else if (inquiries.length > 0) {
		return (
			<Row>
				{(type == 'input' || type == 'search') && inquiries.map(inquiry => (
					<Col key={inquiry.id} xs={12}>
						<InquirySearchCard
							type={type}
							inquiry={inquiry}
							selectedID={selectedID}
							onClick={onClick}
						/>
					</Col>
				))}
				{type == 'list' && inquiries.map(inquiry => (
					<Col key={inquiry.id} xs={12}>
						<InquiryListCard inquiry={inquiry} />
					</Col>
				))}
			</Row>
		)
	}
	return (
		<SectionEmpty
			icon='inbox'
			title='Inquiries'
			msg="Sorry, looks like there aren't any inquiries."
		/>
	)
}

export default InquiryList;