import React, { Fragment } from 'react';

import { ActivityListCard, ActivityPreviewCard } from 'components/activity/activity-card';
import { SectionEmpty } from 'components/basics/empties';
import { Row, Col } from 'components/basics/grids';
import { ActivitySkeleton } from 'components/vendors/loading-skeleton-wrapper';

const ActivityList = (props) => {
	const { activities = [], type, loading, onClick } = props;

	if (loading) {
		return <ActivitySkeleton count={3} />
	} 
	else if (activities.length > 0) {
		return (
			<Row>
				{type == 'list' && activities.map(activity => (
					<Col key={activity.id} xs={12}>
						<ActivityListCard
							activity={activity}
							loading={loading}
						/>
					</Col>
				))}
				{type == 'preview' && activities.map(activity => (
					<Col key={activity.id} xs={12}>
						<ActivityPreviewCard
							activity={activity}
							loading={loading}
							onClick={onClick}
						/>
					</Col>
				))}
			</Row>
		)
	}
	return (
		<SectionEmpty
			icon='bolt'
			title='Recent Activity'
			msg="Sorry, looks like you don't have any activity yet."
		/>
	)
}

export default ActivityList;