import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';
import BookingActions from 'components/booking/booking-actions';
import BookingManager from 'components/booking/booking-manager';
import NoteSection from 'components/note/note-section';
import ProviderPreview from 'components/provider/provider-preview';
import ServiceList from 'components/service/service-list';
import { BookingDetailCard } from 'components/booking/booking-card';
import { FacilityListCard } from 'components/facility/facility-card';
import { UserListCard } from 'components/user/user-card';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminBookingsDetailRoute = (props) => {
  const { bookingID, history, location, store, toast } = props;
  const [ booking, setBooking ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, []);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord('booking', bookingID, { include: 'facility.[location],note,provider,services,user' });
      setBooking(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const refreshData = () => {
    fetchData();
  }


  // Render
  return (
    <AdminRoute title={`DMC - Booking #${bookingID}`}>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <SectionHeader title={`Booking #${bookingID}`} className='flex-between no-b'>
              <div className='flex-end'>
                <BookingManager booking={booking} nextAction={refreshData}/>
                <BookingActions booking={booking} />
              </div>
            </SectionHeader>
          </SectionBlock>

          <Row>
            <Col xs={12} md={4}>
              <BookingDetailCard booking={booking} loading={loading}/>
              <NoteSection
                model={booking.note}
                notableId={booking.id}
                notableType='Booking'
                loading={loading}
              />
            </Col>
            <Col xs={12} md={8}>
              <SectionBlock>
                <SectionHeader title='Facility'>
                </SectionHeader>
                <SectionBody size='xs'>
                  <FacilityListCard facility={booking.facility} loading={loading} />
                </SectionBody>
              </SectionBlock>

              <SectionBlock>
                <SectionHeader title='Provider'>
                </SectionHeader>
                <SectionBody size='xs'>
                  <ProviderPreview provider={booking.provider} loading={loading} />
                </SectionBody>
              </SectionBlock>

              <SectionBlock>
                <SectionHeader title='User'>
                </SectionHeader>
                <SectionBody size='xs'>
                  <UserListCard user={booking.user} loading={loading} />
                </SectionBody>
              </SectionBlock>

              <SectionBlock>
                <SectionHeader title='Services'>
                </SectionHeader>
                <SectionBody>
                  <ServiceList type='preview' services={booking.services} loading={loading} />
                </SectionBody>
              </SectionBlock>
            </Col>
          </Row>
        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withStore(withToast(AdminBookingsDetailRoute));
