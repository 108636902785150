import React, { useEffect, useState } from 'react';
import { withSession } from '@mile-hi-labs/react-session';
import AdminForgotPasswordForm from 'components/auth/forgot-password-form';
import { AuthRoute } from 'components/basics/routes';
import { AuthMain } from 'components/basics/mains';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const ForgotPasswordRoute = (props) => {
  const { history, session } = props;
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    if (session.authenticated()) { history.push('/login')};
  }, []);


  // Methods
  const visitHome = () => {
    history.push('/login');
  }


  // Render
  return (
    <AuthRoute title='DMC - Forgot Password'>
      <AuthMain>
        <Container className='sm'>
          <AdminForgotPasswordForm nextAction={() => visitHome()}/>
        </Container>
      </AuthMain>
    </AuthRoute>
  )
}

export default withSession(ForgotPasswordRoute);

