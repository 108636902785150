import React, { useEffect, useState } from 'react';
import { withSession } from '@mile-hi-labs/react-session';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';

import AdminSettingFields from 'components/admin/admin-settings-fields';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { Button } from 'components/basics/buttons';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { SectionBlock, SectionBody, SectionFooter } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminSettingsNotificationsRoute = (props) => {
  const { history, location, session, store, toast } = props;
  const [ settings, setSettings ] = useState({});
  const [ loading, setLoading ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData()
  }, []);


  // Methods
  const fetchData = async () => {
   try {
      setTaskRunning(true);
      let model = await store.queryRecord('settings', null);
      setSettings(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  const saveSettings = async (settings) => {
    try {
      setTaskRunning(true);
      await settings.save();
      toast.showSuccess('Settings saved!');
    } catch (e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


  // Render
  return (
    <AdminRoute title='DMC - Notifications'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock title='Notifications'>
            <SectionBody>
              <AdminSettingFields model={settings} loading={loading} />
            </SectionBody>
            <SectionFooter>
              <Button taskRunning={taskRunning} onClick={() => saveSettings(settings)}>
                Save<FarIcon icon='check' className='ml-2'/>
              </Button>
            </SectionFooter>

          </SectionBlock>

        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withSession(withStore(withToast(AdminSettingsNotificationsRoute)));
