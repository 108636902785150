import React, { Fragment } from 'react';
import ProviderActions from 'components/provider/provider-actions';
import { AvatarImage } from 'components/basics/avatars';
import { SectionEmpty } from 'components/basics/empties';
import { Table, TableHeader, TableHeaderRow, TableHead, TableHeadSort, TableBody, TableRow, TableLink, TableData } from 'components/basics/tables';
import { TableSkeleton } from 'components/vendors/loading-skeleton-wrapper';
import { capitalize } from 'utils/transforms';

const ProviderTable = (props) => {
	const { providers = [], sortProp, sortValue, sortData, loading, refreshData } = props;

	if (loading) {
		return <TableSkeleton count={6} />
	}
	else if (providers.length > 0) {
		return (
			<Table>
				<TableHeader>
					<TableHeaderRow>
						<TableHeadSort title='Id' value='id' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHead title='Photo'/>
						<TableHeadSort title='Name' value='name' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHead title='Location'/>
						<TableHeadSort title='Email Address' value='email' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHeadSort title='Work Phone' value='work_phone' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHeadSort title='Updated' value='updated_at' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHead title='Actions'/>
					</TableHeaderRow>
				</TableHeader>
				<TableBody>
					{providers.map(provider => (
						<TableRow key={provider.id}>
							<TableLink to={provider.url} value={'#' + provider.id}/>
							<TableLink to={provider.url} value={<AvatarImage size='sm' photo={provider.photo}/>}/>
							<TableLink to={provider.url}><b>{provider.name}</b></TableLink>
							<TableLink to={provider.url} value={provider.location && provider.location.cityState}/>
							<TableLink to={provider.url} value={provider.email}/>
							<TableLink to={provider.url} value={provider.displayPhone}/>
							<TableLink to={provider.url} value={provider.displayUpdatedAt}/>
							<TableData>
								<ProviderActions provider={provider} table={true} refreshData={refreshData}/>
							</TableData>
						</TableRow>
					))}
				</TableBody>
			</Table>
		)
	}
	return (
		<SectionEmpty
			icon='user-circle'
			title='Providers'
			msg="Sorry, looks like there aren't any providers."
		/>
	)
}

export default ProviderTable;
