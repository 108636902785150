import AppModel from 'models/app';
import { isEmpty } from 'utils/helpers';
import { truncate, stringToPhone } from 'utils/transforms';

class Alert extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);

		// Attrs
		this.title = props.title;
		this.message = props.message;
		this.photo = props.photo;


		// Computed
		this.selectAll = false;


		// Relationships
		this.notifications = this.hasMany('notification', props.notifications);
		this.users = this.hasMany('user', props.users);
	}


	// Computed
	get previewMessage() {
		return this.message ? truncate(this.message, 60) : '--'
	}

	get displayMessage() {
		return this.message ? truncate(this.message, 60) : '--'
	}

	get url() {
		return `/alerts/${this.id}`
	}

}

export default Alert;
