import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withSession } from '@mile-hi-labs/react-session';
import { withToast } from 'contexts/toast-context';
import AdminApi from 'apis/admin-api';

import { ButtonBlock } from 'components/basics/buttons';
import { Form, FormGroup, FormControl, FormText } from 'components/basics/forms';
import { Container, Row, Col } from 'components/basics/grids';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, timeout, logger } from 'utils/helpers';

const RegisterForm = (props) => {
  const { session, store, toast, nextAction } = props;
  const [ admin, setAdmin ] = useState({});
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Hooks
  useEffect(() => {
    createAdmin();
    return () => setTaskRunning(false);
  }, [])


  // Tasks
  async function register() {
    try {
      setTaskRunning(true);
      let model = await AdminApi.register(admin);
      await session.authenticate('admin', model);
      toast.showSuccess('Register succeeded!');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


  // Methods
  const createAdmin = async () => {
    let model = await store.createRecord('admin');
    model.set('role', 'administrator');
    setAdmin(model);
  }


  // Render
  return (
    <Form onSubmit={e => e.preventDefault()}>
      <SectionBlock title='Register'>
        <SectionBody>

          <FormGroup label='Full Name'>
            <FormControl
              type='text'
              placeholder='Robert Redford'
              value={admin.name}
              onChange={e => admin.set('name', e.target.value)}
            />
          </FormGroup>

          <FormGroup label='Email Address'>
            <FormControl
              type='email'
              placeholder='redford@hollywood.com'
              value={admin.email}
              onChange={e => admin.set('email', e.target.value)}
            />
          </FormGroup>

          <FormGroup label='Password'>
            <FormControl
              type='password'
              placeholder='••••••••'
              value={admin.password}
              onChange={e => admin.set('password', e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <FormText>This platform is for DMC admins only.</FormText>
          </FormGroup>
        </SectionBody>

        <SectionFooter>
          <ButtonBlock
            type='submit'
            variant='secondary'
            taskRunning={taskRunning}
            onClick={() => register()}>
            Register<FarIcon icon='chevron-right' className='ml-2'/>
          </ButtonBlock>
        </SectionFooter>

      </SectionBlock>
    </Form>
  )
}

export default withStore(withSession(withToast(RegisterForm)));

