import React, { Fragment, useEffect, useState } from 'react';
import { Badge } from 'components/basics/badges';
import { SectionBlock, SectionHeader, SectionBody, SectionSubBlock, SectionSubBody, SectionItem, SectionText, SectionFooter } from 'components/basics/sections';
import { CardFooter } from 'components/basics/cards';
import { isEmpty, timeout, logger } from 'utils/helpers';

const InquiryDetailSection = (props) => {
	const { inquiry = {}, loading, className } = props;


	// Render
	return (
	  <SectionBlock>

	    <SectionHeader title='Details' />
	    <SectionBody size='xs'>
      	<SectionText title='Status' value={<Badge title={inquiry.housingType} />} />
      	<SectionText title='Move In' value={inquiry.displayMoveIn} />
      	<SectionText title='Move Out' value={inquiry.displayMoveOut} />
      	<SectionText title='Payment Method' value={inquiry.paymentMethod} />
      	<SectionText title='Budget' value={inquiry.displayBudget} />
        <SectionText title='Message' value={inquiry.message} />
	    </SectionBody>

	    <SectionHeader title='Housing' />
    	<SectionBody size='xs'>
      	<SectionText title='Housing Type' value={inquiry.housingType} />
      	{inquiry.housingType == 'apartment' ? (
      		<SectionText title='Bed Count' value={inquiry.apartmentBedCount} />
    		) : (
    			<Fragment>
    				<SectionText title='Hotel' value={inquiry.hotelType} />
    				<SectionText title='Bedroom' value={inquiry.hotelBedType} />
  				</Fragment>
    		)}
    	</SectionBody>
      <CardFooter className='detail'>
        Last updated: {inquiry.displayUpdatedAt}
      </CardFooter>
	  </SectionBlock>
	)
}

export default InquiryDetailSection;
