import React, { Fragment, useEffect, useState } from 'react';
import { withStore  } from '@mile-hi-labs/react-data';
import { withSession } from '@mile-hi-labs/react-session';
import { LoadingMessage } from 'components/basics/loading';
import { isEmpty, logger, timeout } from 'utils/helpers';

export const AdminContext = React.createContext();

const AdminProvider = (props) => {
  const { session, store, children } = props;
  const [ admin, setAdmin ] = useState(null);
  const [ data, setData ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    if (session.authenticated()) {
      enterDashboard();
    }
  }, [session.authenticated()]);


  // Tasks
  const enterDashboard = async () => {
    try {
      setLoading(true);
      await fetchData();
      await applyPreferences();
      await loadDashboard();
      setAdmin(session.user);
    } catch (e) {
      logger(e);
    } finally {
      setLoading(false);
    }
  }

  const fetchData = async () => {
    try {
      setData({ title: 'Step 1 of 3', subtitle: 'Fetching data...' });
      setAdapters(store, session.user.id);
      await timeout(400);
    } catch (e) {
      throw e;
    }
  }

  const applyPreferences = async () => {
    setData({ title: 'Step 2 of 3', subtitle: 'Setting preferences...' });
    await timeout(300);
  }

  const loadDashboard = async () => {
    setData({ title: 'Step 3 of 3', subtitle: 'Building dashboard...' });
    await timeout(400);
  }


  // Methods
  const setAdapters = (store, adminID) => {
    store.adapterFor('activity').set('adminID', adminID);
    store.adapterFor('alert').set('adminID', adminID);
    store.adapterFor('analytic').set('adminID', adminID);
    store.adapterFor('booking').set('adminID', adminID);
    store.adapterFor('contact').set('adminID', adminID);
    store.adapterFor('facility').set('adminID', adminID);
    store.adapterFor('inquiry').set('adminID', adminID);
    store.adapterFor('message').set('adminID', adminID);
    store.adapterFor('note').set('adminID', adminID);
    store.adapterFor('notification').set('adminID', adminID);
    store.adapterFor('provider').set('adminID', adminID);
    store.adapterFor('service').set('adminID', adminID);
    store.adapterFor('service-tag').set('adminID', adminID);
    store.adapterFor('settings').set('adminID', adminID);
    store.adapterFor('support').set('adminID', adminID);
    store.adapterFor('user').set('adminID', adminID);
  }

  const clearAdapters = (store) => {
    store.adapterFor('activity').set('adminID', null);
    store.adapterFor('alert').set('adminID', null);
    store.adapterFor('analytic').set('adminID', null);
    store.adapterFor('booking').set('adminID', null);
    store.adapterFor('contact').set('adminID', null);
    store.adapterFor('facility').set('adminID', null);
    store.adapterFor('inquiry').set('adminID', null);
    store.adapterFor('message').set('adminID', null);
    store.adapterFor('note').set('adminID', null);
    store.adapterFor('notification').set('adminID', null);
    store.adapterFor('provider').set('adminID', null);
    store.adapterFor('service').set('adminID', null);
    store.adapterFor('service-tag').set('adminID', null);
    store.adapterFor('settings').set('adminID', null);
    store.adapterFor('support').set('adminID', null);
    store.adapterFor('user').set('adminID', null);
  }


  // Render
  return (
    <AdminContext.Provider admin={{admin}}>
      {loading && <LoadingMessage title={data.title} subtitle={data.subtitle}/>}
      {(!loading && admin) && children}
    </AdminContext.Provider>
  )
};

export default withStore(withSession(AdminProvider));
