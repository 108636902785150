import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withSession } from '@mile-hi-labs/react-session';

import ProviderForm from 'components/provider/provider-form';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionBody } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminProvidersNewRoute = (props) => {
  const { history, location, session, store } = props;
  const [ provider, setProvider ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    createProvider();
  }, []);


  // Methods
  const createProvider = () => {
    let model = store.createRecord('provider', {});
    setProvider(model);
  }


  // Render
  return (
    <AdminRoute title='DMC - New Provider'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <ProviderForm
              title='New Provider'
              provider={provider}
              nextAction={() => history.push('/providers')}
            />
          </SectionBlock>

        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withStore(AdminProvidersNewRoute);
