import React, { Fragment } from 'react';
import FilestackPhotoWrapper from 'components/vendors/filestack-photo-wrapper';
import { Form, FormGroup, FormControl, FormText } from 'components/basics/forms';
import { Container, Row, Col } from 'components/basics/grids';

const AdminFields = (props) => {
	const { model } = props;

	return (
    <Fragment>
      <FormGroup>
        <FilestackPhotoWrapper
          type='user'
          value={model.photo}
          onChange={v => model.set('photo', v)}
        />
      </FormGroup>
      <FormGroup label='Name'>
        <FormControl
          type='text'
          placeholder='Robert Redford'
          value={model.name}
          onChange={e => model.set('name', e.target.value)}
        />
      </FormGroup>

      <FormGroup label='Email Address'>
        <FormControl
          type='email'
          placeholder='redford@hollywood.com'
          value={model.email}
          onChange={e => model.set('email', e.target.value)}
        />
      </FormGroup>

      <FormGroup label='Password'>
        <FormControl
          type='password'
          placeholder='••••••••'
          value={model.password}
          onChange={e => model.set('password', e.target.value)}
        />
      </FormGroup>

    </Fragment>
  )
}

export default AdminFields;
