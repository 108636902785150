import AppModel from 'models/app';
import Accounting from 'accounting';
import Moment from 'moment';
import { capitalize } from 'utils/transforms';

class Inquiry extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);

		// Attrs
		this.status = props.status;
		this.housingType = props.housingType;
		this.moveIn = props.moveIn;
		this.moveOut = props.moveOut;
		this.paymentMethod = props.paymentMethod;
		this.budget = props.budget;
		this.message = props.message;


		// Apts
		this.apartmentBedCount = props.apartmentBedCount;


		// Hotels
		this.hotelType = props.hotelType;
		this.hotelBedType = props.hotelBedType;


		// Relationships
		this.booking = this.belongsTo('booking', props.booking);
		this.location = this.belongsTo('location', props.location);
		this.user = this.belongsTo('user', props.user);
	}


	// Computed
	get statusOptions() {
		return ['new', 'wip', 'canceled', 'completed'];
	}

	get inquiryTypeOptions() {
		return ['apartment', 'hotel'];
	}

	get paymentMethodOptions() {
		return ['credit_card', 'billing'];
	}

	get apartmentBedCountOptions() {
		return [1, 2, 3];
	}

	get hotelTypeOptions() {
		return ['regular', 'extended'];
	}

	get hotelBedTypeOptions() {
		return ['king', 'double_queen']
	}

	get displayHousingType() {
		return capitalize(this.housingType);
	}

	get displayMoveIn() {
		return this.moveIn && Moment(this.moveIn).format('MMM Do, YYYY')
	}

	get displayMoveOut() {
		return this.moveOut && Moment(this.moveOut).format('MMM Do, YYYY')
	}

	get displayDates() {
		return `${this.displayMoveIn} - ${this.displayMoveOut}`
	}

	get displayBudget() {
		return `${Accounting.formatMoney(this.budget)}`
	}

	get displayDescription() {
		return this.message ? this.message : '--';
	}

	get url() {
		return `/inquiries/${this.id}`
	}




	// Methods
	setMoveInDate(date) {
		this.set('moveIn', date);
		this.set('moveOut', this.moveIn > this.moveOut ? this.moveIn : this.moveOut);
	}

	setMoveOutDate(date) {
		this.set('moveOut', date);
		this.set('moveIn', this.moveOut < this.moveIn ? this.moveOut : this.moveIn);
	}

}

export default Inquiry;
