import React, { Fragment } from 'react';

import { NotificationListCard, NotificationPreviewCard } from 'components/notification/notification-card';
import { SectionEmpty } from 'components/basics/empties';
import { Row, Col } from 'components/basics/grids';
import { ListSkeleton, GridSkeleton } from 'components/vendors/loading-skeleton-wrapper';

const NotificationList = (props) => {
	const { notifications = [], type = 'list', loading, onClick } = props;

	if (loading) {
		return <ListSkeleton count={3} />
	} 
	else if (notifications.length > 0) {
		return (
			<Row>
				{type == 'list' && notifications.map(notification => (
					<Col key={notification.id} xs={12}>
						<NotificationListCard
							notification={notification}
							loading={loading}
						/>
					</Col>
				))}
				{type == 'preview' && notifications.map(notification => (
					<Col key={notification.id} xs={12}>
						<NotificationPreviewCard
							notification={notification}
							loading={loading}
							onClick={onClick}
						/>
					</Col>
				))}
			</Row>
		)
	}
	return (
		<SectionEmpty
			icon='bolt'
			title='Notifications'
			msg="Sorry, looks like you don't have any notifications yet."
		/>
	)
}

export default NotificationList;