import React, { Fragment } from 'react';
import ServiceActions from 'components/service/service-actions';
import { Badge } from 'components/basics/badges';
import { SectionEmpty } from 'components/basics/empties';
import { Table, TableHeader, TableHeaderRow, TableHead, TableHeadSort, TableBody, TableRow, TableLink, TableData } from 'components/basics/tables';
import { TableSkeleton } from 'components/vendors/loading-skeleton-wrapper';
import { capitalize } from 'utils/transforms';

const ServiceTable = (props) => {
	const { services = [], sortProp, sortValue, sortData, loading, refreshData } = props;

	if (loading) {
		return <TableSkeleton count={6} />
	}
	else if (services.length > 0) {
		return (
			<Table>
				<TableHeader>
					<TableHeaderRow>
						<TableHeadSort title='Id' value='id' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHeadSort title='Status' value='status' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHead title='User'/>
						<TableHeadSort title='Title' value='title' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHead title='Description'/>
						<TableHeadSort title='Updated' value='updated_at' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHead title='Actions'/>
					</TableHeaderRow>
				</TableHeader>
				<TableBody>
					{services.map(service => (
						<TableRow key={service.id}>
							<TableLink to={service.url} value={'#' + service.id}/>
							<TableLink to={service.url} value={<Badge title={service.status}/>}/>
							<TableLink to={service.url}><b>{service.user && service.user.name}</b></TableLink>
							<TableLink to={service.url} value={service.title}/>
							<TableLink to={service.url} value={service.previewDescription}/>
							<TableLink to={service.url} value={service.displayUpdatedAt}/>
							<TableData>
								<ServiceActions service={service} table={true} refreshData={refreshData} />
							</TableData>
						</TableRow>
					))}
				</TableBody>
			</Table>
		)
	}
	return (
		<SectionEmpty
			icon='concierge-bell'
			title='Services'
			msg="Sorry, looks like there aren't any service requests."
		/>
	)
}

export default ServiceTable;
