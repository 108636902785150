import React, { Fragment, useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';
import ServiceList from 'components/service/service-list';
import { SectionEmpty } from 'components/basics/empties';
import { SectionBlock, SectionBody, SectionFooter } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { Pagination } from 'components/basics/pagination';
import { isEmpty, timeout, logger } from 'utils/helpers';

const ServicesSection = (props) => {
	const { title = 'Services', status = '', store, toast } = props;
	const [ services, setServices ] = useState({});
  const [ page, setPage ] = useState(0);
  const [ pageSize, setPageSize ] = useState(5);
	const [ loading, setLoading ] = useState(false);


	// Hooks
	useEffect(() => {
		fetchData();
	}, [page, pageSize]);


	// Methods
	const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query('service', {
        status: status,
        page: page,
        pageSize: pageSize,
        sortProp: 'id',
        sortValue: 'desc',
        include: 'user'
      });
      setServices(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
	}

	return (
    <SectionBlock title={title}>
      <SectionBody>
      	<ServiceList
      		type='list'
      		services={services}
      		loading={loading}
      	/>
      </SectionBody>
      <SectionFooter>
      	<Pagination meta={services.meta} onClick={page => setPage(page)}/>
      </SectionFooter>
    </SectionBlock>
	)
}

export default withStore(withToast(ServicesSection));
