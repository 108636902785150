import React, { Fragment, useState, useEffect } from 'react';
import { withStore, pushProp } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';
import AlgoliaService from 'services/algolia-service';
import ProviderList from 'components/provider/provider-list';
import { Pagination } from 'components/basics/pagination';
import { TagsContainer, Tag } from 'components/basics/tags';
import { Button } from 'components/basics/buttons';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/basics/modals';
import { FormControl, FormControlPrepend } from 'components/basics/forms';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, logger, timeout, randomString } from 'utils/helpers';

const ProvidersSearchInput = (props) => {
	const { model = {}, providers = [], store, toast, modal, toggleModal, nextAction } = props;
	const [ query, setQuery ] = useState('');
	const [ hits, setHits ] = useState('');
  const [ meta, setMeta ] = useState({});
	const [ loading, setLoading ] = useState(false);
	const [ taskRunning, setTaskRunning ] = useState(false);
	const algolia = new AlgoliaService('providers');


  // Hooks
	useEffect(() => {
		modal && searchData(query);
	}, [modal, query]);


  // Methods
  const fetchData = async (recordID) => {
    try {
      setTaskRunning(true);
      let p = await store.findRecord('provider', recordID);
      model.pushProp('providers', p);
      setQuery('');
    } catch (e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  const searchData = async (value = '', page) => {
    try {
    	setLoading(true);
      await timeout(300);
  		let model = await algolia.queryIndex(value, page);
      setMeta({ page: model.page, pageSize: model.hitsPerPage, totalRecords: model.nbHits });
  		setHits(model.hits);
    } catch (e) {
      toast.showError(e);
    } finally {
    	setLoading(false);
    }
  }

  const saveModel = async () => {
    try {
      setTaskRunning(true);
      await model.save();
      nextAction();
    } catch (e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


	// Render
	return (
		<Fragment>
     	<ModalHeader title='Select Providers'/>
     	<ModalBody className='bb'>
       	<FormControlPrepend icon='search' loading={loading}>
	  			<FormControl
	  				type='search'
			      value={query}
			      autoFocus={true}
			      placeholder='Search providers...'
			      className='search'
			      onChange={e => setQuery(e.target.value)}
		      />
	      </FormControlPrepend>
      </ModalBody>
      <ModalBody className='bb'>
        <TagsContainer>
          {providers.length > 0 && providers.map(p => (
            <Tag key={p.id} title={p.name} onClick={() => model.pushProp('providers', p)} />
          ))}
        </TagsContainer>
      </ModalBody>
      <ModalBody size='lg' className='search'>
    		<ProviderList
    			type='input'
    			providers={hits}
    			selectedIDs={providers.map(provider => provider.id)}
    			loading={loading}
    			onClick={d => fetchData(d.id)}
  			/>
      </ModalBody>
      <ModalFooter className='flex-start'>
        <Pagination meta={meta} onClick={page => searchData(query, page)}/>
      </ModalFooter>
      <ModalFooter className='flex-start'>
        <Button
          variant='secondary'
          taskRunning={taskRunning}
          onClick={() => saveModel()}>
          Save<FarIcon icon='check' className='ml-2'/>
        </Button>
      </ModalFooter>
    </Fragment>
	)
}

export default withStore(withToast(ProvidersSearchInput));
