import React, { Fragment } from 'react';
import AlertActions from 'components/alert/alert-actions';
import { Badge } from 'components/basics/badges';
import { SectionEmpty } from 'components/basics/empties';
import { Table, TableHeader, TableHeaderRow, TableHead, TableHeadSort, TableBody, TableRow, TableLink, TableData } from 'components/basics/tables';
import { TableSkeleton } from 'components/vendors/loading-skeleton-wrapper';
import { capitalize } from 'utils/transforms';

const AlertTable = (props) => {
	const { alerts = [], sortProp, sortValue, sortData, loading, refreshData } = props;

	if (loading) {
		return <TableSkeleton count={6} />
	}
	else if (alerts.length > 0) {
		return (
			<Table>
				<TableHeader>
					<TableHeaderRow>
						<TableHeadSort title='Id' value='status' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHeadSort title='Title' value='title' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHeadSort title='Message' value='message' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHeadSort title='Created At' value='created_at' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHead title='Actions'/>
					</TableHeaderRow>
				</TableHeader>
				<TableBody>
					{alerts.map(alert => (
						<TableRow key={alert.id}>
							<TableLink to={alert.url} value={'#' + alert.id}/>
							<TableLink to={alert.url}><b>{alert.title}</b></TableLink>
							<TableLink to={alert.url} value={capitalize(alert.previewMessage)}/>
							<TableLink to={alert.url} value={alert.displayCreatedAt}/>
							<TableData>
								<AlertActions alert={alert} table={true} refreshData={refreshData} />
							</TableData>
						</TableRow>
					))}
				</TableBody>
			</Table>
		)
	}
	return (
		<SectionEmpty
			icon='broadcast-tower'
			title='Alerts'
			msg="Sorry, looks like you haven't added any alerts yet."
		/>
	)
}

export default AlertTable;
