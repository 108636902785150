import React, { Fragment, useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';

import SelectCard from 'components/cards/select-card';
import { Form } from 'components/basics/forms';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/basics/modals';
import { Button } from 'components/basics/buttons';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, logger } from 'utils/helpers';
import { capitalize } from 'utils/transforms';


const ServiceManager = (props) => {
  const { service = {}, store, toast, nextAction } = props;
  const [ modal, toggleModal ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Methods
  const saveService = async (service) => {
    try {
      setTaskRunning(true);
      await service.save();
      toast.showSuccess('Service updated!');
      toggleModal(!modal);
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  const statusOptions = () => {
    let options = service.statusOptions || [];
    return options.map(option => {
      switch(option) {
        case 'draft':
        return { title: option, description: "These service requests are admin-only and aren't shared with users." }

        case 'requested':
        return { title: option, description: 'These service requests were created by a user and require attention.' }

        case 'wip':
        return { title: option, description: 'These service requests are currently active in the system.' }

        case 'scheduled':
        return { title: option, description: 'These service requests have maintenance scheduled.' }

        case 'follow-up':
        return { title: option, description: 'These service requests require follow-up by the admin.' }

        case 'canceled':
        return { title: option, description: 'These service requests were canceled by either party.' }

        case 'completed':
        return { title: option, description: 'These service requests are now finished.' }
      }
    })
  }


  // Render
  return (
    <Fragment>
      <Modal show={modal} toggleModal={() => toggleModal(!modal)}>
        <Form onSubmit={e => e.preventDefault()}>
          <ModalHeader title='Booking Manager'/>
          <ModalBody size='md' className='no-p'>
            {statusOptions().map(option => (
              <SelectCard
                key={option.title}
                title={capitalize(option.title)}
                description={capitalize(option.description)}
                selected={service.status == option.title}
                onSelect={() => service.set('status', option.title)}
              />
            ))}
          </ModalBody>
          <ModalFooter>
            <Button
              type='submit'
              variant='secondary'
              taskRunning={taskRunning}
              onClick={() => saveService(service)}>
              Save<FarIcon icon='check' className='ml-2'/>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Button
        size='sm'
        variant='gray'
        taskRunning={taskRunning}
        className='mr-15'
        onClick={() => toggleModal(!modal)}>
        Manage
      </Button>
    </Fragment>
  )
}

export default withStore(withToast(ServiceManager));

