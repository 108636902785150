import AppModel from 'models/app';
import { stringToPhone } from 'utils/transforms';

class Activity extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);

		// Attrs
		this.title = props.title;
		this.description = props.description;
		this.activitableId = props.activitableId;
		this.activitableType = props.activitableType;
		this.event = props.event = '';
	}

	get icon() {
		switch (this.activitableType) {
			case 'Admin':
			return 'crown';

			case 'Booking':
			return 'calendar';

			case 'Facility':
			return 'business';

			case 'Provider':
			return 'business';

			case 'Service':
			return 'concierge-bell';

			case 'User':
			return 'user';

			default:
			return 'crown'
		}
	}

	get displayEvent() {
		return this.event.split('.')[0];
	}
}

export default Activity;
