import React, { Fragment } from 'react';

import { BookingListCard, BookingSearchCard } from 'components/booking/booking-card';
import { SectionEmpty } from 'components/basics/empties';
import { Row, Col } from 'components/basics/grids';
import { ListSkeleton, SearchSkeleton } from 'components/vendors/loading-skeleton-wrapper';

const BookingList = (props) => {
	const { bookings = [], type, selectedID, loading, onClick } = props;

	if (loading) {
		return (
			<Fragment>
				{(type == 'input' || type == 'search') ? (
					<SearchSkeleton count={3} />
				) : (
					<ListSkeleton count={3} />
				)}
			</Fragment>
		)
	} 
	else if (bookings.length > 0) {
		return (
			<Row>
				{(type == 'input' || type == 'search') && bookings.map(booking => (
					<Col key={booking.id} xs={12}>
						<BookingSearchCard
							type={type}
							booking={booking}
							selectedID={selectedID}
							onClick={onClick}
						/>
					</Col>
				))}
				{type == 'list' && bookings.map(booking => (
					<Col key={booking.id} xs={12}>
						<BookingListCard booking={booking} />
					</Col>
				))}
			</Row>
		)
	}
	return (
		<SectionEmpty
			icon='calendar'
			title='Bookings'
			msg="Sorry, looks like there aren't any bookings."
		/>
	)
}

export default BookingList;