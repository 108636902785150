import React, { useEffect, useState } from 'react';
import { LoadScriptNext, Autocomplete } from '@react-google-maps/api';

import { FormControlPrepend, FormControl } from 'components/basics/forms';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { logger, randomString } from 'utils/helpers';

const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY;

const updateLocation = (autocomplete) => {
  let data = {};
  let google = autocomplete.getPlace();
  let address = google.address_components;

  let streetNumber = address.find(c => c.types[0] == 'street_number');
  let route = address.find(c => c.types[0] == 'route');

  data.street = streetNumber && route ? streetNumber.long_name + ' ' + route.short_name : null;
  data.street2 = address.find(component => component.types[0] == 'subpremise');
  data.city = address.find(component => component.types[0] == 'locality');
  data.state = address.find(component => component.types[0] == 'administrative_area_level_1');
  data.country = address.find(component => component.types[0] == 'country');
  data.postalCode = address.find(component => component.types[0] == 'postal_code');
  data.latitude = google.geometry.location.lat();
  data.longitude = google.geometry.location.lng();

  data.street2 = data.street2 ? data.street2.long_name : '';
  data.city = data.city ? data.city.long_name : '';
  data.state = data.state ? data.state.long_name : '';
  data.country = data.country ? data.country.long_name : '';
  data.postalCode = data.postalCode ? data.postalCode.long_name : '';
  return data;
}

const StreetAddressAutocomplete = (props) => {
  const { location = {}, placeholder, onChange, className } = props;
  const [ autocomplete, setAutocomplete ] = useState(null)

  // Render
	return (
		<div className={'autocomplete-wrapper ' + className}>
			<LoadScriptNext googleMapsApiKey={GOOGLE_KEY} libraries={['places']}>
				<Autocomplete
          onLoad={value => setAutocomplete(value)}
          onPlaceChanged={() => onChange(updateLocation(autocomplete))}
          options={{types: ['geocode', 'establishment']}}
          fields={['name', 'formatted_address', 'geometry', 'address_component']}>
          <FormControl
            name={randomString()}
            placeholder={placeholder}
            value={location.street}
          />
        </Autocomplete>
			</LoadScriptNext>
		</div>
	)
}

const CityStateAutocomplete = (props) => {
  const { location = {}, placeholder, onChange, className } = props;
  const [ autocomplete, setAutocomplete ] = useState(null)

  // Render
  return (
    <div className={'autocomplete-wrapper ' + className}>
      <LoadScriptNext googleMapsApiKey={GOOGLE_KEY} libraries={['places']}>
        <Autocomplete
          onLoad={value => setAutocomplete(value)}
          onPlaceChanged={() => onChange(updateLocation(autocomplete))}
          options={{types: ['(regions)']}}
          fields={['name', 'formatted_address', 'geometry', 'address_component']}>
          <FormControlPrepend icon='map-marker'>
            <FormControl
              name={randomString()}
              placeholder={placeholder}
              value={location.cityState}
            />
          </FormControlPrepend>
        </Autocomplete>
      </LoadScriptNext>
    </div>
  )
}

export { StreetAddressAutocomplete, CityStateAutocomplete }

// Docs:
// https://github.com/JustFly1984/react-google-maps-api
// https://developers.google.com/maps/documentation/javascript/places-autocomplete
