import React, { Fragment, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Avatar } from 'components/basics/avatars';
import { NavBtn, NavLink } from 'components/basics/navs';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, DropdownLink } from 'components/basics/dropdowns';

const AdminDropdown = (props) => {
	const { session, history } = props;
	const [ dropdown, toggleDropdown ] = useState(false);


	// Methods
	const logout = async () => {
		await session.logout();
		history.push('/');
	}

	// Render
	return (
		<Fragment>
			{session.authenticated() ? (
				<Dropdown open={dropdown} toggle={() => toggleDropdown(!dropdown)}>
					<DropdownToggle size='sm' variant='light-gray' className='flex-start' onClick={() => toggleDropdown(!dropdown)}>
						<Avatar photo={session.user.photo} title={session.user.firstName} />
					</DropdownToggle>
					<DropdownMenu open={dropdown}>
						<DropdownLink to='/settings/profile'>View Profile</DropdownLink>
						<DropdownLink to='/'>Exit Dashboard</DropdownLink>
						<DropdownItem onClick={() => logout()}>Logout</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			) : (
				<Fragment>
					<NavLink to='/login'>Login</NavLink>
					<NavLink to='/register'>Register</NavLink>
				</Fragment>
			)}
		</Fragment>
	)
}

export default withRouter(AdminDropdown);
