import React, { useEffect, useState } from 'react';
import { withSession } from '@mile-hi-labs/react-session';
import { withStore } from '@mile-hi-labs/react-data';
import Moment from 'moment';

import BookingForm from 'components/booking/booking-form';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionBody } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminBookingsNewRoute = (props) => {
  const { history, location, session, store } = props;
  const [ booking, setBooking ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    createBooking();
  }, []);


  // Methods
  const createBooking = () => {
    let model = store.createRecord('booking', {
      status: 'draft',
      housing: 'apartment',
      moveIn: Moment().startOf('d').add(1, 'd'),
      moveOut: Moment().startOf('d').add(2, 'd'),
    });
    setBooking(model);
  }


  // Render
  return (
    <AdminRoute title='DMC - New Booking'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <BookingForm
              title='New Booking'
              booking={booking}
              nextAction={() => history.push('/bookings')}
            />
          </SectionBlock>

        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withSession(withStore(AdminBookingsNewRoute));

