import React, { useEffect, useState } from 'react';
import { withSession } from '@mile-hi-labs/react-session';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';

import ActivitiesSection from 'components/activity/activities-section';
import ServiceActions from 'components/service/service-actions';
import ServiceManager from 'components/service/service-manager';
import MessagesSection from 'components/message/messages-section';
import NoteSection from 'components/note/note-section';
import { ServiceDetailCard } from 'components/service/service-card';
import { BookingListCard } from 'components/booking/booking-card';
import { UserListCard } from 'components/user/user-card';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { ButtonText } from 'components/basics/buttons';
import { SectionBlock, SectionHeader, SectionBody, SectionItem, SectionText, SectionFooter } from 'components/basics/sections';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminServicesDetailRoute = (props) => {
  const { serviceID, history, location, session, store, toast } = props;
  const [ service, setService ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, []);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord('service', serviceID, { include: 'booking.[user],user,note' });
      setService(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const refreshData = () => {
    fetchData();
  }


  // Render
  return (
    <AdminRoute title={`DMC - Service #${serviceID}`}>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <SectionHeader title={`Service #${serviceID}`} className='flex-between no-b'>
              <div className='flex-end'>
                <ServiceManager service={service} nextAction={refreshData}/>
                <ServiceActions service={service} />
              </div>
            </SectionHeader>
          </SectionBlock>

          <Row>
            <Col xs={12} md={4}>
              <ServiceDetailCard service={service} loading={loading}/>
              <NoteSection
                model={service.note}
                notableId={service.id}
                notableType='Service'
                loading={loading}
              />
            </Col>
            <Col xs={12} md={8}>
              <SectionBlock>
                <SectionHeader title='Details'>
                </SectionHeader>
                <SectionBody size='xs'>
                  <SectionItem title='Permission to enter?' value={service.displayPermission} />
                  <SectionText title='Description' value={service.displayDescription} />
                </SectionBody>
              </SectionBlock>

              <SectionBlock>
                <SectionHeader title='Booking'>
                </SectionHeader>
                <SectionBody size='xs'>
                  <BookingListCard
                    booking={service.booking}
                    loading={loading}
                  />
                </SectionBody>
              </SectionBlock>

              <MessagesSection service={service} />

              {service.id && (
                <ActivitiesSection type='preview' modelId={service.id} modelName='Service' />
              )}

            </Col>
          </Row>
        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withStore(withToast(AdminServicesDetailRoute));
