import AppModel from 'models/app';

class Admin extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);

		// Attrs
		this.photo = props.photo;
		this.name = props.name;
		this.email = props.email;
		this.password = props.password;
		this.role = props.role;


		// Relationships
		this.activities = this.hasMany('activity', props.activities);
		this.settings = this.belongsTo('settings', props.settings);
	}

	get firstName() {
		return this.name && this.name.split(' ')[0];
	}

	get lastName() {
		return this.name && this.name.split(' ')[1];
	}

	get roleOptions() {
		return ['administrator']
	}
}

export default Admin;
