import AppModel from 'models/app';
import { stringToPhone } from 'utils/transforms';

class Contact extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);

		// Attrs
		this.photo = props.photo;
		this.name = props.name;
		this.email = props.email;
		this.jobTitle = props.jobTitle;
		this.cellPhone = props.cellPhone;
		this.workPhone = props.workPhone;
		this.workPhoneExt = props.workPhoneExt;
		this.description = props.description;


		// Relationships
		this.provider = this.belongsTo('provider', props.provider);
	}

	// Computed
	get firstName() {
		return this.name && this.name.split(' ')[0];
	}

	get lastName() {
		return this.name && this.name.split(' ')[1];
	}

	get displayJobTitle() {
		return this.jobTitle && this.jobTitle;
	}

	get displayCellPhone() {
		return this.cellPhone && 'c. ' + stringToPhone(this.cellPhone);
	}

	get displayWorkPhone() {
		return this.workPhone && 'w. ' + stringToPhone(this.workPhone) + this.displayWorkPhoneExt;
	}

	get displayWorkPhoneExt() {
		return this.workPhoneExt ? ' x' + this.workPhoneExt : '';
	}

	get displayDescription() {
		return this.description ? this.description : '--';
	}

	get url() {
		return `/contacts/${this.id}`
	}
}

export default Contact;
