import React, { Fragment } from 'react';
import { ProviderListCard, ProviderSearchCard } from 'components/provider/provider-card';
import { PreviewEmpty } from 'components/basics/empties';
import { isEmpty } from 'utils/helpers';

const ProviderPreview = (props) => {
	const { provider = {}, loading, onClick } = props;

	if (!isEmpty(provider)) {
		return (
			<ProviderListCard provider={provider} loading={loading} />
		)
	}
	return (
		<PreviewEmpty
			icon='user-circle'
			title='Providers'
			msg="Sorry, looks like we haven't assigned a provider yet."
		/>
	)
}

export default ProviderPreview;
