import React, { Fragment, useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';

import ContactList from 'components/contact/contact-list';
import FacilityList from 'components/facility/facility-list';
import ProviderList from 'components/provider/provider-list';
import TeammateList from 'components/teammate/teammate-list';
import UserList from 'components/user/user-list';
import { SectionEmpty } from 'components/basics/empties';
import { SearchBlock, SearchHeader, SearchBody } from 'components/basics/search';
import { SearchSkeleton } from 'components/vendors/loading-skeleton-wrapper';
import { isEmpty, logger } from 'utils/helpers';

const SearchResults = (props) => {
	const { results = [], loading, store, toast } = props;
	const [ admins, setAdmins ] = useState([]);
	const [ bookings, setBookings ] = useState([]);
	const [ contacts, setContacts ] = useState([]);
	const [ facilities, setFacilities ] = useState([]);
	const [ providers, setProviders ] = useState([]);
	const [ services, setServices ] = useState([]);
	const [ users, setUsers ] = useState([]);
	
	
	// Hooks
	useEffect(() => {
		results.length > 0 && updateResults(results);
	}, [results]);

	
	// Methods
	const updateResults = (results) => {
		results.map(result => {
			let index = result.index.split('_')[0];
			if (index == 'admins') {
				setAdmins(result.hits);
				logger('admins: ', result.hits);
				return;
			}
			if (index == 'bookings') {
				setBookings(result.hits);
				logger('bookings: ', result.hits);
				return;
			}
			if (index == 'contacts') {
				setContacts(result.hits);
				logger('contacts: ', result.hits);
				return;
			}
			if (index == 'facilities') {
				setFacilities(result.hits);
				logger('facilities: ', result.hits);
				return;
			}
			if (index == 'providers') {
				setProviders(result.hits);
				logger('providers: ', result.hits);
				return;
			}
			if (index == 'services') {
				setServices(result.hits);
				logger('services: ', result.hits);
				return;
			}
			if (index == 'users') {
				setUsers(result.hits);
				logger('users: ', result.hits);
				return;
			}
		})
	}

	
	// Render
	return (
		<Fragment>
			{loading && <SearchSkeleton count={3} />}
			{!isEmpty(admins) && (
				<SearchBlock>
					<SearchHeader title='Admins'/>
					<SearchBody>
			  		<TeammateList
			  			type='search'
			  			teammates={admins}
			  			loading={loading}
						/>
					</SearchBody>
				</SearchBlock>
			)}
			{!isEmpty(contacts) && (
				<SearchBlock>
					<SearchHeader title='Contacts'/>
					<SearchBody>
			  		<ContactList
			  			type='search'
			  			contacts={contacts}
			  			loading={loading}
						/>
					</SearchBody>
				</SearchBlock>
			)}
			{!isEmpty(facilities) && (
				<SearchBlock>
					<SearchHeader title='Facilities'/>
					<SearchBody>
			  		<FacilityList
			  			type='search'
			  			facilities={facilities}
			  			loading={loading}
						/>
					</SearchBody>
				</SearchBlock>
			)}
			{!isEmpty(providers) && (
				<SearchBlock>
					<SearchHeader title='Providers'/>
					<SearchBody>
			  		<ProviderList
			  			type='search'
			  			providers={providers}
			  			loading={loading}
						/>
					</SearchBody>
				</SearchBlock>
			)}
			{!isEmpty(users) && (
				<SearchBlock>
					<SearchHeader title='Users'/>
					<SearchBody>
			  		<UserList
			  			type='search'
			  			users={users}
			  			loading={loading}
						/>
					</SearchBody>
				</SearchBlock>
			)}
			{(isEmpty(admins) && isEmpty(contacts) && isEmpty(facilities) && isEmpty(providers) && isEmpty(users)) && (
				<SectionEmpty
					icon='search'
					title='Search Results'
					msg="Sorry, looks like we don't have results that match."
				/>
			)}
		</Fragment>
	)
}

export default withStore(withToast(SearchResults));