import React from 'react';
import { Switch, Route, Redirect, useHistory, useParams, useRouteMatch } from 'react-router-dom';

// Contexts
import AdminContext from 'contexts/admin-context';
import { withSession } from '@mile-hi-labs/react-session';

// Mkt
import IndexRoute from 'routes/mkt/index';
import NotFoundRoute from 'routes/mkt/not-found';
import NotAuthorizedRoute from 'routes/mkt/not-authorized';

// Auth
import LoginRoute from 'routes/auth/login';
import RegisterRoute from 'routes/auth/register';
import ForgotPasswordRoute from 'routes/auth/forgot-password';
import ResetPasswordRoute from 'routes/auth/reset-password';

// Admin
import AdminDashboardRoute from 'routes/admin/dashboard';
import AdminActivityRoute from 'routes/admin/activity';
import AdminAnalyticsRoute from 'routes/admin/analytics';

import AdminAlertsIndexRoute from 'routes/admin/alerts/index';
import AdminAlertsNewRoute from 'routes/admin/alerts/new';
import AdminAlertsDetailRoute from 'routes/admin/alerts/detail';
import AdminAlertsEditRoute from 'routes/admin/alerts/edit';

import AdminBookingsIndexRoute from 'routes/admin/bookings/index';
import AdminBookingsNewRoute from 'routes/admin/bookings/new';
import AdminBookingsDetailRoute from 'routes/admin/bookings/detail';
import AdminBookingsEditRoute from 'routes/admin/bookings/edit';

import AdminContactsIndexRoute from 'routes/admin/contacts/index';
import AdminContactsNewRoute from 'routes/admin/contacts/new';
import AdminContactsDetailRoute from 'routes/admin/contacts/detail';
import AdminContactsEditRoute from 'routes/admin/contacts/edit';

import AdminFacilitiesIndexRoute from 'routes/admin/facilities/index';
import AdminFacilitiesNewRoute from 'routes/admin/facilities/new';
import AdminFacilitiesDetailRoute from 'routes/admin/facilities/detail';
import AdminFacilitiesEditRoute from 'routes/admin/facilities/edit';

import AdminInquiriesIndexRoute from 'routes/admin/inquiries/index';
import AdminInquiriesNewRoute from 'routes/admin/inquiries/new';
import AdminInquiriesDetailRoute from 'routes/admin/inquiries/detail';
import AdminInquiriesEditRoute from 'routes/admin/inquiries/edit';

import AdminProvidersIndexRoute from 'routes/admin/providers/index';
import AdminProvidersNewRoute from 'routes/admin/providers/new';
import AdminProvidersDetailRoute from 'routes/admin/providers/detail';
import AdminProvidersEditRoute from 'routes/admin/providers/edit';

import AdminNotificationsRoute from 'routes/admin/notifications';

import AdminServicesIndexRoute from 'routes/admin/services/index';
import AdminServicesNewRoute from 'routes/admin/services/new';
import AdminServicesDetailRoute from 'routes/admin/services/detail';
import AdminServicesEditRoute from 'routes/admin/services/edit';

import AdminUsersIndexRoute from 'routes/admin/users/index';
import AdminUsersNewRoute from 'routes/admin/users/new';
import AdminUsersDetailRoute from 'routes/admin/users/detail';
import AdminUsersEditRoute from 'routes/admin/users/edit';

import AdminSettingsIndexRoute from 'routes/admin/settings/index';
import AdminSettingsProfileDetailRoute from 'routes/admin/settings/profile/detail';
import AdminSettingsProfileEditRoute from 'routes/admin/settings/profile/edit';
import AdminSettingsNotificationsRoute from 'routes/admin/settings/notifications';
import AdminSettingsTagsIndexRoute from 'routes/admin/settings/tags/index';
import AdminSettingsTeammatesIndexRoute from 'routes/admin/settings/teammates/index';
import AdminSettingsTeammatesNewRoute from 'routes/admin/settings/teammates/new';
import AdminSettingsTeammatesDetailRoute from 'routes/admin/settings/teammates/detail';
import AdminSettingsTeammatesEditRoute from 'routes/admin/settings/teammates/edit';
import AdminSettingsCustomizationsRoute from 'routes/admin/settings/customizations';
import AdminSettingsDocumentationRoute from 'routes/admin/settings/documentation';

import AdminSupportIndexRoute from 'routes/admin/support/index';
import AdminSupportDetailRoute from 'routes/admin/support/detail';

// Users
import UserResetPasswordRoute from 'routes/user/reset-password';

// Utils
import ErrorBoundary from 'utils/error-boundary';

const Router = (props) => {
  const { session } = props;

  // Render
  return (
  	<ErrorBoundary>
	    <Switch>
	      <Route exact path='/' component={IndexRoute}/>
        <Route exact path='/login' component={LoginRoute}/>
        <Route exact path='/register' component={RegisterRoute}/>
        <Route exact path='/forgot-password' component={ForgotPasswordRoute}/>
        <Route exact path='/reset-password' component={ResetPasswordRoute}/>
        <Route exact path='/not-found' component={NotFoundRoute}/>
        <Route exact path='/not-authorized' component={NotAuthorizedRoute}/>
        <Route exact path='/user/reset-password' component={UserResetPasswordRoute}/>
        {session.authenticated() && (
          <Route>
            <AdminRoutes/>
          </Route>
        )}
        {session.loaded && (
          <Route path='/*'>
            <Redirect to='/login'/>
          </Route>
        )}
	    </Switch>
    </ErrorBoundary>
  );
}

const AdminRoutes = (props) => {
  return (
    <AdminContext>
      <Switch>
        <Route exact path='/dashboard' component={AdminDashboardRoute} />
        <Route exact path='/activity' component={AdminActivityRoute} />
        <Route exact path='/analytics' component={AdminAnalyticsRoute} />

        <Route exact path='/alerts' component={AdminAlertsIndexRoute} />
        <Route exact path='/alerts/new' component={AdminAlertsNewRoute} />

        <Route path='/alerts/:alertID'>
          <AdminAlertsDetail />
        </Route>

        <Route exact path='/bookings' component={AdminBookingsIndexRoute} />
        <Route exact path='/bookings/new' component={AdminBookingsNewRoute} />

        <Route path='/bookings/:bookingID'>
          <AdminBookingsDetail />
        </Route>

        <Route exact path='/contacts' component={AdminContactsIndexRoute} />
        <Route exact path='/contacts/new' component={AdminContactsNewRoute} />

        <Route path='/contacts/:contactID'>
          <AdminContactsDetail />
        </Route>

        <Route exact path='/facilities' component={AdminFacilitiesIndexRoute} />
        <Route exact path='/facilities/new' component={AdminFacilitiesNewRoute} />

        <Route path='/facilities/:facilityID'>
          <AdminFacilitiesDetail />
        </Route>

        <Route exact path='/inquiries' component={AdminInquiriesIndexRoute} />
        <Route exact path='/inquiries/new' component={AdminInquiriesNewRoute} />

        <Route path='/inquiries/:inquiryID'>
          <AdminInquiriesDetail />
        </Route>

        <Route exact path='/providers' component={AdminProvidersIndexRoute} />
        <Route exact path='/providers/new' component={AdminProvidersNewRoute} />

        <Route path='/providers/:providerID'>
          <AdminProvidersDetail />
        </Route>

        <Route exact path='/notifications' component={AdminNotificationsRoute} />
        <Route exact path='/services' component={AdminServicesIndexRoute} />
        <Route exact path='/services/new' component={AdminServicesNewRoute} />

        <Route path='/services/:serviceID'>
          <AdminServicesDetail/>
        </Route>

        <Route exact path='/support' component={AdminSupportIndexRoute} />

        <Route path='/support/:supportID'>
          <AdminSupportDetail/>
        </Route>

        <Route exact path='/users' component={AdminUsersIndexRoute} />
        <Route exact path='/users/new' component={AdminUsersNewRoute} />

        <Route path='/users/:userID'>
          <AdminUsersDetail/>
        </Route>

        <Route path='/settings'>
          <AdminSettings/>
        </Route>

        <Route path='/*'>
          <Redirect to='/'/>
        </Route>

      </Switch>
    </AdminContext>
  )
}

const AdminAlertsDetail = (props) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { alertID } = useParams();

  return (
    <Switch>
      <Route exact path={path}>
        <AdminAlertsDetailRoute alertID={alertID} history={history}/>
      </Route>

      <Route exact path={path + '/edit'}>
        <AdminAlertsEditRoute alertID={alertID} history={history}/>
      </Route>
    </Switch>
  )
}

const AdminBookingsDetail = (props) => {
  const history = useHistory();
	const { path } = useRouteMatch();
  const { bookingID } = useParams();

	return (
		<Switch>
      <Route exact path={path}>
        <AdminBookingsDetailRoute bookingID={bookingID} history={history}/>
      </Route>

      <Route exact path={path + '/edit'}>
        <AdminBookingsEditRoute bookingID={bookingID} history={history}/>
      </Route>
    </Switch>
	)
}

const AdminContactsDetail = (props) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { contactID } = useParams();

  return (
    <Switch>
      <Route exact path={path}>
        <AdminContactsDetailRoute contactID={contactID} history={history}/>
      </Route>

      <Route exact path={path + '/edit'}>
        <AdminContactsEditRoute contactID={contactID} history={history}/>
      </Route>
    </Switch>
  )
}

const AdminFacilitiesDetail = (props) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { facilityID } = useParams();

  return (
    <Switch>
      <Route exact path={path}>
        <AdminFacilitiesDetailRoute facilityID={facilityID} history={history}/>
      </Route>

      <Route exact path={path + '/edit'}>
        <AdminFacilitiesEditRoute facilityID={facilityID} history={history}/>
      </Route>

    </Switch>
  )
}

const AdminInquiriesDetail = (props) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { inquiryID } = useParams();

  return (
    <Switch>
      <Route exact path={path}>
        <AdminInquiriesDetailRoute inquiryID={inquiryID} history={history}/>
      </Route>

      <Route exact path={path + '/edit'}>
        <AdminInquiriesEditRoute inquiryID={inquiryID} history={history}/>
      </Route>
    </Switch>
  )
}

const AdminProvidersDetail = (props) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { providerID } = useParams();

  return (
    <Switch>
      <Route exact path={path}>
        <AdminProvidersDetailRoute providerID={providerID} history={history}/>
      </Route>

      <Route exact path={path + '/edit'}>
        <AdminProvidersEditRoute providerID={providerID} history={history}/>
      </Route>
    </Switch>
  )
}

const AdminServicesDetail = (props) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { serviceID } = useParams();

  return (
    <Switch>
      <Route exact path={path}>
        <AdminServicesDetailRoute serviceID={serviceID} history={history}/>
      </Route>

      <Route exact path={path + '/edit'}>
        <AdminServicesEditRoute serviceID={serviceID} history={history} />
      </Route>
    </Switch>
  )
}

const AdminUsersDetail = (props) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { userID } = useParams();

  return (
    <Switch>
      <Route exact path={path}>
        <AdminUsersDetailRoute userID={userID} history={history} />
      </Route>

      <Route exact path={path + '/edit'}>
        <AdminUsersEditRoute userID={userID} history={history} />
      </Route>
    </Switch>
  )
}

const AdminSupportDetail = (props) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { supportID } = useParams();

  return (
    <Switch>
      <Route exact path={path}>
        <AdminSupportDetailRoute supportID={supportID} history={history} />
      </Route>
    </Switch>
  )
}

const AdminSettings = (props) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={AdminSettingsIndexRoute} />
      <Route exact path={path + '/profile'} component={AdminSettingsProfileDetailRoute} />
      <Route exact path={path + '/profile/edit'} component={AdminSettingsProfileEditRoute} />
      <Route exact path={path + '/notifications'} component={AdminSettingsNotificationsRoute} />
      <Route exact path={path + '/tags'} component={AdminSettingsTagsIndexRoute} />

      <Route exact path={path + '/teammates'} component={AdminSettingsTeammatesIndexRoute} />
      <Route exact path={path + '/teammates/new'} component={AdminSettingsTeammatesNewRoute} />

      <Route path={path + '/teammates/:teammateID'}>
        <AdminSettingsTeammatesDetail/>
      </Route>

      <Route exact path={path + '/customizations'} component={AdminSettingsCustomizationsRoute} />
      <Route exact path={path + '/documentation'} component={AdminSettingsDocumentationRoute} />

      <Route path='/*'>
        <Redirect to='/'/>
      </Route>
    </Switch>
  )
}

const AdminSettingsTeammatesDetail = (props) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { teammateID } = useParams();

  return (
    <Switch>
      <Route exact path={path}>
        <AdminSettingsTeammatesDetailRoute teammateID={teammateID} history={history} />
      </Route>

      <Route exact path={path + '/edit'}>
        <AdminSettingsTeammatesEditRoute teammateID={teammateID} history={history} />
      </Route>
    </Switch>
  )
}

export default withSession(Router);
