import React, { Fragment, useState } from 'react';
import { withRouter, NavLink, Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { ButtonWrapper } from 'components/basics/buttons';
import { TooltipWrapper } from 'components/basics/tooltips';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { DmcIcon, DmcLogo } from 'utils/assets/branding';

const AdminSidenav = (props) => {
	const { open, toggleOpen, className } = props;

	return (
		<Nav className={open ? 'admin-sidenav' : 'admin-sidenav collapsed'}>
			<div className='admin-sidenav-header'>
				<Navbar.Brand as={Link} to='/dashboard'>{open ? <img src={DmcLogo}/> : <img src={DmcIcon}/>}</Navbar.Brand>
			</div>
			<div className='admin-sidenav-main'>
				<NavLink to='/dashboard' className='nav-link'>
					<FarIcon icon='tachometer-alt-fast'/>
					<span>Dashboard</span>
				</NavLink>

				<TooltipWrapper text='Alerts'>
					<NavLink to='/alerts' className='nav-link'>
						<FarIcon icon='broadcast-tower'/><span>Alerts</span>
					</NavLink>
				</TooltipWrapper>

				<TooltipWrapper text='Inquiries'>
					<NavLink to='/inquiries' className='nav-link'>
						<FarIcon icon='inbox'/><span>Inquiries</span>
					</NavLink>
				</TooltipWrapper>

				<TooltipWrapper text='Bookings'>
					<NavLink to='/bookings' className='nav-link'>
						<FarIcon icon='calendar'/><span>Bookings</span>
					</NavLink>
				</TooltipWrapper>

				<TooltipWrapper text='Contacts'>
					<NavLink to='/contacts' className='nav-link'>
						<FarIcon icon='user-tie'/><span>Contacts</span>
					</NavLink>
				</TooltipWrapper>

				<TooltipWrapper text='Facilities'>
					<NavLink to='/facilities' className='nav-link'>
						<FarIcon icon='map-marker'/><span>Facilities</span>
					</NavLink>
				</TooltipWrapper>

				<TooltipWrapper text='Providers'>
					<NavLink to='/providers' className='nav-link'>
						<FarIcon icon='building'/><span>Providers</span>
					</NavLink>
				</TooltipWrapper>

				<TooltipWrapper text='Services'>
					<NavLink to='/services' className='nav-link'>
						<FarIcon icon='concierge-bell'/><span>Services</span>
					</NavLink>
				</TooltipWrapper>

				<TooltipWrapper text='Users'>
					<NavLink to='/users' className='nav-link'>
						<FarIcon icon='user'/><span>Users</span>
					</NavLink>
				</TooltipWrapper>

				<TooltipWrapper text='Support'>
					<NavLink to='/support' className='nav-link'>
						<FarIcon icon='question-square'/><span>Support</span>
					</NavLink>
				</TooltipWrapper>
			</div>

			<div className='admin-sidenav-footer'>
				<TooltipWrapper text='Settings'>
					<NavLink to='/settings' className='nav-link'>
						<FarIcon icon='sliders-v'/><span>Settings</span>
					</NavLink>
				</TooltipWrapper>
				<div className='sub'>
					<TooltipWrapper text='Open'>
						<ButtonWrapper variant='white' className='nav-link w-100' onClick={toggleOpen}>
							{open ? <span><FarIcon icon='chevron-left'/>Collapse</span> : <FarIcon icon='chevron-right'/>}
						</ButtonWrapper>
					</TooltipWrapper>
				</div>
			</div>
		</Nav>
	)
}

export default withRouter(AdminSidenav);
