import React, { Fragment, useEffect, useState } from 'react';
import { Badge } from 'components/basics/badges';
import { SectionBlock, SectionHeader, SectionBody, SectionSubBlock, SectionSubBody, SectionItem, SectionText, SectionFooter } from 'components/basics/sections';
import { CardFooter } from 'components/basics/cards';
import { isEmpty, timeout, logger } from 'utils/helpers';

const SupportDetailSection = (props) => {
	const { support = {}, loading, className } = props;


	// Render
	return (
	  <SectionBlock>
	    <SectionHeader title='Details' />
	    <SectionBody size='xs'>
      	<SectionText title='Reason' value={support.reason} />
        <SectionText title='Message' value={support.message} />
	    </SectionBody>
      <CardFooter className='detail'>
        Last updated: {support.displayUpdatedAt}
      </CardFooter>
	  </SectionBlock>
	)
}

export default SupportDetailSection;
