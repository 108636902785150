import React, { Fragment } from 'react';

import { FacilityListCard, FacilitySearchCard } from 'components/facility/facility-card';
import { SectionEmpty } from 'components/basics/empties';
import { Row, Col } from 'components/basics/grids';
import { GridSkeleton, ListSkeleton, SearchSkeleton } from 'components/vendors/loading-skeleton-wrapper';

const FacilityList = (props) => {
	const { facilities = [], type, selectedID, loading, onClick } = props;

	if (loading) {
		return (
			<Fragment>
				{(type == 'input' || type == 'search') ? (
					<SearchSkeleton count={3} />
				) : (
					<ListSkeleton count={3} />
				)}
			</Fragment>
		)
	} 
	else if (facilities.length > 0) {
		return (
			<Row>
				{(type == 'input' || type == 'search') && facilities.map(facility => (
					<Col key={facility.id} xs={12}>
						<FacilitySearchCard
							type={type}
							facility={facility}
							selectedID={selectedID}
							onClick={onClick}
						/>
					</Col>
				))}
				{type == 'list' && facilities.map(facility => (
					<Col key={facility.id} xs={12}>
						<FacilityListCard
							type={type}
							facility={facility}
						/>
					</Col>
				))}
			</Row>
		)
	}
	return (
		<SectionEmpty
			icon='map-marker'
			title='Facilities'
			msg="Sorry, looks like there aren't any facilities."
		/>
	)
}

export default FacilityList;