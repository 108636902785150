import AppAdapter from 'adapters/app';

class SupportAdapter extends AppAdapter {
	static adminID = null;

	static buildURL(modelName, id) {
		let url = super.buildURL(modelName, id);

		if (this.adminID && id) {
			return this.baseURL() + `/admins/${this.adminID}/${modelName}/${String(id)}`;
		}
		if (this.adminID) {
			return this.baseURL() + `/admins/${this.adminID}/${modelName}`;
		}
		return url;
	}

}

export default SupportAdapter;
