import React, { Fragment } from 'react';

import { ServiceTagPreviewCard } from 'components/service-tag/service-tag-card';
import { SectionEmpty } from 'components/basics/empties';
import { Row, Col } from 'components/basics/grids';
import { ListSkeleton } from 'components/vendors/loading-skeleton-wrapper';

const ServiceTagList = (props) => {
	const { tags = [], type, selectedID, loading, onClick } = props;

	if (loading) {
		return <ListSkeleton count={3} />
	} 
	else if (tags.length > 0) {
		return (
			<Row>
				{type == 'preview' && tags.map(tag => (
					<Col key={tag.id} xs={12}>
						<ServiceTagPreviewCard
						 	type={type}
							tag={tag}
							onClick={onClick}
						/>
					</Col>
				))}
			</Row>
		)
	}
	return (
		<SectionEmpty
			icon='tags'
			title='Service Tags'
			msg="Sorry, looks like you don't have any service tags yet."
		/>
	)
}

export default ServiceTagList;