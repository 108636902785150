import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';

import BookingList from 'components/booking/booking-list';
import NoteSection from 'components/note/note-section';
import ProvidersSection from 'components/provider/providers-section';
import ProvidersSearchInput from 'components/provider/providers-search-input';
import FacilityActions from 'components/facility/facility-actions';
import { FacilityDetailCard } from 'components/facility/facility-card';
import { LocationDetailCard } from 'components/location/location-card';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { ButtonText } from 'components/basics/buttons';
import { Modal } from 'components/basics/modals';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminFacilitiesDetailRoute = (props) => {
  const { facilityID, history, location, store, toast } = props;
  const [ facility, setFacility ] = useState({});
  const [ modal, toggleModal ] = useState(false);
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, []);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord('facility', facilityID, { include: 'providers,location,note' });
      setFacility(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const refreshData = () => {
    toggleModal(!modal);
    fetchData();
  }


  // Render
  return (
    <AdminRoute title={`DMC - Facility #${facilityID}`}>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <SectionHeader title={`Facility #${facilityID}`} className='flex-between no-b'>
              <FacilityActions facility={facility} />
            </SectionHeader>
          </SectionBlock>

          <Row>

            <Col xs={12} md={4}>
              <FacilityDetailCard facility={facility} loading={loading}/>
              <NoteSection
                model={facility.note}
                notableId={facility.id}
                notableType='Facility'
                loading={loading}
              />
            </Col>

            <Col xs={12} md={8}>
              <LocationDetailCard location={facility.location} loading={loading} />
              <ProvidersSection
                type='list'
                facilityID={facilityID}
                loading={loading}
                onClick={() => toggleModal(!modal)}
              />
            </Col>

          </Row>

        </Container>
      </AdminMain>
      <Modal show={modal} toggleModal={() => toggleModal(!modal)}>
        <ProvidersSearchInput
          model={facility}
          providers={facility.providers}
          modal={modal}
          toggleModal={toggleModal}
          nextAction={() => refreshData()}
        />
      </Modal>
    </AdminRoute>
  )
}

export default withStore(withToast(AdminFacilitiesDetailRoute));
