import AppSerializer from 'serializers/app';
import Moment from 'moment';

class Inquiry extends AppSerializer {
	static relationships = {
		...super.relationships,
		location: { serialize: true },
		user: { serialize: 'id' },
	}


	static serializeAttr(data, key) {
		let formattedAttr = super.serializeAttr(data, key);

		if (key == 'apartmentBedCount') {
			return parseInt(data[key]);
		}
		if (key == 'budget') {
			return parseFloat(data[key]);
		}
		if (key.includes('move')) {
			return Moment(data[key]).toISOString();
		}
		return formattedAttr;
	}

}

export default Inquiry;
