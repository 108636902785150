import React, { Fragment } from 'react';
import FacilityActions from 'components/facility/facility-actions';
import { AvatarImage } from 'components/basics/avatars';
import { SectionEmpty } from 'components/basics/empties';
import { Table, TableHeader, TableHeaderRow, TableHead, TableHeadSort, TableBody, TableRow, TableLink, TableData } from 'components/basics/tables';
import { TableSkeleton } from 'components/vendors/loading-skeleton-wrapper';
import { capitalize } from 'utils/transforms';

const FacilityTable = (props) => {
	const { facilities = [], sortProp, sortValue, sortData, loading, refreshData } = props;

	if (loading) {
		return <TableSkeleton count={6} />
	}
	else if (facilities.length > 0) {
		return (
			<Table>
				<TableHeader>
					<TableHeaderRow>
						<TableHeadSort title='Id' value='status' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHead title='Photo'/>
						<TableHeadSort title='Name' value='name' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHeadSort title='Web Address' value='web' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHead title='Location'/>
						<TableHeadSort title='Phone Number' value='phone' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHeadSort title='Updated' value='last_updated' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHead title='Actions'/>
					</TableHeaderRow>
				</TableHeader>
				<TableBody>
					{facilities.map(facility => (
						<TableRow key={facility.id}>
							<TableLink to={facility.url} value={'#' + facility.id}/>
							<TableLink to={facility.url} value={<AvatarImage size='md' photo={facility.photo}/>}/>
							<TableLink to={facility.url}><b>{facility.name}</b></TableLink>
							<TableLink to={facility.url} value={facility.web}/>
							<TableLink to={facility.url} value={facility.location && facility.location.cityState} />
							<TableLink to={facility.url} value={facility.displayPhone}/>
							<TableLink to={facility.url} value={facility.displayUpdatedAt}/>
							<TableData>
								<FacilityActions facility={facility} table={true} refreshData={refreshData} />
							</TableData>
						</TableRow>
					))}
				</TableBody>
			</Table>
		)
	}
	return (
		<SectionEmpty
			icon='map-marker'
			title='Facilities'
			msg="Sorry, looks like there aren't any facilities."
		/>
	)
}

export default FacilityTable;
