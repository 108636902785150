import React, { Fragment } from 'react';
import ActivityCard from 'components/cards/activity-card';
import NotificationCard from 'components/cards/notification-card';

const NotificationListCard = (props) => {
	const { notification = {}, loading, onClick } = props;

	return (
		<ActivityCard
			icon={notification.icon}
			title={notification.title}
			event={notification.event}
			description={notification.description}
			timestamp={notification.displayUpdatedAt}
		/>
	)
}

const NotificationPreviewCard = (props) => {
	const { notification = {}, loading, onClick } = props;

	return (
		<NotificationCard
			icon={notification.icon}
			avatar={notification.user}
			event={notification.event}
			title={notification.title}
			description={notification.description}
			timestamp={notification.displayUpdatedAt}
		/>
	)
}

export { NotificationListCard, NotificationPreviewCard }
