import React, { Fragment } from 'react';
import DetailCard from 'components/cards/detail-card';
import ListCard from 'components/cards/list-card';
import SearchCard from 'components/cards/search-card';
import { isEmpty } from 'utils/helpers';

const UserDetailCard = (props) => {
	const { user = {}, type, loading } = props;

	return (
		<DetailCard
			icon='user'
			photo={user.photo}
			title={user.name}
			line1={<a href={`mailto:${user.email}`}>{user.email}</a>}
			line2={user.displayPhone}
			timestamp={user.displayUpdatedAt}
			loading={loading}
		/>
	)
}

const UserListCard = (props) => {
	const { user = {}, type, loading } = props;

	return (
		<ListCard
			icon='user'
			photo={user.photo}
			title={user.name}
			subtitle={user.email}
			line1={user.displayPhone}
			loading={loading}
			link={`/users/${user.id}`}
		/>
	)
}

const UserSearchCard = (props) => {
	const { user = {}, type, selectedID, selectedIDs, loading, onClick } = props;
	const selected = !isEmpty(selectedIDs) ? selectedIDs.includes(user.id) : user.id == selectedID;

	if (type == 'input') {
		return (
			<SearchCard
				icon='user'
				title={user.name}
				line1={user.email}
				selected={selected}
				onClick={() => onClick(user)}
			/>
		)
	}
	return (
		<SearchCard
			icon='user'
			title={user.name}
			line1={user.email}
			link={`/users/${user.id}`}
		/>
	)
}

export { UserDetailCard, UserListCard, UserSearchCard }
