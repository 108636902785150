import AppModel from 'models/app';

class Location extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);

		// Attrs
		this.street = props.street;
		this.street2 = props.street2;
		this.city = props.city;
		this.state = props.state;
		this.postalCode = props.postalCode;
		this.country = props.country;
		this.latitude = this.attr('decimal', props.latitude);
		this.longitude = this.attr('decimal', props.longitude);


		// Relations
		// this.booking = this.belongsTo('booking', props.booking);
		// this.facility = this.belongsTo('facility', props.facility);
		// this.provider = this.belongsTo('provider', props.provider);
		// this.user = this.belongsTo('user', props.user);
	}


	// Computed
	get summary() {
		return this.street && `${this.street}, ${this.city}, ${this.state} ${this.postalCode}`;
	}

	get cityState() {
		return this.city && `${this.city}, ${this.state}`;
	}

	get cityStatePostal() {
		return this.city && `${this.city}, ${this.state} ${this.postalCode}`;
	}
}

export default Location;
