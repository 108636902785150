import { library } from '@fortawesome/fontawesome-svg-core';

import {
	faAddressCard as fadAddressCard,
	faBells as fadBells,
	faBolt as fadBolt,
	faBuilding as fadBuilding,
	faBroadcastTower as fadBroadcastTower,
	faCalendar as fadCalendar,
	faChartPieAlt as fadChartPieAlt,
	faCheckCircle as fadCheckCircle,
	faConciergeBell as fadConciergeBell,
	faCode as fadCode,
	faComments as fadComments,
	faCogs as fadCogs,
	faCrown as fadCrown,
	faDoorOpen as fadDoorOpen,
	faIdBadge as fadIdBadge,
	faImages as fadImages,
	faInbox as fadInbox,
	faLifeRing as fadLifeRing,
	faMapMarker as fadMapMarker,
	faPencil as fadPencil,
	faPlus as fadPlus,
	faQuestionSquare as fadQuestionSquare,
	faSearch as fadSearch,
	faSlidersH as fadSlidersH,
	faSignOut as fadSignOut,
	faShield as fadShield,
	faSync as fadSync,
	faTags as fadTags,
	faTrash as fadTrash,
	faUniversity as fadUniversity,
	faUser as fadUser,
	faUserCircle as fadUserCircle,
	faUsersCrown as fadUsersCrown,
	faWandMagic as fadWandMagic
} from '@fortawesome/pro-duotone-svg-icons';

library.add(
	fadAddressCard,
	fadBells,
	fadBolt,
	fadBuilding,
	fadBroadcastTower,
	fadCalendar,
	fadChartPieAlt,
	fadCheckCircle,
	fadConciergeBell,
	fadCode,
	fadComments,
	fadCogs,
	fadCrown,
	fadDoorOpen,
	fadIdBadge,
	fadImages,
	fadInbox,
	fadLifeRing,
	fadMapMarker,
	fadPencil,
	fadPlus,
	fadQuestionSquare,
	fadSearch,
	fadSlidersH,
	fadSignOut,
	fadShield,
	fadSync,
	fadTags,
	fadTrash,
	fadUniversity,
	fadUser,
	fadUserCircle,
	fadUsersCrown,
	fadWandMagic
)

export default library;
