import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';

import FacilityForm from 'components/facility/facility-form';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionBody } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminFacilitiesNewRoute = (props) => {
  const { history, location, session, store } = props;
  const [ facility, setFacility ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    createFacility();
  }, []);


  // Methods
  const createFacility = () => {
    let model = store.createRecord('facility', {
      location: store.createRecord('location')
    });
    logger('New Facility: ', model);
    setFacility(model);
  }


  // Render
  return (
    <AdminRoute title='DMC - New Facility'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <FacilityForm
              title='New Facility'
              facility={facility}
              nextAction={() => history.push('/facilities')}
            />
          </SectionBlock>

        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withStore(withToast(AdminFacilitiesNewRoute));

