import React, { Fragment, useEffect, useState } from 'react';

import StatCard from 'components/cards/stat-card';
import { SectionEmpty } from 'components/basics/empties';
import { Container, Row, Col } from 'components/basics/grids';
import { StatSkeleton } from 'components/vendors/loading-skeleton-wrapper';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AnalyticsPreview = (props) => {
	const { analytics = {}, loading } = props;

	if (loading) { return <StatSkeleton count={6} />}
	return (
    <Row>
    	<Col sm={12} md={6} lg={4}>
	  		<StatCard
	  			stat={analytics.displayTotalAdmins}
	  			title='Total Admins'
				/>
			</Col>
    	<Col sm={12} md={6} lg={4}>
	  		<StatCard
	  			stat={analytics.displayTotalBookings}
	  			title='Total Bookings'
				/>
			</Col>
    	<Col sm={12} md={6} lg={4}>
	  		<StatCard
	  			stat={analytics.displayTotalFacilities}
	  			title='Total Facilities'
				/>
			</Col>
    	
    	<Col sm={12} md={6} lg={4}>
	  		<StatCard
	  			stat={analytics.displayTotalProviders}
	  			title='Total Providers'
				/>
			</Col>
    	<Col sm={12} md={6} lg={4}>
	  		<StatCard
	  			stat={analytics.displayTotalServices}
	  			title='Total Services'
				/>
			</Col>
    	<Col sm={12} md={6} lg={4}>
	  		<StatCard
	  			stat={analytics.displayTotalUsers}
	  			title='Total Users'
				/>
			</Col>
    </Row>
	)
}

export default AnalyticsPreview;