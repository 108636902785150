import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withSession } from '@mile-hi-labs/react-session';
import { withToast } from 'contexts/toast-context';

import AdminFields from 'components/admin/admin-fields';
import { Form, FormGroup, FormControl, FormText } from 'components/basics/forms';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { Button } from 'components/basics/buttons';
import { Container, Row, Col } from 'components/basics/grids';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, logger } from 'utils/helpers';

const AdminForm = (props) => {
  const { admin = {}, title, store, toast, nextAction } = props;
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Hooks
  useEffect(() => {
    return () => setTaskRunning(false);
  }, []);


  // Tasks
  const saveAdmin = async (admin) => {
    try {
      setTaskRunning(true);
      await admin.save();
      toast.showSuccess('Profile updated!');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


  // Methods
  const handleSubmit = (e) => {
    e.preventDefault();
  }


  // Render
  return (
    <Form onSubmit={e => e.preventDefault()}>
     <SectionHeader title={title}/>
      <SectionBody size='md'>
        <AdminFields model={admin}/>
      </SectionBody>
      <SectionFooter>
        <Button
          type='submit'
          variant='secondary'
          taskRunning={taskRunning}
          onClick={() => saveAdmin(admin)}>
          Save<FarIcon icon='check' className='ml-2'/>
        </Button>
      </SectionFooter>
    </Form>
  )
}

export default withSession(withStore(withToast(AdminForm)));

