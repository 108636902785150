import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import AlgoliaService from 'services/algolia-service';
import SearchResults from 'components/search/search-results';
import { FormGroup, FormControl, FormControlPrepend } from 'components/basics/forms';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'components/basics/dropdowns';
import { isEmpty, logger, randomString } from 'utils/helpers';

const SearchForm = (props) => {
	const { history, location, className = '' } = props;
	const [ query, setQuery ] = useState('');
	const [ results, setResults ] = useState('');
	const [ dropdown, toggleDropdown ] = useState(false);
	const [ loading, setLoading ] = useState(false);
	const algolia = new AlgoliaService();


	// Hooks
	useEffect(() => {
		query.length >= 3 && searchData(query);
	}, [query]);


  const searchData = async (query = '') => {
    try {
      setLoading(true);
  		let model = await algolia.queryIndices(query);
  		setResults(model);
  		toggleDropdown(true);
    } catch (e) {
      logger(e);
    } finally {
    	setLoading(false);
    }
  }

  const clearSearch = () => {
  	setQuery('');
  	document.getElementById('search_form').value = '';
  	logger('Search cleared');
  }

	// Render
	return (
		<Dropdown open={dropdown} alignRight={false} toggle={() => toggleDropdown(!dropdown)} className={'search-wrapper ' + className}>
	  	<DropdownToggle size='xs' variant='' className='w-100'>
	  		<FormControlPrepend icon='search'>
  				<FormControl
  					id='search_form'
  					type='search'
			      value={query}
			      placeholder='Search for anything...'
			      className='search mr-15'
			      onChange={e => setQuery(e.target.value)}
			      onBlur={() => clearSearch()}
  				/>
	  		</FormControlPrepend>
	    </DropdownToggle>
	    <DropdownMenu open={dropdown} className='search'>
	    	<SearchResults results={results} loading={loading} />
	    </DropdownMenu>
    </Dropdown>
	)
}

export default withRouter(SearchForm);
