import React, { Fragment, useEffect } from 'react';
import DatePickerWrapper from 'components/vendors/date-picker-wrapper';
import LocationFields from 'components/location/location-fields';
import UserSearchInput from 'components/user/user-search-input';
import CurrencyInputWrapper from 'components/vendors/currency-input-wrapper';
import SwitchWrapper from 'components/vendors/switch-wrapper';
import GoogleMapWrapper from 'components/vendors/google-map-wrapper';
import { CityStateAutocomplete } from 'components/vendors/google-autocomplete-wrapper';
import { SubSectionBlock, SubSectionHeader, SubSectionBody } from 'components/basics/sub-sections';
import { Form, FormGroup, FormControl, FormSelect, FormTextArea, FormText } from 'components/basics/forms';
import { Container, Row, Col } from 'components/basics/grids';

const InquiryFields = (props) => {
	const { model } = props;

	return (
    <Fragment>
      <SubSectionBlock className='bb'>
        <SubSectionHeader title='Destination' />
        <SubSectionBody>
          <GoogleMapWrapper size='xs' model={model.location} className='mb-15'/>
          <CityStateAutocomplete
            location={model.location}
            placeholder='Search for a city...'
            onChange={v => model.setRelation('location', v)}
            className='mb-15'
          />
        </SubSectionBody>
      </SubSectionBlock>

      <SubSectionBlock className='bb'>
        <SubSectionHeader title='Details' />
        <SubSectionBody>
          <FormGroup label='Status'>
            <FormSelect
              value={model.status || -1}
              options={model.statusOptions}
              placeholder='Select from the following...'
              onChange={e => model.set('status', e.target.value)}
            />
          </FormGroup>

          <Row>
            <Col sm='6'>
              <FormGroup label='Move In Date'>
                <DatePickerWrapper
                  date={model.moveIn}
                  placeholder='Select date...'
                  onChange={d => model.setMoveInDate(d)}
                />
              </FormGroup>
            </Col>
            <Col sm='6'>
              <FormGroup label='Move Out Date'>
                <DatePickerWrapper
                  date={model.moveOut}
                  placeholder='Select date...'
                  onChange={d => model.setMoveOutDate(d)}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm='12' md='6'>
              <FormGroup label='Payment Method'>
                <FormSelect
                  value={model.paymentMethod || -1}
                  options={model.paymentMethodOptions}
                  placeholder='Select from the following...'
                  onChange={e => model.set('paymentMethod', e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col sm='12' md='6'>
              <FormGroup label='Budget'>
                <CurrencyInputWrapper
                  value={model.budget}
                  placeholder='$00.00'
                  onChange={v => model.set('budget', v)}
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup label='Message'>
            <FormTextArea
              placeholder="Anything you'd like to share?"
              value={model.message}
              options={model.message}
              onChange={e => model.set('message', e.target.value)}
            />
          </FormGroup>

        </SubSectionBody>
      </SubSectionBlock>

      <SubSectionBlock title='Additional Questions' className='bb'>
        <SubSectionBody>
          <FormGroup label='Housing Type'>
            <FormSelect
              value={model.housingType || -1}
              options={model.inquiryTypeOptions}
              placeholder='Select from the following...'
              onChange={e => model.set('housingType', e.target.value)}
            />
          </FormGroup>
          {model.housingType == 'apartment' && (
            <FormGroup label='How Many Bedrooms?'>
              <FormSelect
                value={model.apartmentBedCount || -1}
                options={model.apartmentBedCountOptions}
                placeholder='Select from the following...'
                onChange={e => model.set('apartmentBedCount', e.target.value)}
              />
            </FormGroup>
          )}
          {model.housingType == 'hotel' && (
            <Fragment>
              <FormGroup label='Select Hotel Type'>
                <FormSelect
                  value={model.hotelType}
                  options={model.hotelTypeOptions}
                  placeholder='Select hotel type'
                  onChange={e => model.set('hotelType', e.target.value)}
                />
              </FormGroup>

              <FormGroup label='Select Bed Type'>
                <FormSelect
                  value={model.hotelBedType}
                  options={model.hotelBedTypeOptions}
                  placeholder='Select bed type'
                  onChange={e => model.set('hotelBedType', e.target.value)}
                />
              </FormGroup>
            </Fragment>
          )}
        </SubSectionBody>
      </SubSectionBlock>

      <SubSectionBlock title='Relationships'>

        <SubSectionBody>
          <FormGroup label='Select User'>
            <UserSearchInput
              user={model.user}
              onRemove={v => model.set('user', {})}
              onSelect={v => model.setRelation('user', v)}
            />
          </FormGroup>
        </SubSectionBody>

      </SubSectionBlock>

    </Fragment>
  )
}

export default InquiryFields;
