import AppModel from 'models/app';
import Accounting from 'accounting';
import Moment from 'moment';

class Booking extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);

		// Attrs
		this.status = props.status;
		this.housing = props.housing;
		this.unit = props.unit;
		this.moveIn = props.moveIn;
		this.moveOut = props.moveOut;
		this.netRate = props.netRate;
		this.billingRate = props.billingRate;
		this.billingFrequency = props.billingFrequency;
		this.message = props.message;

		// Misc
		this.mailbox = props.mailbox;
		this.maidService = props.maidService;


		// Relationships
		this.facility = this.belongsTo('facility', props.facility);
		this.note = this.belongsTo('note', props.note);
		this.provider = this.belongsTo('provider', props.provider);
		this.user = this.belongsTo('user', props.user);
		this.services = this.hasMany('services', props.services);
	}


	// Computed
	get billingOptions() {
		return ['daily', 'weekly', 'monthly', 'bi_monthly'];
	}

	get housingOptions() {
		return ['apartment', 'house', 'hotel', 'townhome'];
	}

	get statusOptions() {
		return ['draft', 'requested', 'wip', 'canceled', 'completed'];
	}

	get displayMoveIn() {
		return this.moveIn && Moment(this.moveIn).format('MMM Do, YYYY')
	}

	get displayMoveOut() {
		return this.moveOut && Moment(this.moveOut).format('MMM Do, YYYY')
	}

	get displayDates() {
		return `${this.displayMoveIn} - ${this.displayMoveOut}`
	}

	get displayRate() {
		return `${Accounting.formatMoney(this.billingRate)} (${Accounting.formatMoney(this.netRate)})`
	}

	get displayDescription() {
		return this.message ? this.message : '--';
	}

	get url() {
		return `/bookings/${this.id}`
	}




	// Methods
	setMoveInDate(date) {
		this.set('moveIn', date);
		this.set('moveOut', this.moveIn > this.moveOut ? this.moveIn : this.moveOut);
	}

	setMoveOutDate(date) {
		this.set('moveOut', date);
		this.set('moveIn', this.moveOut < this.moveIn ? this.moveOut : this.moveIn);
	}

}

export default Booking;
