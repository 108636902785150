import React, { Fragment } from 'react';
import { TeammateListCard, TeammateSearchCard } from 'components/teammate/teammate-card';
import { SectionEmpty } from 'components/basics/empties';
import { Row, Col } from 'components/basics/grids';
import { ListSkeleton, SearchSkeleton } from 'components/vendors/loading-skeleton-wrapper';

const TeammateList = (props) => {
	const { teammates = [], type, selectedID, loading, onClick } = props;

	if (loading) {
		return (
			<Fragment>
				{(type == 'input' || type == 'search') ? (
					<SearchSkeleton count={3} />
				) : (
					<ListSkeleton count={3} />
				)}
			</Fragment>
		)
	} 
	else if (teammates.length > 0) {
		return (
			<Row>
				{(type == 'input' || type == 'search') && teammates.map(teammate => (
					<Col key={teammate.id} xs={12}>
						<TeammateSearchCard
							type={type}
							teammate={teammate}
							selectedID={selectedID}
							onClick={onClick}
						/>
					</Col>
				))}
				{type == 'list' && teammates.map(teammate => (
					<Col key={teammate.id} xs={12}>
						<TeammateListCard
							teammate={teammate}
							loading={loading}
						/>
					</Col>
				))}
			</Row>
		)
	}
	return (
		<SectionEmpty
			icon='users-crown'
			title='Teammates'
			msg="Sorry, looks like you don't have any teammates yet."
		/>
	)
}

export default TeammateList;