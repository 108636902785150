import React, { useEffect, useState } from 'react';
import { withSession } from '@mile-hi-labs/react-session';
import { withStore } from '@mile-hi-labs/react-data';

import TeammateForm from 'components/teammate/teammate-form';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionBody } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminSettingsTeammatesNewRoute = (props) => {
  const { history, location, session, store } = props;
  const [ teammate, setTeammate ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    createTeammate();
  }, []);


  // Methods
  const createTeammate = () => {
    let model = store.createRecord('admin', {
      role: 'administrator'
    });
    setTeammate(model);
  }

  // Render
  return (
    <AdminRoute title='DMC - New Service'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <TeammateForm
              title='New Teammate'
              teammate={teammate}
              nextAction={() => history.push('/settings/teammates')}
            />
          </SectionBlock>

        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withSession(withStore(AdminSettingsTeammatesNewRoute));
