import React, { useEffect, useState } from 'react';
import { withSession } from '@mile-hi-labs/react-session';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';


import ActivityList from 'components/activity/activity-list';
import AdminActions from 'components/admin/admin-actions';
import { AdminDetailCard } from 'components/admin/admin-card';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminSettingsProfileIndexRoute = (props) => {
  const { history, location, session, store, toast } = props;
  const [ admin, setAdmin ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, []);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(false);
      let model = await store.queryRecord('admin', session.user.id, { include: 'activities'});
      setAdmin(model);
    } catch (e) {
      toast.showError(e)
    } finally {
      setLoading(false);
    }
  }


  // Render
  return (
    <AdminRoute title='DMC - Profile'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>
          <SectionBlock>
            <SectionHeader title='Your Profile' className='flex-between no-b'>
              <AdminActions admin={admin} />
            </SectionHeader>
          </SectionBlock>

          <Row>

            <Col xs={12} md={4}>
              <AdminDetailCard admin={admin} loading={loading}/>
            </Col>

            <Col xs={12} md={8}>
              <SectionBlock title='Account Activity'>
                <SectionBody>
                  <ActivityList
                    type='preview'
                    activities={admin.activities}
                    loading={loading}
                  />
                </SectionBody>
              </SectionBlock>
            </Col>
          </Row>


        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withSession(withStore(withToast(AdminSettingsProfileIndexRoute)));
