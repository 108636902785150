import React, { useEffect, useState } from 'react';
import { withSession } from '@mile-hi-labs/react-session';
import RegisterForm from 'components/auth/register-form';
import { AuthRoute } from 'components/basics/routes';
import { AuthMain } from 'components/basics/mains';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const RegisterRoute = (props) => {
  const { history, session } = props;
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    if (session.authenticated()) { history.push('/')};
  }, []);


  // Methods
  const visitAdmin = () => {
    history.push('/dashboard');
  }


  // Render
  return (
    <AuthRoute title='DMC - Register'>
      <AuthMain>
        <Container className='sm'>
          <RegisterForm nextAction={() => visitAdmin()}/>
        </Container>
      </AuthMain>
    </AuthRoute>
  )
}

export default withSession(RegisterRoute);

