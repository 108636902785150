import axios from 'axios';

class Axios {
  // Methods
  get instance() {
    return axios.create({ baseURL: process.env.REACT_APP_SERVER_DOMAIN });
  }

}

export default Axios;
