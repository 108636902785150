import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';

import ContactFields from 'components/contact/contact-fields';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/basics/modals';
import { Form, FormGroup, FormControl, FormText } from 'components/basics/forms';
import { Button, ButtonText } from 'components/basics/buttons';
import { Container, Row, Col } from 'components/basics/grids';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, logger } from 'utils/helpers';


const ContactForm = (props) => {
  const { contact = {}, title, type, store, toast, toggleModal, nextAction } = props;
  const [ taskRunning, setTaskRunning ] = useState(false);
  const [ deleteRunning, setDeleteRunning ] = useState(false);


  // Methods
  const saveContact = async (contact) => {
    try {
      setTaskRunning(true);
      await contact.save();
      toast.showSuccess('Contact saved!');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  const destroyContact = async (contact) => {
    try {
      setDeleteRunning(true);
      await contact.destroy();
      toast.showSuccess('Contact destroyed!');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setDeleteRunning(false);
    }
  }


  // Render
  if (type == 'modal') {
    return (
      <Form onSubmit={e => e.preventDefault()}>
        <ModalHeader title={title}/>
        <ModalBody size='md'>
          <ContactFields model={contact}/>
        </ModalBody>
        <ModalFooter className='flex-between'>
          <Button
            type='submit'
            variant='secondary'
            taskRunning={taskRunning}
            onClick={() => saveContact(contact)}>
            Save<FarIcon icon='check' className='ml-2'/>
          </Button>
        </ModalFooter>
      </Form>
    )
  }
  return (
    <Form onSubmit={e => e.preventDefault()}>
      <SectionHeader title={title}/>
      <SectionBody size='md'>
        <ContactFields model={contact}/>
      </SectionBody>
      <SectionFooter className='flex-between'>
        <Button
          type='submit'
          variant='secondary'
          taskRunning={taskRunning}
          onClick={() => saveContact(contact)}>
          Save<FarIcon icon='check' className='ml-2'/>
        </Button>
      </SectionFooter>
    </Form>
  )
}

export default withStore(withToast(ContactForm));

