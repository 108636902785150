import React, { Fragment } from 'react';
import Accounting from 'accounting';
import Moment from 'moment';

import DetailCard from 'components/cards/detail-card';
import ListCard from 'components/cards/list-card';
import SearchCard from 'components/cards/search-card';

const BookingDetailCard = (props) => {
	const { booking = {}, loading } = props;

	return (
		<DetailCard
			icon='calendar'
			status={booking.status}
			photo={booking.user && booking.user.photo}
			title={booking.user && booking.user.name}
			line1={booking.displayDates}
			line2={'Billing: ' + booking.displayRate}
			timestamp={booking.displayUpdatedAt}
			loading={loading}
		/>
	)
}

const BookingListCard = (props) => {
	const { booking = {}, loading } = props;

	return (
		<ListCard
			icon='calendar'
			status={booking.status}
			title={booking.user && booking.user.name}
			subtitle={booking.facility && booking.facility.name}
			line1={booking.displayDates}
			loading={loading}
			link={`/bookings/${booking.id}`}
		/>
	)
}

const BookingSearchCard = (props) => {
	const { booking = {}, type, selectedID, loading, onClick } = props;
	const selected = booking.id == selectedID;

	if (type == 'input') {
		return (
			<SearchCard
				icon='calendar'
				status={`Booking #${booking.id}`}
				title={booking.user && booking.user.name}
				line1={`${Moment(booking.move_in).format('MMM Do, YYYY')} - ${Moment(booking.out).format('MMM Do, YYYY')}`}
				selected={selected}
				onClick={() => onClick(booking)}
			/>
		)
	}
	return (
		<SearchCard
			icon='calendar'
			status={booking.status}
			title={booking.user && booking.user.name}
			line1={`${Moment(booking.move_in).format('MMM Do, YYYY')} - ${Moment(booking.out).format('MMM Do, YYYY')}`}
			link={`/bookings/${booking.id}`}
		/>
	)
}

export { BookingDetailCard, BookingListCard, BookingSearchCard }
