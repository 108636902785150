import AppModel from 'models/app';

class Settings extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);

		// Attrs
		this.bookingNotifications = props.bookingNotifications;
		this.messageNotifications = props.messageNotifications;
		this.serviceNotifications = props.serviceNotifications;
		this.userNotifications = props.userNotifications;
	}
}

export default Settings;
