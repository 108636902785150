import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import DayPicker from 'react-day-picker';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { FormControl, FormControlPrepend } from 'components/basics/forms';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'components/basics/dropdowns';
import { logger } from 'utils/helpers';

import 'react-day-picker/lib/style.css';

const DatePickerInput = (props) => {
	const { date, placeholder, onChange } = props;
	const [ dropdownOpen, toggleDropdown ] = useState(false);


  // Methods
  const displayDate = (value) => {
  	return Moment(date).format('MMMM Do, YYYY');
  }

  const formattedDate = (value) => {
  	return Moment(value).toDate();
  }

  const setDate = (value) => {
  	onChange(Moment(value));
  	toggleDropdown(!dropdownOpen);
  }


  // Render
	return (
		<Dropdown open={dropdownOpen} alignRight={false} toggle={() => toggleDropdown(!dropdownOpen)}>
			<DropdownToggle size='none' variant='' onClick={() => toggleDropdown(!dropdownOpen)} className='w-100'>
				<FormControlPrepend icon='calendar'>
					<div className='form-control text-left'>{displayDate(date)}</div>
				</FormControlPrepend>
			</DropdownToggle>

			<DropdownMenu className='p-15 date'>
				<DayPicker
	        disableDays={[]}
	        selectedDays={formattedDate(date)}
	        onDayClick={value => setDate(value)}
	        inputProps={{className: ''}}
	      />
      </DropdownMenu>

    </Dropdown>
	)
}

export default DatePickerInput;

// Docs:
// https://react-day-picker.js.org/docs/getting-started