import React, { Fragment } from 'react';
import { Form, FormGroup, FormControl, FormTextArea } from 'components/basics/forms';
import { Container, Row, Col } from 'components/basics/grids';

const ServiceTagFields = (props) => {
	const { model } = props;

	return (
    <Fragment>
      <FormGroup label='Tag Name'>
        <FormControl
          type='text'
          placeholder='Water Leak'
          value={model.title}
          onChange={e => model.set('title', e.target.value)}
        />
      </FormGroup>

      <FormGroup label='Description'>
        <FormTextArea
          placeholder='Please describe this service request for others...'
          value={model.description}
          onChange={e => model.set('description', e.target.value)}
        />
      </FormGroup>

    </Fragment>
  )
}

export default ServiceTagFields;
