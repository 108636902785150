import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';
import NotificationList from 'components/notification/notification-list';
import { Pagination } from 'components/basics/pagination';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';

const NotificationsSection = (props) => {
	const { title = 'Notifications', alertID, userID, store, toast } = props;
  const [ notifications, setNotifications ] = useState([]);
  const [ page, setPage ] = useState(0);
  const [ pageSize, setPageSize ] = useState(10);
	const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    if (alertID || userID) fetchData();
  }, [alertID, userID, page, pageSize]);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let notifications = await store.query('notification', formattedParams())
      setNotifications(notifications);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const formattedParams = () => {
    let params = {
      page: page,
      pageSize: pageSize,
      sortProp: 'id',
      sortValue: 'desc',
      user_id: userID,
      include: 'user'
    }
    if (alertID) params.notifiable_type = 'Alert';
    if (alertID) params.notifiable_id = alertID;
    return params;
  }


	// Render
	return (
    <SectionBlock>
      <SectionHeader title='Notifications'>
      </SectionHeader>
      <SectionBody>
        <NotificationList
        	type='preview'
        	notifications={notifications}
        	loading={loading}
      	/>
      </SectionBody>
      <SectionFooter>
      	<Pagination meta={notifications.meta} onClick={page => setPage(page)}/>
      </SectionFooter>
    </SectionBlock>
	)
}

export default withStore(withToast(NotificationsSection));
