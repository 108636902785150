import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withSession } from '@mile-hi-labs/react-session';
import { withToast } from 'contexts/toast-context';

import UserFields from 'components/user/user-fields';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/basics/modals';
import { Form, FormGroup, FormControl, FormText } from 'components/basics/forms';
import { Button } from 'components/basics/buttons';
import { Container, Row, Col } from 'components/basics/grids';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, logger } from 'utils/helpers';


const UserForm = (props) => {
  const { user = {}, title, type, session, store, toast, nextAction } = props;
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Hooks
  useEffect(() => {
    return () => setTaskRunning(false);
  }, []);


  // Methods
  const saveUser = async (user) => {
    try {
      setTaskRunning(true);
      await user.save();
      toast.showSuccess('User saved!');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


  // Render
  if (type == 'modal') {
    return (
      <Form onSubmit={e => e.preventDefault()}>
        <ModalHeader title={title}/>
        <ModalBody size='md'>
          <UserFields model={user}/>
        </ModalBody>
        <ModalFooter>
          <Button
            type='submit'
            variant='secondary'
            taskRunning={taskRunning}
            onClick={() => saveUser(user)}>
            Save<FarIcon icon='check' className='ml-2'/>
          </Button>
        </ModalFooter>
      </Form>
    )
  }
  return (
    <Form onSubmit={e => e.preventDefault()}>
      <SectionHeader title={title}/>
      <SectionBody size='md'>
        <UserFields model={user}/>
      </SectionBody>
      <SectionFooter>
        <Button
          type='submit'
          variant='secondary'
          taskRunning={taskRunning}
          onClick={() => saveUser(user)}>
          Save<FarIcon icon='check' className='ml-2'/>
        </Button>
      </SectionFooter>
    </Form>
  )
}

export default withSession(withStore(withToast(UserForm)));

