import React, { Fragment, useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';
import BookingList from 'components/booking/booking-list';
import { SectionEmpty } from 'components/basics/empties';
import { Container, Row, Col } from 'components/basics/grids';
import { Pagination } from 'components/basics/pagination';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { isEmpty, timeout, logger } from 'utils/helpers';

const BookingsSection = (props) => {
	const { title = 'Bookings', userID, store, toast } = props;
	const [ bookings, setBookings ] = useState([]);
  const [ page, setPage ] = useState(0);
  const [ pageSize, setPageSize ] = useState(5);
	const [ loading, setLoading ] = useState(false);


	// Hooks
	useEffect(() => {
		fetchData();
	}, [page, pageSize]);


	// Methods
	const fetchData = async () => {
    try {
      setLoading(true);
      let bookings = await store.query('booking', {
        page: page,
        pageSize: pageSize,
        sortProp: 'id',
        sortValue: 'desc',
        user_id: userID,
        include: 'facility,user'
      });
      setBookings(bookings);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
	}

	return (
    <SectionBlock>
      <SectionHeader title={title} className='flex-between' />
      <SectionBody>
      	<BookingList
      		type='list'
      		bookings={bookings}
      		loading={loading}
      	/>
      </SectionBody>
      <SectionFooter>
      	<Pagination meta={bookings.meta} onClick={page => setPage(page)}/>
      </SectionFooter>
    </SectionBlock>
	)
}

export default withStore(withToast(BookingsSection));
