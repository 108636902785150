import React, { useEffect, useState } from 'react';
import { withSession } from '@mile-hi-labs/react-session';
import UserResetPasswordForm from 'components/user/user-reset-password-form';
import { MktRoute } from 'components/basics/routes';
import { AuthMain } from 'components/basics/mains';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const UserResetPasswordRoute = (props) => {
  const { history, session } = props;
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    if (session.authenticated()) { history.push('/')};
  }, []);


  // Render
  return (
    <MktRoute title='DMC - Reset Password'>
      <AuthMain>
        <Container className='sm'>
          <UserResetPasswordForm nextAction={() => visitLogin()}/>
        </Container>
      </AuthMain>
    </MktRoute>
  )
}

export default withSession(UserResetPasswordRoute);

