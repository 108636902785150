import React, { Fragment } from 'react';
import FilestackPhotoWrapper from 'components/vendors/filestack-photo-wrapper';
import SwitchWrapper from 'components/vendors/switch-wrapper';
import UsersSearchInput from 'components/user/users-search-input';
import { SubSectionBlock, SubSectionHeader, SubSectionBody } from 'components/basics/sub-sections';
import { TagsContainer, Tag } from 'components/basics/tags';
import { Form, FormGroup, FormControl, FormTextArea } from 'components/basics/forms';
import { Container, Row, Col } from 'components/basics/grids';

const AlertFields = (props) => {
	const { model } = props;

	return (
    <Fragment>
      <SubSectionBlock className='bb'>
        <SubSectionHeader title='Details' />
          <SubSectionBody>
            <FormGroup>
              <FilestackPhotoWrapper
                type='photo'
                value={model.photo}
                width={16}
                height={9}
                onChange={v => model.set('photo', v)}
              />
            </FormGroup>
            <FormGroup label='Title'>
              <FormControl
                value={model.title}
                placeholder='News announcement!'
                onChange={e => model.set('title', e.target.value)}
              />
            </FormGroup>

            <FormGroup label='Message'>
              <FormTextArea
                value={model.message}
                placeholder='What would you like to share?'
                onChange={e => model.set('message', e.target.value)}
              />
            </FormGroup>

          <FormGroup>
            <SwitchWrapper
              label='Send to all users?'
              value={model.selectAll}
              onChange={v => model.set('selectAll', v)}
            />
          </FormGroup>

        </SubSectionBody>
      </SubSectionBlock>
      {!model.selectAll && (
        <SubSectionBlock title='Users'>
          <SubSectionBody>
            <FormGroup label='Select Users'>
              <UsersSearchInput model={model} users={model.users} />
            </FormGroup>
            <FormGroup>
              <TagsContainer>
                {(model.users && (model.users.length > 0)) && model.users.map(user => (
                  <Tag key={user.id} title={user.name} onClick={() => model.pushProp('users', user)} />
                ))}
              </TagsContainer>
            </FormGroup>

          </SubSectionBody>
        </SubSectionBlock>
      )}

    </Fragment>
  )
}

export default AlertFields;
