import React, { Fragment } from 'react';
import SwitchWrapper from 'components/vendors/switch-wrapper';
import { SubSectionBlock, SubSectionHeader, SubSectionBody } from 'components/basics/sub-sections';
import { Form, FormGroup, FormControl, FormSelect, FormTextArea } from 'components/basics/forms';
import { Container, Row, Col } from 'components/basics/grids';

const SettingsFields = (props) => {
  const { model } = props;

  return (
    <Fragment>
      <SubSectionBlock title='Notification Types'>
        <SubSectionBody>
          <FormGroup>
            <SwitchWrapper
              label='Booking notifications'
              value={model.bookingNotifications}
              onChange={v => model.set('bookingNotifications', v)}
              className='mb-3'
            />
          </FormGroup>

          <FormGroup>
            <SwitchWrapper
              label='Message Notifications'
              value={model.messageNotifications}
              onChange={v => model.set('messageNotifications', v)}
              className='mb-3'
            />
          </FormGroup>

          <FormGroup>
            <SwitchWrapper
              label='Service Notifications'
              value={model.serviceNotifications}
              onChange={v => model.set('serviceNotifications', v)}
              className='mb-3'
            />
          </FormGroup>

          <FormGroup>
            <SwitchWrapper
              label='User Notifications'
              value={model.userNotifications}
              onChange={v => model.set('userNotifications', v)}
              className='mb-3'
            />
          </FormGroup>

        </SubSectionBody>
      </SubSectionBlock>

    </Fragment>
  )
}

export default SettingsFields;
