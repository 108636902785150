import React, { useEffect, useState } from 'react';
import { withSession } from '@mile-hi-labs/react-session';
import { withStore } from '@mile-hi-labs/react-data';

import ServiceForm from 'components/service/service-form';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionBody } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminServicesNewRoute = (props) => {
  const { history, location, session, store } = props;
  const [ service, setService ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    createService();
  }, []);


  // Methods
  const createService = () => {
    let model = store.createRecord('service', {
      status: 'draft',
      permission: false
    });
    setService(model);
  }


  // Render
  return (
    <AdminRoute title='DMC - New Service'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <ServiceForm
              title='New Service'
              service={service}
              nextAction={() => history.push('/services')}
            />
          </SectionBlock>

        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withSession(withStore(AdminServicesNewRoute));
