import React, { Fragment } from 'react';
import DetailCard from 'components/cards/detail-card';
import ListCard from 'components/cards/list-card';
import PreviewCard from 'components/cards/preview-card';
import SearchCard from 'components/cards/search-card';

const ContactDetailCard = (props) => {
	const { contact = {}, type, loading, link } = props;

	return (
		<DetailCard
			icon='user'
			photo={contact.photo}
			title={contact.name}
			subtitle={contact.jobTitle}
			line1={<a href={`mailto:${contact.email}`}>{contact.email}</a>}
			timestamp={contact.displayUpdatedAt}>
			<h6>{contact.displayCellPhone}</h6>
			<h6>{contact.displayWorkPhone}</h6>
			<p>{contact.displayDescription}</p>
		</DetailCard>
	)
}

const ContactListCard = (props) => {
	const { contact = {}, type, loading, link } = props;

	return (
		<ListCard
			icon='user'
			photo={contact.photo}
			title={contact.name}
			subtitle={contact.jobTitle}
			line1={contact.email}
			link={`/contacts/${contact.id}`}
		/>
	)
}

const ContactPreviewCard = (props) => {
	const { contact = {}, type, loading, onClick } = props;

	return (
		<PreviewCard
			icon='user'
			photo={contact.photo}
			title={contact.name}
			subtitle={contact.jobTitle}
			line1={contact.email}
			line2={contact.displayWorkPhone}
			onClick={() => onClick(contact)}
		/>
	)
}

const ContactSearchCard = (props) => {
	const { contact = {}, type, selectedID, loading, onClick } = props;
	const selected = contact.id == selectedID;

	if (type == 'input') {
		return (
			<SearchCard
				icon='user'
				title={contact.name}
				line1={contact.email}
				selected={selected}
				onClick={() => onClick(contact)}
			/>
		)
	}
	return (
		<SearchCard
			icon='user'
			title={contact.name}
			line1={contact.email}
			link={`/contacts/${contact.id}`}
		/>
	)
}

export { ContactDetailCard, ContactListCard, ContactPreviewCard, ContactSearchCard }