import React, { Fragment } from 'react';
import { SectionEmpty } from 'components/basics/empties';

const NotePreviewCard = (props) => {
	const { note = {}, loading, onClick } = props;

	if (note.id) {
		return <h6>{note.notes}</h6>
	}
	return (
		<SectionEmpty
			icon='pencil'
			title='Note'
			msg="Post a note for other admins."
		/>
	)
}

export { NotePreviewCard }