import React, { useEffect, useState } from 'react';
import { withSession } from '@mile-hi-labs/react-session';

import ListCard from 'components/cards/list-card';
import PreviewCard from 'components/cards/preview-card';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionBody, SectionDivider } from 'components/basics/sections';
import { SubSectionBlock, SubSectionHeader, SubSectionBody } from 'components/basics/sub-sections';
import { Container, Row, Col } from 'components/basics/grids';
import { FadIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminSettingsRoute = (props) => {
  const { history, location, session } = props;
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
  }, []);


  // Render
  return (
    <AdminRoute title='DMC - Settings'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock title='Settings'>
            <SectionBody>
              <ListCard
                icon='user'
                title='Profile'
                line1="View your profile and recent activity."
                link='/settings/profile'
              />

              <ListCard
                icon='bolt'
                title='Notifications'
                line1="Manage which notifications you'd like to receive."
                link='/settings/notifications'
              />

              <ListCard
                icon='users-crown'
                title='Teammates'
                line1="Manage your teammates and their permissions."
                link='/settings/teammates'
              />

              <ListCard
                icon='cogs'
                title='Customizations'
                line1="Manage various platform-wide customizations."
                link='/settings/customizations'
              />

              <ListCard
                icon='life-ring'
                title='Documentation'
                line1="Find answers to common questions about this platform."
                link='/settings/documentation'
              />

              <ListCard
                icon='door-open'
                title='Exit Dashboard'
                line1="Exit the dashboard and head back to the home screen."
                link='/'
              />
              <SectionDivider className='pb-2'/>
              <SubSectionBlock>
                <h6 className='sub-text'><FadIcon icon='code' className='mr-2' />Application built by <a href='https://erichubbell.com' target='blank'>Eric Hubbell</a>.</h6>
              </SubSectionBlock>

            </SectionBody>
          </SectionBlock>

        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withSession(AdminSettingsRoute);
