import React, { useEffect, useState } from 'react';
import { withSession } from '@mile-hi-labs/react-session';

import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { Accordion, AccordionToggle, AccordionCollapse } from 'components/basics/accordions';
import { SectionBlock, SectionBody } from 'components/basics/sections';
import { SubSectionBlock, SubSectionHeader, SubSectionBody } from 'components/basics/sub-sections';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminSettingsDocumentationRoute = (props) => {
  const { history, location, session } = props;
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
  }, []);


  // Tasks


  // Render
  return (
    <AdminRoute title='DMC - Documentation'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock title='Documentation'>
            <SectionBody>

              <SubSectionBlock title='Models'>
                <SubSectionBody>

                  <Accordion>
                    <AccordionToggle id='1' title='Can I Delete All Models?'/>
                    <AccordionCollapse id='1'>
                      Yes. <br/>
                      But, in some situations (such as Users), you can only delete the model after it's relationships have been deleted.<br/>
                      This is designed to prevent you from deleting valuable information such as an inquiry or booking or service request.
                    </AccordionCollapse>
                  </Accordion>

                </SubSectionBody>
              </SubSectionBlock>

               <SubSectionBlock title='Notifications'>
                <SubSectionBody>

                  <Accordion>
                    <AccordionToggle id='3' title='How Do Admin Notifications Work?'/>
                    <AccordionCollapse id='3'>
                      We create an activity for most CRUD actions that occur across the application. <br/>
                      We then check whether to send a notification. <br/>
                      We then send a notificaiton to each admin after checking their settings.
                    </AccordionCollapse>
                  </Accordion>

                  <Accordion>
                    <AccordionToggle id='4' title='How Do User Notifications Work?' />
                    <AccordionCollapse id='4'>
                      We create a notification for a more curated list of CRUD actions that occur across the app. <br/>
                      We then check whether to send a notification and which platforms to send to. <br/>
                      We then send a notification if the user after checking the user's settings.
                    </AccordionCollapse>
                  </Accordion>

                </SubSectionBody>
              </SubSectionBlock>

              <SubSectionBlock title='Development'>
                <SubSectionBody>

                  <Accordion>
                    <AccordionToggle id='1' title='Tell Me About The Infrastructure?'/>
                    <AccordionCollapse id='1'>
                      This project is hosted on several droplets configured and operated through Digital Ocean. <br/>
                      The web uses React, mobile uses React-Native, and the API is powered by Node. <br/>
                      The project uses dev, staging, and production environments for scalability. <br/>
                      All code is then stored on Github under the DMC organization with matching branches.
                    </AccordionCollapse>
                  </Accordion>

                </SubSectionBody>
              </SubSectionBlock>

            </SectionBody>
          </SectionBlock>

        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withSession(AdminSettingsDocumentationRoute);
