import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withSession } from '@mile-hi-labs/react-session';
import { withToast } from 'contexts/toast-context';

import ContactFields from 'components/contact/contact-fields';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/basics/modals';
import { Form, FormGroup, FormControl, FormTextArea } from 'components/basics/forms';
import { Button, ButtonText } from 'components/basics/buttons';
import { Container, Row, Col } from 'components/basics/grids';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, logger } from 'utils/helpers';


const NoteForm = (props) => {
  const { note = {}, title, session, store, toast, toggleModal, nextAction } = props;
  const [ taskRunning, setTaskRunning ] = useState(false);
  const [ deleteRunning, setDeleteRunning ] = useState(false);


  // Methods
  const saveNote = async (note) => {
    try {
      setTaskRunning(true);
      await note.save();
      toast.showSuccess('Note saved!');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


  // Render
  return (
    <Form onSubmit={e => e.preventDefault()}>
      <ModalHeader title={title}/>
      <ModalBody size='md'>
        <FormGroup label='Note'>
          <FormTextArea
            value={note.notes}
            placeholder='What would you like to share?'
            onChange={e => note.set('notes', e.target.value)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          type='submit'
          variant='secondary'
          taskRunning={taskRunning}
          onClick={() => saveNote(note)}>
          Save<FarIcon icon='check' className='ml-2'/>
        </Button>
      </ModalFooter>
    </Form>
  )
}

export default withSession(withStore(withToast(NoteForm)));

