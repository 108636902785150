import React, { Fragment } from 'react';

import { Badge } from 'components/basics/badges';
import { Card, CardHeader, CardImg, CardBody, CardFooter } from 'components/basics/cards';
import { Image } from 'components/basics/images';
import { Link } from 'components/basics/links';
import { ButtonWrapper, ButtonText } from 'components/basics/buttons';
import { FarIcon, FadIcon } from 'components/vendors/fa-wrapper';

const SearchCard = (props) => {
	const { status, icon, photo, title, line1, line2, link, selected, onClick } = props;

	if (onClick) {
		return (
			<ButtonWrapper onClick={onClick}>
				<Card className='search'>
					<CardHeader className='search'>
						{icon && <FadIcon icon={icon} size='2x' className='card-icon'/>}
						{photo && <CardImg src={photo} />}
					</CardHeader>
					<CardBody className='search'>
						<div>
							{status && <Badge title={status} />}
							{title && <h6 className='title sm'>{title}</h6>}
							{line1 && <h6 className='sm'>{line1}</h6>}
							{line2 && <h6 className='sm'>{line2}</h6>}
						</div>
						<ButtonText as='div' size='xs' variant='gray'>
							{selected ? <FarIcon icon='check-circle' /> : <FarIcon icon='circle' />}
						</ButtonText>
					</CardBody>
				</Card>
			</ButtonWrapper>
		)
	}
	return (
		<Link to={link}>
			<Card className='search'>
				<CardHeader className='search'>
					{icon && <FadIcon icon={icon} size='2x' className='card-icon'/>}
					{photo && <CardImg src={photo} />}
				</CardHeader>
				<CardBody className='search'>
					<div>
						{status && <Badge title={status} />}
						{title && <h6 className='title sm'>{title}</h6>}
						{line1 && <h6 className='sm'>{line1}</h6>}
						{line2 && <h6 className='sm'>{line2}</h6>}
					</div>
					<span className='btn btn-xs btn-text-gray'>
						<FarIcon icon='chevron-right'/>
					</span>
				</CardBody>
			</Card>
		</Link>
	)
}

export default SearchCard;