import React, { Fragment } from 'react';
import { ServiceListCard, ServicePreviewCard } from 'components/service/service-card';
import { SectionEmpty } from 'components/basics/empties';
import { Row, Col } from 'components/basics/grids';
import { ListSkeleton, GridSkeleton } from 'components/vendors/loading-skeleton-wrapper';

const ServiceList = (props) => {
	const { services = [], type, loading, onClick } = props;

	if (loading) {
		return <ListSkeleton count={3} />
	}
	else if (services.length > 0) {
		return (
			<Row>
				{type == 'list' && services.map(service => (
					<Col key={service.id} xs={12}>
						<ServiceListCard service={service} />
					</Col>
				))}

				{type == 'preview' && services.map(service => (
					<Col key={service.id} xs={12}>
						<ServicePreviewCard service={service} />
					</Col>
				))}
			</Row>
		)
	}
	return (
		<SectionEmpty
			icon='concierge-bell'
			title='Services'
			msg="Sorry, looks like there aren't any service requests."
		/>
	)
}

export default ServiceList;
