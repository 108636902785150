import React, { Fragment } from 'react';

import { ProviderListCard, ProviderPreviewCard, ProviderSearchCard } from 'components/provider/provider-card';
import { SectionEmpty } from 'components/basics/empties';
import { Row, Col } from 'components/basics/grids';
import { ListSkeleton, SearchSkeleton } from 'components/vendors/loading-skeleton-wrapper';

const ProviderList = (props) => {
	const { providers = [], type, selectedIDs, selectedID, loading, onClick } = props;

	if (loading) {
		return (
			<Fragment>
				{(type == 'input' || type == 'search') ? (
					<SearchSkeleton count={3} />
				) : (
					<ListSkeleton count={3} />
				)}
			</Fragment>
		)
	}
	else if (providers && providers.length > 0) {
		return (
			<Row>
				{(type == 'input' || type == 'search') && providers.map(provider => (
					<Col key={provider.id} xs={12}>
						<ProviderSearchCard
							type={type}
							provider={provider}
							selectedIDs={selectedIDs}
							selectedID={selectedID}
							onClick={onClick}
						/>
					</Col>
				))}
				{type == 'list' && providers.map(provider => (
					<Col key={provider.id} xs={12}>
						<ProviderListCard
							provider={provider}
							selectedID={selectedID}
						/>
					</Col>
				))}
				{type == 'preview' && providers.map(provider => (
					<Col key={provider.id} xs={12}>
						<ProviderPreviewCard
							provider={provider}
							selectedID={selectedID}
							onClick={onClick}
						/>
					</Col>
				))}
			</Row>
		)
	}
	return (
		<SectionEmpty
			icon='user-circle'
			title='Providers'
			msg="Sorry, looks like there aren't any providers."
		/>
	)
}

export default ProviderList;
