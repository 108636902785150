import AppSerializer from 'serializers/app';

class MessageSerializer extends AppSerializer {
	static relationships = {
		...super.relationships,
		service: { serialize: 'id' },
	}
}

export default MessageSerializer;
