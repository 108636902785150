import React, { Fragment } from 'react';
import FilestackPhotoWrapper from 'components/vendors/filestack-photo-wrapper';
import LocationFields from 'components/location/location-fields';
import { PhoneMaskedInput } from 'components/vendors/masked-input-wrapper';
import { SubSectionBlock, SubSectionHeader, SubSectionBody } from 'components/basics/sub-sections';
import { Form, FormGroup, FormControl, FormTextArea } from 'components/basics/forms';
import { Container, Row, Col } from 'components/basics/grids';

const ProviderFields = (props) => {
	const { model } = props;

	return (
    <Fragment>
      <SubSectionBlock className='bb'>
        <SubSectionHeader title='Details' />
        <SubSectionBody>
          <FormGroup>
            <FilestackPhotoWrapper
              type='provider'
              value={model.photo}
              onChange={v => model.set('photo', v)}
            />
          </FormGroup>
          <FormGroup label='Business Name'>
            <FormControl
              type='text'
              placeholder='Acme, Inc'
              value={model.name}
              onChange={e => model.set('name', e.target.value)}
            />
          </FormGroup>

          <FormGroup label='Email Address'>
            <FormControl
              type='text'
              placeholder='president@acmeinc.com'
              value={model.email}
              onChange={e => model.set('email', e.target.value)}
            />
          </FormGroup>

          <Row>
            <Col xs='12' sm='6'>
              <FormGroup label='Phone Number'>
                <PhoneMaskedInput
                  placeholder='(303) 444-2226'
                  value={model.phone}
                  onChange={e => model.set('phone', e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col xs='12' sm='6'>
              <FormGroup label='Web Address'>
                <FormControl
                  placeholder='https://www.acme.com'
                  value={model.web}
                  onChange={e => model.set('web', e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup label='Description'>
            <FormTextArea
              type='text'
              placeholder='Please add a description...'
              value={model.description}
              onChange={e => model.set('description', e.target.value)}
            />
          </FormGroup>
        </SubSectionBody>
      </SubSectionBlock>

      <LocationFields model={model} location={model.location} />
    </Fragment>
  )
}

export default ProviderFields;
