import React, { Fragment } from 'react';
import ActivityCard from 'components/cards/activity-card';

const ActivityListCard = (props) => {
	const { activity = {}, loading } = props;

	return (
		<ActivityCard
			icon={activity.icon}
			title={activity.title}
			event={activity.event}
			description={activity.description}
			timestamp={activity.displayUpdatedAt}
		/>
	)
}

const ActivityPreviewCard = (props) => {
	const { activity = {}, loading } = props;

	return (
		<ActivityCard
			icon={activity.icon}
			title={activity.title}
			event={activity.event}
			description={activity.description}
			timestamp={activity.displayUpdatedAt}
		/>
	)
}

export { ActivityListCard, ActivityPreviewCard }
