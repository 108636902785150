import React, { Fragment } from 'react';
import DetailCard from 'components/cards/detail-card';
import { SectionEmpty } from 'components/basics/empties';

const AdminDetailCard = (props) => {
	const { admin = {}, loading } = props;

	return (
		<DetailCard
			icon='users-crown'
			photo={admin.photo}
			title={admin.name}
			subtitle={admin.role}
			line1={<a href={`mailto:${admin.email}`}>{admin.email}</a>}
			line2={admin.displayPhone}
			timestamp={admin.displayUpdatedAt}
			loading={loading}>
		</DetailCard>
	)
}

export { AdminDetailCard }
