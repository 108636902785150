import React from 'react';
import CurrencyInput from 'react-currency-input';
import { FormControlPrepend } from 'components/basics/forms';
import { logger } from 'utils/helpers';

const CurrencyInputWrapper = (props) => {
  const { name, value, placeholder, readOnly, onChange, className } = props;

  
  // Methods
  const formatValue = (event, maskedValue, floatValue) => {
    onChange(floatValue);
  }
 
  // Render
  return (
    <FormControlPrepend icon='dollar-sign'>
      <CurrencyInput 
        type='text'
        name={name}
        precision='2'
        value={value} 
        className={'form-control ' + className}
        placeholder={placeholder} 
        onChangeEvent={formatValue} 
      />
    </FormControlPrepend>
  )
}

export default CurrencyInputWrapper;

// Docs
// https://github.com/jsillitoe/react-currency-input#readme