import React, { Fragment, useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';
import { Form, FormGroup, FormControl, FormSelect, FormTextArea } from 'components/basics/forms';

const ServiceTagSelect = (props) => {
	const { value, store, toast, other, toggleOther, onChange } = props;
	const [ options, setOptions ] = useState([]);
	const [ loading, setLoading ] = useState(false);

	// Hooks
	useEffect(() => {
		fetchData()
	}, []);


	// Methods
	const fetchData = async() => {
    try { 
      setLoading(true);
      let model = await store.query('service-tag', {});
      let formattedModel = model.map(tag => tag.title);
      formattedModel.push('other');
      setOptions(formattedModel);
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
	}

	const setOption = (option) => {
		if (option == 'other') {
			toggleOther(!other);
			onChange('');
		} else {
			toggleOther(false);
			onChange(option);
		}
	}


	// Render
	return (
    <FormSelect
      value={value}
      options={options}
      placeholder='Select issue...' 
      onChange={e => setOption(e.target.value)}
    />
	)
}

export default withStore(withToast(ServiceTagSelect));
