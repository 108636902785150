import React, { Fragment, useState, useEffect } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';

import AlgoliaService from 'services/algolia-service';
import UserList from 'components/user/user-list';
import { Pagination } from 'components/basics/pagination';
import { TagsContainer, Tag } from 'components/basics/tags';
import { Button } from 'components/basics/buttons';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/basics/modals';
import { FormGroup, FormControl, FormControlDiv, FormControlPrepend, FormControlPreAndAppend } from 'components/basics/forms';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, logger, timeout, randomString } from 'utils/helpers';

const UsersSearchInput = (props) => {
	const { model = {}, users = [], store, toast } = props;
	const [ query, setQuery ] = useState('');
	const [ hits, setHits ] = useState('');
  const [ meta, setMeta ] = useState({});
  const [ modal, toggleModal ] = useState(false);
	const [ loading, setLoading ] = useState(false);
	const [ taskRunning, setTaskRunning ] = useState(false);
	const algolia = new AlgoliaService('users');


  // Hooks
	useEffect(() => {
		modal && searchData(query);
	}, [modal, query]);


  // Methods
  const fetchData = async (recordID) => {
    try {
      setTaskRunning(true);
      let user = await store.findRecord('user', recordID);
      model.pushProp('users', user);
      setQuery('');
    } catch (e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  const searchData = async (value = '', page) => {
    try {
    	setLoading(true);
      await timeout(300);
  		let model = await algolia.queryIndex(value, page);
      setMeta({ page: model.page, pageSize: model.hitsPerPage, totalRecords: model.nbHits });
  		setHits(model.hits);
    } catch (e) {
      toast.showError(e);
    } finally {
    	setLoading(false);
    }
  }

  const saveModel = async () => {
    try {
      setTaskRunning(true);
      await model.save();
      nextAction();
    } catch (e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


	// Render
	return (
		<Fragment>
      <Modal show={modal} toggleModal={() => toggleModal(!modal)}>
       	<ModalHeader title='Select Users'/>
       	<ModalBody className='bb'>
         	<FormControlPrepend icon='search' loading={loading}>
  	  			<FormControl
  	  				type='search'
  			      value={query}
  			      autoFocus={true}
  			      placeholder='Search users...'
  			      className='search'
  			      onChange={e => setQuery(e.target.value)}
  		      />
  	      </FormControlPrepend>
        </ModalBody>
        <ModalBody className='bb'>
          <TagsContainer>
            {users.length > 0 && users.map(user => (
              <Tag key={user.id} title={user.name} onClick={() => model.pushProp('users', user)} />
            ))}
          </TagsContainer>
        </ModalBody>
        <ModalBody size='lg' className='search'>
      		<UserList
      			type='input'
      			users={hits}
      			selectedIDs={users.map(user => user.id)}
      			loading={loading}
      			onClick={d => fetchData(d.id)}
    			/>
        </ModalBody>
        <ModalFooter className='flex-start'>
          <Pagination meta={meta} onClick={page => searchData(query, page)}/>
        </ModalFooter>
        <ModalFooter className='flex-start'>
          <Button
            variant='secondary'
            taskRunning={taskRunning}
            onClick={() => toggleModal(!modal)}>
            Finished<FarIcon icon='check' className='ml-2'/>
          </Button>
        </ModalFooter>
      </Modal>
      <FormControlPrepend icon='search'>
        <FormControlDiv value={null} placeholder='Search Users...' onClick={() => toggleModal(!modal)} />
      </FormControlPrepend>
    </Fragment>
	)
}

export default withStore(withToast(UsersSearchInput));
