import { JsonApiSerializer } from '@mile-hi-labs/react-data';
import { maskToString } from 'utils/transforms';

class AppSerializer extends JsonApiSerializer {
	static attrs = {
		...super.attrs,
		createdAt: { serialize: false },
		updatedAt: { serialize: false },
	}

	// Methods
	static serializeAttr(data, key) {
		let formattedKey = super.serializeAttr(data, key);

		if (key.includes('phone') || key.includes('Phone')) {
			return maskToString(data[key]);
		}
		return formattedKey;
	}


}

export default AppSerializer;
