import AppSerializer from 'serializers/app';
import Moment from 'moment';

class ServiceSerializer extends AppSerializer {
	static relationships = {
		...super.relationships,
		booking: { serialize: 'id' },
		user: { serialize: 'id' },
	}


}

export default ServiceSerializer;
