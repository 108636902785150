import React, { useEffect, useState } from 'react';
import { withSession } from '@mile-hi-labs/react-session';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';

import AdminForm from 'components/admin/admin-form';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionBody } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminProfileEditRoute = (props) => {
  const { history, location, session, store, toast } = props;
  const [ admin, setAdmin ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData()
  }, [session.authenticated()]);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.findRecord('admin', session.user.id)
      setAdmin(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }


  // Render
  return (
    <AdminRoute title='DMC - Edit Profile'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <AdminForm
              title='Edit Teammate'
              admin={admin}
              loading={loading}
              nextAction={() => history.push(`/settings/profile`)}
            />
          </SectionBlock>

        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withSession(withStore(withToast(AdminProfileEditRoute)));

