import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';

import AlertForm from 'components/alert/alert-form';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionBody } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminAlertsEditRoute = (props) => {
  const { alertID, history, location, store, toast } = props;
  const [ alert, setAlert ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, []);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord('alert', alertID)
      setAlert(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }


  // Render
  return (
    <AdminRoute title='DMC - Edit Alert'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <AlertForm
              title='Edit Alert'
              alert={alert}
              loading={loading}
              nextAction={() => history.push(`/alerts/${alertID}`)}
            />
          </SectionBlock>

        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withStore(withToast(AdminAlertsEditRoute));
