import React, { Fragment, useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';

import NoteForm from 'components/note/note-form';
import { NotePreviewCard } from 'components/note/note-card';
import { ButtonText } from 'components/basics/buttons';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/basics/modals';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, timeout, logger } from 'utils/helpers';

const NoteSection = (props) => {  
  const { model = {}, notableId, notableType, loading, store, nextAction } = props;
  const [ note, setNote ] = useState({});
  const [ modal, setModal ] = useState('');
  const [ modalOpen, toggleModal ] = useState(false);
  

  // Hooks  
  useEffect(() => {
    model.id && setNote(model);
  }, [model]);


  // Methods
  const editNote = () => {
    let model = !isEmpty(note) ? note : store.createRecord('note', {
      notableId: notableId,
      notableType: notableType,
    });
    openModal('note', model);
  }

  const openModal = (type, model = {}) => {
    if (type == 'note') {
      setNote(model);
      setModal('note');
      toggleModal(!modalOpen);
    }
  }
  

  // Render
  return (
    <Fragment>
      <SectionBlock>
        <SectionHeader title='Notes' className='flex-between'>
          <ButtonText 
            size='xs' 
            variant='gray' 
            onClick={() => editNote()}>
            <FarIcon icon='plus' size='2x'/>
          </ButtonText>
        </SectionHeader>
        <SectionBody size='xs'>
          <NotePreviewCard note={note} loading={loading} />
        </SectionBody>
      </SectionBlock>
      <Modal show={modalOpen} toggleModal={() => toggleModal(!modalOpen)}>
        <NoteForm 
          type='modal' 
          title={note.id ? 'Edit Note' : 'Add Note'}
          note={note}
          toggleModal={toggleModal} 
          nextAction={() => toggleModal(!modalOpen)}
        />
      </Modal>
    </Fragment>
  )
}

export default withStore(NoteSection);

