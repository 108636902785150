import React, { useEffect } from 'react';
import Router from 'router';
import GoogleFontLoader from 'react-google-font-loader';

import { MobileNavProvider } from 'contexts/mobile-nav-context';
import { StoreContext, StoreProvider } from '@mile-hi-labs/react-data';
import { SessionProvider } from '@mile-hi-labs/react-session';
import { ThemeProvider } from 'contexts/theme-context';
import { ToastProvider } from 'contexts/toast-context';

import * as Adapters from 'adapters';
import * as Models from 'models';
import * as Serializers from 'serializers';

import { logger } from'utils/helpers';
import { capitalize } from 'utils/transforms';


const App = (props) => {
  const { id, className = '', data } = props;
  const apiDomain = process.env.REACT_APP_SERVER_DOMAIN


  // Hooks
  useEffect(() => {
    logger('Env: ', capitalize(process.env.NODE_ENV));
  }, []);


  // Render
  return (
    <StoreProvider apiDomain={apiDomain} adapters={Adapters} serializers={Serializers} models={Models}>
      <GoogleFontLoader fonts={[ {font: 'Open Sans Condensed', weights: [700]}, {font: 'Open Sans', weights: [300, 400, 600, 700]} ]} />
      <StoreContext.Consumer>
        {store => (
          <ToastProvider>
            <SessionProvider store={store} modelName='admin'>
              <MobileNavProvider>
                <ThemeProvider>
                  <Router/>
                </ThemeProvider>
              </MobileNavProvider>
            </SessionProvider>
          </ToastProvider>
        )}
      </StoreContext.Consumer>
    </StoreProvider>
  );
}

export default App;
