import React, { useEffect, useState } from 'react';
import { withSession } from '@mile-hi-labs/react-session';
import { withStore } from '@mile-hi-labs/react-data';
import Moment from 'moment';

import InquiryForm from 'components/inquiry/inquiry-form';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionBody } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminInquiriesNewRoute = (props) => {
  const { history, location, session, store } = props;
  const [ inquiry, setInquiry ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    createInquiry();
  }, []);


  // Methods
  const createInquiry = () => {
    let model = store.createRecord('inquiry', {
      status: 'new',
      housingType: 'apartment',
      moveIn: Moment().startOf('d').add(1, 'd'),
      moveOut: Moment().startOf('d').add(2, 'd'),
    });
    setInquiry(model);
  }


  // Render
  return (
    <AdminRoute title='DMC - New Inquiry'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <InquiryForm
              title='New Inquiry'
              inquiry={inquiry}
              nextAction={() => history.push('/inquiries')}
            />
          </SectionBlock>

        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withSession(withStore(AdminInquiriesNewRoute));
