import React, { Fragment } from 'react';
import BookingActions from 'components/booking/booking-actions';
import { Badge } from 'components/basics/badges';
import { SectionEmpty } from 'components/basics/empties';
import { Table, TableHeader, TableHeaderRow, TableHead, TableHeadSort, TableBody, TableRow, TableLink, TableData } from 'components/basics/tables';
import { TableSkeleton } from 'components/vendors/loading-skeleton-wrapper';
import { capitalize } from 'utils/transforms';

const BookingTable = (props) => {
	const { bookings = [], sortProp, sortValue, sortData, loading, refreshData } = props;

	if (loading) {
		return <TableSkeleton count={6} />
	}
	else if (bookings.length > 0) {
		return (
			<Table>
				<TableHeader>
					<TableHeaderRow>
						<TableHeadSort title='Id' value='status' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHeadSort title='Status' value='status' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHead title='User'/>
						<TableHeadSort title='Housing' value='housing' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHeadSort title='Move In' value='move_in' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHeadSort title='Move Out' value='move_out' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHeadSort title='Rate' value='billing_rate' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHead title='Actions'/>
					</TableHeaderRow>
				</TableHeader>
				<TableBody>
					{bookings.map(booking => (
						<TableRow key={booking.id}>
							<TableLink to={booking.url} value={'#' + booking.id}/>
							<TableLink to={booking.url} value={<Badge title={booking.status}/>}/>
							<TableLink to={booking.url}>
								<b>{booking.user && booking.user.name}</b>
							</TableLink>
							<TableLink to={booking.url} value={capitalize(booking.housing)}/>
							<TableLink to={booking.url} value={booking.displayMoveIn}/>
							<TableLink to={booking.url} value={booking.displayMoveOut}/>
							<TableLink to={booking.url} value={booking.displayRate}/>
							<TableData>
								<BookingActions booking={booking} table={true} refreshData={refreshData} />
							</TableData>
						</TableRow>
					))}
				</TableBody>
			</Table>
		)
	}
	return (
		<SectionEmpty
			icon='calendar'
			title='Bookings'
			msg="Sorry, looks like there aren't any bookings."
		/>
	)
}

export default BookingTable;
