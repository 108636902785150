import AppSerializer from 'serializers/app';

class Provider extends AppSerializer {
	static relationships = {
		...super.relationships,
		location: { serialize: true },
	}
}

export default Provider;
