import React, { Fragment, useEffect } from 'react';
import DatePickerWrapper from 'components/vendors/date-picker-wrapper';
import FacilitySearchInput from 'components/facility/facility-search-input';
import ProviderSearchInput from 'components/provider/provider-search-input';
import UserSearchInput from 'components/user/user-search-input';
import CurrencyInputWrapper from 'components/vendors/currency-input-wrapper';
import SwitchWrapper from 'components/vendors/switch-wrapper';
import { SubSectionBlock, SubSectionHeader, SubSectionBody } from 'components/basics/sub-sections';
import { Form, FormGroup, FormControl, FormSelect, FormTextArea, FormText } from 'components/basics/forms';
import { Container, Row, Col } from 'components/basics/grids';
import LocationFields from 'components/location/location-fields';

const BookingFields = (props) => {
	const { model } = props;

	return (
    <Fragment>
      <SubSectionBlock className='bb'>
        <SubSectionHeader title='Details' />
        <SubSectionBody>
          <FormGroup label='Status'>
            <FormSelect
              value={model.status}
              options={model.statusOptions}
              placeholder='Select status'
              onChange={e => model.set('status', e.target.value)}
            />
          </FormGroup>

          <FormGroup label='Housing Type'>
            <FormSelect
              value={model.housing}
              options={model.housingOptions}
              placeholder='Select housing'
              onChange={e => model.set('housing', e.target.value)}
            />
          </FormGroup>

          <Row>
            <Col sm='12' md='6'>
              <FormGroup label='Move In Date'>
                <DatePickerWrapper
                  date={model.moveIn}
                  placeholder='Select date...'
                  onChange={v => model.setMoveInDate(v)}
                />
              </FormGroup>
            </Col>
            <Col sm='12' md='6'>
              <FormGroup label='Move Out Date'>
                <DatePickerWrapper
                  date={model.moveOut}
                  placeholder='Select date...'
                  onChange={v => model.setMoveOutDate(v)}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm='12' md='6'>
              <FormGroup label='Net Rate'>
                <CurrencyInputWrapper
                  value={model.netRate}
                  placeholder='$00.00'
                  onChange={v => model.set('netRate', v)}
                />
              </FormGroup>
            </Col>
            <Col sm='12' md='6'>
              <FormGroup label='Billing Rate'>
                <CurrencyInputWrapper
                  value={model.billingRate}
                  placeholder='$00.00'
                  onChange={v => model.set('billingRate', v)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm='12' md='6'>
              <FormGroup label='Billing Frequency'>
                <FormSelect
                  value={model.billingFrequency}
                  options={model.billingOptions}
                  placeholder='Select billing frequency'
                  onChange={e => model.set('billingFrequency', e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col sm='12' md='6'>
              <FormGroup label='Unit #'>
                <FormControl
                  value={model.unit}
                  placeholder='Apt #412'
                  onChange={e => model.set('unit', e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup label='Special Instructions'>
            <FormTextArea
              placeholder="Anything you'd like to share?"
              value={model.message}
              onChange={e => model.set('message', e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <SwitchWrapper
              label='Does this booking require maid service?'
              value={model.maidService}
              onChange={v => model.set('maidService', v)}
            />
          </FormGroup>

        </SubSectionBody>
      </SubSectionBlock>

      <SubSectionBlock title='Relationships'>

        <SubSectionBody>
          <FormGroup label='Select Facility'>
            <FacilitySearchInput
              facility={model.facility}
              onRemove={v => model.set('facility', {})}
              onSelect={v => model.setRelation('facility', v)}
            />
          </FormGroup>
        </SubSectionBody>

        <SubSectionBody>
          <FormGroup label='Select User'>
            <UserSearchInput
              user={model.user}
              onRemove={v => model.set('user', {})}
              onSelect={v => model.setRelation('user', v)}
            />
          </FormGroup>
        </SubSectionBody>

        <SubSectionBody>
          <FormGroup label='Select Provider'>
            <ProviderSearchInput
              provider={model.provider}
              onRemove={v => model.set('provider', {})}
              onSelect={v => model.setRelation('provider', v)}
            />
          </FormGroup>
        </SubSectionBody>

      </SubSectionBlock>

    </Fragment>
  )
}

export default BookingFields;
