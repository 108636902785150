import AppModel from 'models/app';
import { stringToPhone } from 'utils/transforms';

class User extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);

		// Attrs
		this.photo = props.photo;
		this.name = props.name;
		this.email = props.email;
		this.password = props.password;
		this.phone = props.phone;


		// Relationships
		this.location = this.belongsTo('location', props.location);
		this.note = this.belongsTo('note', props.note);
		this.bookings = this.hasMany('booking', props.bookings);
		this.notifications = this.hasMany('notification', props.notifications);
	}


	// Computed
	get firstName() {
		return this.name && this.name.split(' ')[0];
	}

	get lastName() {
		return this.name && this.name.split(' ')[1];
	}

	get displayPhone() {
		return this.phone && stringToPhone(this.phone);
	}

	get url() {
		return `/users/${this.id}`
	}
}

export default User;
