import AppModel from 'models/app';
import { stringToPhone } from 'utils/transforms';

class Notification extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);

		// Attrs
		this.title = props.title;
		this.description = props.description;
		this.event = props.event;
		this.notifiableId = props.notifiableId;
		this.notifiableType = props.notifiableType;


		// Relationship
		this.user = this.belongsTo('user', props.user);
	}


	// Computed
	get icon() {
		switch(this.notifiableType) {
			case 'booking':
			return 'calendar';

			case 'facility':
			return 'business';

			case 'provider':
			return 'business';

			case 'service':
			return 'concierge-bell';

			case 'user':
			return 'user';

			default:
			return 'crown'
		}
	}

	get displayEvent() {
		return this.event.split('.')[0];
	}
}

export default Notification;
