import AppSerializer from 'serializers/app';

class Facility extends AppSerializer {
	static relationships = {
		...super.relationships,
		location: { serialize: true },
		providers: { serialize: 'id' },
	}


}

export default Facility;
