import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withSession } from '@mile-hi-labs/react-session';
import { withToast } from 'contexts/toast-context';
import ActivitiesSection from 'components/activity/activities-section';
import AnalyticsSection from 'components/analytics/analytics-section';
import InquiriesSection from 'components/inquiry/inquiries-section';
import ServicesSection from 'components/service/services-section';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { ButtonText } from 'components/basics/buttons';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminDashboardRoute = (props) => {
  const { history, location, session, store, toast } = props;
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Hooks
  useEffect(() => {
  }, []);


  // Methods
  const refreshData = async () => {
    try {
      setTaskRunning(true);
      toast.showInfo('Testing!');
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


  // Render
  return (
    <AdminRoute title='DMC - Dashboard'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>
          <SectionBlock>
            <SectionHeader title={`Welcome, ${session.user.name}`} className='no-b flex-between' />
          </SectionBlock>

          <Row>
            <Col md={12} lg={8}>
              <AnalyticsSection />
              <InquiriesSection title='Pending Inquiries' />
              <ServicesSection title='Requested Services' status='requested'/>
            </Col>
            <Col md={12} lg={4}>
              <ActivitiesSection />
            </Col>
          </Row>

        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withSession(withStore(withToast(AdminDashboardRoute)));

