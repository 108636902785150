import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';
import ContactActions from 'components/contact/contact-actions';
import NoteSection from 'components/note/note-section';
import { ContactDetailCard } from 'components/contact/contact-card';
import { ProviderListCard } from 'components/provider/provider-card';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminContactsDetailRoute = (props) => {
  const { contactID, history, location, store, toast } = props;
  const [ contact, setContact ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, []);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord('contact', contactID, { include: 'provider' });
      setContact(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const refreshData = () => {
    toggleModal(!modal);
    fetchData();
  }


  // Render
  return (
    <AdminRoute title={`DMC - Contact #${contactID}`}>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <SectionHeader title={`Contact #${contactID}`} className='flex-between no-b'>
              <ContactActions contact={contact} />
            </SectionHeader>
          </SectionBlock>

          <Row>
            <Col xs={12} md={4}>
              <ContactDetailCard contact={contact} loading={loading}/>
            </Col>
            <Col xs={12} md={8}>
              <SectionBlock title='Provider'>
                <SectionBody size='xs'>
                  <ProviderListCard provider={contact.provider} loading={loading} />
                </SectionBody>
              </SectionBlock>

            </Col>
          </Row>

        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withStore(withToast(AdminContactsDetailRoute));
