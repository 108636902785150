import React, { Fragment } from 'react';
import ContactActions from 'components/contact/contact-actions';
import { AvatarImage } from 'components/basics/avatars';
import { SectionEmpty } from 'components/basics/empties';
import { Table, TableHeader, TableHeaderRow, TableHead, TableHeadSort, TableBody, TableRow, TableLink, TableData } from 'components/basics/tables';
import { TableSkeleton } from 'components/vendors/loading-skeleton-wrapper';
import { capitalize } from 'utils/transforms';

const ContactTable = (props) => {
	const { contacts = [], sortProp, sortValue, sortData, loading, refreshData } = props;

	if (loading) {
		return <TableSkeleton count={6} />
	}
	else if (contacts.length > 0) {
		return (
			<Table>
				<TableHeader>
					<TableHeaderRow>
						<TableHeadSort title='Id' value='id' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHead title='Photo'/>
						<TableHeadSort title='Name' value='name' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHeadSort title='Job Title' value='job_title' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHeadSort title='Email Address' value='email' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHeadSort title='Work Phone' value='work_phone' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHeadSort title='Updated' value='updated_at' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHead title='Actions'/>
					</TableHeaderRow>
				</TableHeader>
				<TableBody>
					{contacts.map(contact => (
						<TableRow key={contact.id}>
							<TableLink to={contact.url} value={'#' + contact.id}/>
							<TableLink to={contact.url} value={<AvatarImage size='sm' photo={contact.photo}/>}/>
							<TableLink to={contact.url}><b>{contact.name}</b></TableLink>
							<TableLink to={contact.url} value={contact.displayJobTitle}/>
							<TableLink to={contact.url} value={contact.email}/>
							<TableLink to={contact.url} value={contact.displayWorkPhone}/>
							<TableLink to={contact.url} value={contact.displayUpdatedAt}/>
							<TableData>
								<ContactActions contact={contact} table={true} refreshData={refreshData} />
							</TableData>
						</TableRow>
					))}
				</TableBody>
			</Table>
		)
	}
	return (
		<SectionEmpty
			icon='user'
			title='Contacts'
			msg="Sorry, looks like we don't have any contacts yet."
		/>
	)
}

export default ContactTable;
