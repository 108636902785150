import React, { Fragment } from 'react';
import UserActions from 'components/user/user-actions';
import { AvatarImage } from 'components/basics/avatars';
import { SectionEmpty } from 'components/basics/empties';
import { Table, TableHeader, TableHeaderRow, TableHead, TableHeadSort, TableBody, TableRow, TableLink, TableData } from 'components/basics/tables';
import { TableSkeleton } from 'components/vendors/loading-skeleton-wrapper';
import { capitalize } from 'utils/transforms';

const UserTable = (props) => {
	const { users = [], sortProp, sortValue, sortData, loading, refreshData } = props;

	if (loading) {
		return <TableSkeleton count={6} />
	}
	else if (users.length > 0) {
		return (
			<Table>
				<TableHeader>
					<TableHeaderRow>
						<TableHeadSort title='Id' value='id' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHead title='Photo'/>
						<TableHeadSort title='Name' value='name' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHead title='Location'/>
						<TableHeadSort title='Email Address' value='email' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHeadSort title='Phone' value='phone' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHeadSort title='Updated' value='updated_at' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
						<TableHead title='Actions'/>
					</TableHeaderRow>
				</TableHeader>
				<TableBody>
					{users.map(user => (
						<TableRow key={user.id}>
							<TableLink to={user.url} value={'#' + user.id}/>
							<TableLink to={user.url} value={<AvatarImage size='sm' photo={user.photo}/>}/>
							<TableLink to={user.url}><b>{user.name}</b></TableLink>
							<TableLink to={user.url} value={user.location ? user.location.cityState : '--'}/>
							<TableLink to={user.url} value={user.email}/>
							<TableLink to={user.url} value={user.displayPhone}/>
							<TableLink to={user.url} value={user.displayUpdatedAt}/>
							<TableData>
								<UserActions user={user} table={true} refreshData={refreshData}/>
							</TableData>
						</TableRow>
					))}
				</TableBody>
			</Table>
		)
	}
	return (
		<SectionEmpty
			icon='user'
			title='Users'
			msg="Sorry, looks like there aren't any users yet."
		/>
	)
}

export default UserTable;
