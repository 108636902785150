import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';

import ContactForm from 'components/contact/contact-form';
import { AdminBreadcrumbs } from 'components/basics/breadcrumbs';
import { AdminRoute } from 'components/basics/routes';
import { AdminHeader } from 'components/basics/headers';
import { AdminMain } from 'components/basics/mains';
import { SectionBlock, SectionBody } from 'components/basics/sections';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const AdminContactsNewRoute = (props) => {
  const { contactID, history, location, session, store } = props;
  const [ contact, setContact ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, []);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord('contact', contactID, { include: 'provider' })
      setContact(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }


  // Render
  return (
    <AdminRoute title='DMC - Edit Contact'>
      <AdminBreadcrumbs />
      <AdminMain>
        <Container>

          <SectionBlock>
            <ContactForm
              title='Edit Contact'
              contact={contact}
              nextAction={() => history.push(`/contacts/${contactID}`)}
            />
          </SectionBlock>

        </Container>
      </AdminMain>
    </AdminRoute>
  )
}

export default withStore(withToast(AdminContactsNewRoute));
