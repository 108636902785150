import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withSession } from '@mile-hi-labs/react-session';
import { withToast } from 'contexts/toast-context';
import AdminApi from 'apis/admin-api';

import { Form, FormGroup, FormControl, FormText } from 'components/basics/forms';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { ButtonBlock } from 'components/basics/buttons';
import { Link } from 'components/basics/links';
import { Container, Row, Col } from 'components/basics/grids';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, logger } from 'utils/helpers';

const LoginForm = (props) => {
  const { session, store, toast, nextAction } = props;
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Hooks
  useEffect(() => {
    return () => setTaskRunning(false);
  }, []);


  // Methods
  const login = async () => {
    try {
      setTaskRunning(true);
      let model = await AdminApi.login({email: email, password: password});
      await session.authenticate('admin', model);
      toast.showSuccess('Login succeeded!');
      nextAction();
    } catch(e) {
      toast.showError(e);
      setTaskRunning(false);
    } finally {
      setTaskRunning(false);
    }
  }


  // Render
  return (
    <Form onSubmit={e => e.preventDefault()}>
      <SectionBlock title='Login'>

        <SectionBody>
          <FormGroup label='Email Address'>
            <FormControl
              type='email'
              placeholder='redford@hollywood.com'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </FormGroup>

          <FormGroup label='Password'>
            <FormControl
              type='password'
              placeholder='••••••••'
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Link to='/forgot-password'>Forgot Password?</Link>
          </FormGroup>
        </SectionBody>

        <SectionFooter>
          <ButtonBlock
            type='submit'
            variant='secondary'
            taskRunning={taskRunning}
            onClick={() => login()}>
            Login<FarIcon icon='chevron-right' className='ml-2'/>
          </ButtonBlock>
        </SectionFooter>

      </SectionBlock>
    </Form>
  )
}

export default withSession(withStore(withToast(LoginForm)));

