import React, { Fragment, useState } from 'react';
import Head from 'components/basics/head';
import AdminNav from 'components/navs/admin-nav';
import AdminSideNav from 'components/navs/admin-sidenav';
import AuthNav from 'components/navs/auth-nav';
import MktNav from 'components/navs/mkt-nav';
import { Container, Row, Col } from 'components/basics/grids';

const AdminRoute = (props) => {
	const { title, description, style, children } = props;
  const [ open, toggleOpen ] = useState(true);

	return (
  	<Fragment>
      <Head title={title} description={description}/>
      <div id='route' className={open ? 'admin-route' : 'admin-route collapsed'}>
        <AdminSideNav open={open} toggleOpen={() => toggleOpen(!open)}/>
        <div className={open ? 'admin-container' : 'admin-container collapsed'}>
          <AdminNav />
          {children}
        </div>
      </div>
    </Fragment>
	)
}

const AuthRoute = (props) => {
  const { title, description, style, children } = props;

  return (
    <Fragment>
      <Head title={title} description={description}/>
      <div id='route' className='mkt-route'>
        <AuthNav />
        {children}
      </div>
    </Fragment>
  )
}

const MktRoute = (props) => {
	const { title, description, style, children } = props;

	return (
  	<Fragment>
      <Head title={title} description={description}/>
      <div id='route' className='mkt-route'>
        <MktNav />
        {children}
      </div>
    </Fragment>
	)
}

export { AdminRoute, AuthRoute, MktRoute }
