import AppSerializer from 'serializers/app';

class AlertSerializer extends AppSerializer {
	static relationships = {
		...super.relationships,
		users: { serialize: 'id' },
	}
}

export default AlertSerializer;
