import AppModel from 'models/app';

class Note extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);

		// Attrs
		this.notes = props.notes;
		this.notableId = props.notableId;
		this.notableType = props.notableType;
	}
}

export default Note;
