import React, { useEffect, useState } from 'react';
import { LoadScriptNext, GoogleMap, Marker } from '@react-google-maps/api';
import { MapMarker } from 'utils/assets/icons';
import { isEmpty } from 'utils/helpers';

const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY;

const GoogleMapWrapper = (props) => {
	const { model = {}, size, style, className } = props;
	const [ position, setPosition ] = useState({ lat: 40.885164, lng: -74.3808085 });

	const options = {
		zoom: 13,
		icon: MapMarker,
		mapTypeControl: false,
		streetViewControl: false,
		fullscreenControl: false,
		styles: style
	}


	// Hooks
	useEffect(() => {
		model.latitude && setPosition({ lat: model.latitude, lng: model.longitude });
	}, [model])


	// Render
	return (
		<div className={`map-block ${size} ${className}`}>
			<LoadScriptNext googleMapsApiKey={GOOGLE_KEY} libraries={['places']}>
				<GoogleMap center={position} options={options} mapContainerClassName='google-map'>
					<Marker position={position} icon={MapMarker} animation='DROP'/>
				</GoogleMap>
			</LoadScriptNext>
		</div>
	)
}

GoogleMapWrapper.defaultProps = { model: {}, size: 'sm', style: [], className: '' }

export default GoogleMapWrapper;

// Docs
// https://react-google-maps-api-docs.netlify.app/
