import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withSession } from '@mile-hi-labs/react-session';
import { withToast } from 'contexts/toast-context';

import ServiceFields from 'components/service/service-fields';
import { SectionBlock, SectionHeader, SectionBody, SectionFooter } from 'components/basics/sections';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/basics/modals';
import { Form, FormGroup, FormControl, FormText } from 'components/basics/forms';
import { Button } from 'components/basics/buttons';
import { Container, Row, Col } from 'components/basics/grids';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, logger, randomString } from 'utils/helpers';


const ServiceForm = (props) => {
  const { service = {}, title, type, session, store, toast, nextAction } = props;
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Hooks
  useEffect(() => {
    return () => setTaskRunning(false);
  }, []);


  // Methods
  const saveService = async (service) => {
    try {
      setTaskRunning(true);
      await service.save();
      toast.showSuccess('Service saved!');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


  // Render
  return (
    <Form onSubmit={e => e.preventDefault()}>
      <SectionHeader title={title}/>
      <SectionBody size='md'>
        <ServiceFields model={service}/>
      </SectionBody>
      <SectionFooter>
        <Button
          type='button'
          variant='secondary'
          taskRunning={taskRunning}
          onClick={() => saveService(service)}>
          Save<FarIcon icon='check' className='ml-2'/>
        </Button>
      </SectionFooter>
    </Form>
  )
}

export default withSession(withStore(withToast(ServiceForm)));

