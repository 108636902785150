import React, { Component } from 'react';
import { withToast } from 'contexts/toast-context';
import { logger }  from 'utils/helpers';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error) {
    this.props.toast.showError(error);
  }

  // Hooks
  componentDidCatch(error, errorInfo) {
    logger(error, errorInfo);
  }


  // Render
  render() {
    const { toast, children } = this.props;
    return children;
  }
}

export default withToast(ErrorBoundary);
