import React, { Fragment } from 'react';

import { Avatar } from 'components/basics/avatars';
import { Badge } from 'components/basics/badges';
import { Card, CardHeader, CardImg, CardBody, CardFooter } from 'components/basics/cards';
import { Image } from 'components/basics/images';
import { ButtonWrapper } from 'components/basics/buttons';
import { FarIcon, FadIcon } from 'components/vendors/fa-wrapper';
import { ChatSkeleton } from 'components/vendors/loading-skeleton-wrapper';

const MessageCard = (props) => {
	const { avatar = {}, status, message, timestamp, onClick, loading, children, className = '' } = props;

	if (loading) { return <ChatSkeleton count={1}/>}
	return (
		<Card className={'chat ' + className}>
			<CardBody className='chat'>
				{avatar && <Avatar size='xs' photo={avatar.photo} title={avatar.name} onClick={onClick} className='pb-2'/>}
				{message && <h6>{message}</h6>}
				{children && children}
			</CardBody>
			{timestamp && <CardFooter className='chat'>{timestamp}</CardFooter>}
		</Card>
	)
}

export default MessageCard;
