import AppSerializer from 'serializers/app';

class User extends AppSerializer {
	static relationships = {
		...super.relationships,
		location: { serialize: true },
	}

}

export default User;
