import React, { Fragment, useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withToast } from 'contexts/toast-context';
import ActivityList from 'components/activity/activity-list';
import { SectionEmpty } from 'components/basics/empties';
import { SectionBlock, SectionBody, SectionFooter } from 'components/basics/sections';
import { Pagination } from 'components/basics/pagination';
import { Container, Row, Col } from 'components/basics/grids';
import { isEmpty, timeout, logger } from 'utils/helpers';

const ActivitiesSection = (props) => {
	const { title = 'Recent Activity', modelId = '', modelName = '', store, toast } = props;
	const [ activities, setActivities ] = useState([]);
  const [ page, setPage ] = useState(0);
  const [ pageSize, setPageSize ] = useState(5);
	const [ loading, setLoading ] = useState(false);


	// Hooks
	useEffect(() => {
		fetchData();
	}, [page, pageSize]);


	// Methods
	const fetchData = async () => {
    try {
      setLoading(true);
      let activities = await store.query('activity', {
        page: page,
        pageSize: pageSize,
        sortProp: 'id',
        sortValue: 'desc',
        activitable_id: modelId,
        activitable_type: modelName
      });
      setActivities(activities);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
	}

	return (
    <SectionBlock title={title}>
      <SectionBody size='md'>
      	<ActivityList
      		type='list'
      		activities={activities}
      		loading={loading}
      	/>
      </SectionBody>
      <SectionFooter>
      	<Pagination meta={activities.meta} onClick={page => fetchData(page)}/>
      </SectionFooter>
    </SectionBlock>
	)
}

export default withStore(withToast(ActivitiesSection));
