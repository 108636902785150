import React, { useEffect, useState } from 'react';
import { withStore } from '@mile-hi-labs/react-data';
import { withSession } from '@mile-hi-labs/react-session';
import { withToast } from 'contexts/toast-context';

import ServiceTagFields from 'components/service-tag/service-tag-fields';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/basics/modals';
import { Form, FormGroup, FormControl, FormText } from 'components/basics/forms';
import { Button, ButtonText } from 'components/basics/buttons';
import { Container, Row, Col } from 'components/basics/grids';
import { FarIcon } from 'components/vendors/fa-wrapper';
import { isEmpty, logger } from 'utils/helpers';


const ServiceTagForm = (props) => {
  const { tag = {}, title, type, session, store, toast, nextAction } = props;
  const [ taskRunning, setTaskRunning ] = useState(false);
  const [ deleteRunning, setDeleteRunning ] = useState(false);


  // Hooks
  useEffect(() => {
    return () => setTaskRunning(false);
  }, []);


  // Methods
  const saveTag = async (tag) => {
    try {
      setTaskRunning(true);
      await tag.save();
      toast.showSuccess('Tag saved!');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  const destroyTag = async (tag) => {
    try {
      setDeleteRunning(true);
      await tag.destroy();
      toast.showSuccess('Tag destroyed!');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setDeleteRunning(false);
    }
  }


  // Render
  return (
    <Form onSubmit={e => e.preventDefault()}>
      <ModalHeader title={title}/>
      <ModalBody size='sm'>
        <ServiceTagFields model={tag} />
      </ModalBody>
      <ModalFooter className='flex-between'>
        {tag.id && (
          <ButtonText
            variant='danger'
            taskRunning={deleteRunning}
            onClick={() => destroyTag(tag)}>
            <FarIcon icon='times' className='mr-2'/>Delete
          </ButtonText>
        )}
        <Button
          type='submit'
          variant='secondary'
          taskRunning={taskRunning}
          onClick={() => saveTag(tag)}>
          Save<FarIcon icon='check' className='ml-2'/>
        </Button>
      </ModalFooter>
    </Form>
  )
}

export default withSession(withStore(withToast(ServiceTagForm)));
