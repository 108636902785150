import React, { Fragment } from 'react';

import { MessageRecipientCard, MessageSenderCard } from 'components/message/message-card';
import { SectionEmpty } from 'components/basics/empties';
import { Row, Col } from 'components/basics/grids';
import { ListSkeleton, GridSkeleton } from 'components/vendors/loading-skeleton-wrapper';

const MessageList = (props) => {
	const { messages = [], type, admin, loading, onClick } = props;

	if (loading) {
		return <ListSkeleton count={3} />
	} 
	else if (messages.length > 0) {
		return (
			<Row>
				{type == 'preview' && messages.map(message => (
					<Col key={message.id} xs={12}>
						{admin && message.isAdmin ? (
							<MessageSenderCard
								message={message}
								onClick={onClick}
							/>
						) : (
							<MessageRecipientCard
								message={message}
								onClick={onClick}
							/>
						)}
					</Col>
				))}
			</Row>
		)
	}
	return (
		<SectionEmpty
			icon='comments'
			title='Messages'
			msg="Sorry, looks like we don't have any messages yet."
		/>
	)
}

export default MessageList;