import React, { Fragment } from 'react';
import DetailCard from 'components/cards/detail-card';
import ListCard from 'components/cards/list-card';
import SearchCard from 'components/cards/search-card';

const TeammateDetailCard = (props) => {
	const { teammate = {}, loading } = props;

	return (
		<DetailCard
			icon='users-crown'
			photo={teammate.photo}
			title={teammate.name}
			subtitle={teammate.role}
			line1={<a href={`mailto:${teammate.email}`}>{teammate.email}</a>}
			timestamp={teammate.displayUpdatedAt}
			loading={loading}
		/>
	)
}

const TeammateListCard = (props) => {
	const { teammate = {}, loading } = props;

	return (
		<ListCard
			icon='users-crown'
			photo={teammate.photo}
			title={teammate.name}
			subtitle={teammate.role}
			line1={teammate.email}
			loading={loading}
			link={`/settings/teammates/${teammate.id}`}
		/>
	)
}

const TeammateSearchCard = (props) => {
	const { teammate = {}, type, selectedID, loading, onClick } = props;
	const selected = teammate.id == selectedID;

	if (type == 'input') {
		return (
			<SearchCard
				icon='users-crown'
				title={teammate.name}
				line1={teammate.email}
				selected={selected}
				onClick={() => onClick(teammate)}
			/>
		)
	}
	return (
		<SearchCard
			icon='users-crown'
			title={teammate.name}
			line1={teammate.email}
			link={`/settings/teammates/${teammate.id}`}
		/>
	)
}

export { TeammateDetailCard, TeammateListCard, TeammateSearchCard }
